const formatDateTime = (dateTime) => {
  if (!dateTime) {
    return '-';
  }

  return new Date(dateTime).toLocaleString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

const formatDate = (date) => {
  if (!date) {
    return '-';
  }

  return new Date(date).toLocaleString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export { formatDate, formatDateTime };
