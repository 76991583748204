import { useState } from "react";

export default function TransparentCollapse(props) {
  const { id, header, children } = props;

  const [isOpen, setIsOpen] = useState(false);

  /**
   * A style object for the header.
   */
  const buttonStyle = {
    transform: isOpen ? "rotate(180deg)" : "",
    transition: "transform 200ms ease",
    fontSize: 20,
  };

  return (
    <div className="me-3">
      <div className="d-grid mb-3">
        <button
          className="btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${id}`}
          aria-expanded="false"
          aria-controls={id}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="d-flex gap-3 align-items-center justify-content-center">
            <div>{header}</div>
            <i className="fe fe-chevron-down" style={buttonStyle} />
          </div>
        </button>
      </div>

      <div className="collapse" id={id}>
        {children}
      </div>
    </div>
  );
}
