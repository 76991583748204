import { useEffect, useReducer } from "react";

import { Popover } from "bootstrap";

import DateInput from "../../../inputs/form-inputs/DateInput";
import NumberInput from "../../../inputs/form-inputs/NumberInput";

import { formatDate, formatDateTime } from "../../../utils/DateFormatters";
import { getDate } from "../../../../src/utils/DatesAndTime";

// Set tomorrow's date
const today = new Date();
today.setDate(today.getDate() + 1);
const tomorrow = today.toISOString().split("T")[0];

const initialFormState = {
  rotateRefresh: false,
  data: {
    expires_at: tomorrow, // Defaults to expire on tomorrow's date
    invite_code: "",
    max_invite_count: 1, // Set default number of invites to 1
  },
};

const formReducer = (formState, action) => {
  switch (action.type) {
    case "update_data":
      return { ...formState, data: { ...formState.data, ...action.payload } };
    case "toggle_rotate_refresh":
      return { ...formState, rotateRefresh: !formState.rotateRefresh };
    default:
      return formState;
  }
};

const handleGenerateInviteCode = (formDispatch, generateInviteCodeUrl) => {
  formDispatch({ type: "toggle_rotate_refresh" });

  fetch(generateInviteCodeUrl + ".json", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then((data) => {
      formDispatch({ type: "update_data", payload: { invite_code: data.invite_code } });
    });
};

const handleCreateInvite = (formState, socialInvitesUrl) => {
  const body = {
    social_invite: {
      invite_code: formState.data.invite_code,
      max_invite_count: formState.data.max_invite_count,
      expires_at: formState.data.expires_at,
    },
  };

  fetch(socialInvitesUrl + ".json", {
    body: JSON.stringify(body),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
  }).then((response) => {
    if (response.ok) {
      // Refresh page
      window.location.reload();
    }
    throw new Error("Network response was not ok.");
  });
};

const handleDeleteInvite = (id, socialInvitesUrl) => {
  if (!window.confirm("Are you sure you want to remove this account?")) return;

  //Remove invite
  fetch(socialInvitesUrl + "/" + id + ".json", {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
  }).then((response) => {
    if (response.ok) {
      // Refresh page
      window.location.reload();
    }
    throw new Error("Network response was not ok.");
  });
};

const handleCopyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

function InviteModal(props) {
  const { formState, formDispatch, endpoints } = props;

  const generateInviteCodeButtonStyle = {
    transform: formState.rotateRefresh ? "rotate(180deg)" : "",
    transition: "transform 200ms ease",
  };

  return (
    <div className="modal fade" id="generateInviteModal" tabIndex="-1" aria-labelledby="generateInviteModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="generateInviteModalLabel">
              Create an invite
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="d-flex flex-column align-items-center gap-3 w-100">
              <div className="d-flex flex-column w-100">
                <h3>Invite Code</h3>
                <div className="d-flex gap-3">
                  <input type="text" className="form-control" value={formState.data.invite_code} readOnly />
                  <button
                    className="btn btn-primary sort-button animate-button-hover-click"
                    onClick={() => handleGenerateInviteCode(formDispatch, endpoints.generateInviteCodeUrl)}
                  >
                    <i className="fe fe-refresh-cw" style={generateInviteCodeButtonStyle} />
                  </button>
                </div>
              </div>
              <div className="d-flex flex-column w-100">
                <h3>Expires At</h3>
                <DateInput
                  value={formState.data.expires_at}
                  options={{
                    minDate: tomorrow,
                  }}
                  onChange={([date]) => {
                    const dateString = getDate(date);

                    formDispatch({ type: "update_data", payload: { expires_at: dateString } });
                  }}
                />
              </div>
              <div className="d-flex flex-column w-100">
                <h3>Amount of invitees</h3>
                <NumberInput
                  value={formState.data.max_invite_count}
                  onChange={(e) => formDispatch({ type: "update_data", payload: { max_invite_count: e.target.value } })}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={() => handleCreateInvite(formState, endpoints.socialInvitesUrl)}>
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const inviteTableHeaders = [
  { label: "#" },
  { label: "Users Joined" },
  { label: "Max Invites" },
  { label: "Invite Code" },
  { label: "URL", style: { minWidth: 500 } },
  { label: "Created At" },
  { label: "Expires On" },
  { label: "Delete" },
];

export default function ManageInviteDashboard(props) {
  const { invites, endpoints } = props;
  const { socialInviteLandingUrl, socialInvitesUrl, generateInviteCodeUrl } = endpoints;

  const [formState, formDispatch] = useReducer(formReducer, initialFormState);

  useEffect(() => {
    handleGenerateInviteCode(formDispatch, generateInviteCodeUrl);

    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
    [...popoverTriggerList].map((popoverTriggerEl) => new Popover(popoverTriggerEl));
  }, []);

  return (
    <>
      <InviteModal {...{ formState, formDispatch, endpoints }} />

      <div className="d-flex flex-column">
        {invites.length ? (
          <div className="card card-primary overflow-scroll">
            <table className="table table-sm mb-0 border-0">
              <thead>
                <tr>
                  {inviteTableHeaders.map((header) => (
                    <th key={header.label} scope="col" style={header.style}>
                      {header.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {invites.map((invite, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{invite.invite_count}</td>
                    <td>{invite.max_invite_count}</td>
                    <td>{invite.invite_code}</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <a
                          style={{ minWidth: 420 }}
                          href={`${window.location.origin}${socialInviteLandingUrl}/${invite.invite_code}`}
                        >{`${window.location.origin}${socialInviteLandingUrl}/${invite.invite_code}`}</a>
                        <button
                          className="btn btn-sm btn-primary animate-button-hover-click"
                          data-bs-toggle="popover"
                          data-bs-trigger="focus"
                          data-bs-title="Copied to clipboard!"
                          data-bs-content={`Invite Code: ${invite.invite_code} `}
                          onClick={() => handleCopyToClipboard(`${window.location.origin}${socialInviteLandingUrl}/${invite.invite_code}`)}
                        >
                          <i className="fe fe-clipboard" />
                        </button>
                      </div>
                    </td>
                    <td className="text-sm">{formatDateTime(invite.created_at)}</td>
                    <td className="text-sm">{formatDate(invite.expires_at)}</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <button className="btn btn-sm btn-danger animate-button-hover-click" onClick={() => handleDeleteInvite(invite.id, socialInvitesUrl)}>
                          <i className="fe fe-trash" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <div className="card card-primary overflow-scroll">
              <table className="table table-sm mb-0 border-0">
                <thead>
                  <tr>
                    {inviteTableHeaders.map((header) => (
                      <th key={header.label} scope="col" style={header.style}>
                        {header.label}
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
              <span className="d-block mb-2">No invites found.</span>
              <button type="button" className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#generateInviteModal">
                Create Invite
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
