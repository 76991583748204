import { useEffect, useState } from 'react';
import TextInput from '../../../inputs/form-inputs/TextInput';

function ComponentButton({ c, handleSelectComponent }) {
  return (
    <label className="col-5">
      <input type="radio" className="card-input-element" id={c.name} name="component_option" value={c.name} onChange={handleSelectComponent} />
      <div className="card card-input">
        <div className="card-body">
          <div className="d-flex flex-column gap-3">
            {c.name}
            <p className="placeholder-glow d-flex flex-column gap-1 align-items-center">
              <span className={`placeholder placeholder-sm col-${Math.floor(Math.random() * 10) + 3}`} />
              <span className={`placeholder placeholder-sm col-${Math.floor(Math.random() * 10) + 3}`} />
              <span className={`placeholder placeholder-sm col-${Math.floor(Math.random() * 10) + 3}`} />
            </p>
          </div>
        </div>
      </div>
    </label>
  );
}

export default function ComponentSelectionModal({ id, insertIndex, dispatch, componentDefinitions }) {
  const [selected, setSelected] = useState(null);
  const [componentDefinitionsList, setComponentDefinitionsList] = useState(componentDefinitions);

  // Generate props using reducer
  const generateProps = (propSchema) => {
    return propSchema.reduce((acc, prop) => {
      acc[prop.name] = prop.defaultValue ?? '';
      return acc;
    }, {});
  };

  const handleAddComponent = () => {
    const props = selected.propSchema ? generateProps(selected.propSchema) : {};
    const newComponent = { ...selected, id: crypto.randomUUID(), ...(props && { props }) };

    // Add the content to index if insertIndex is present
    if (insertIndex !== undefined) {
      dispatch({ type: 'ADD_CONTENT_AT_INDEX', payload: { index: insertIndex, content: newComponent } });
    } else {
      dispatch({ type: 'ADD_CONTENT', payload: newComponent });
    }
  };

  const handleSelectComponent = (name) => {
    const component = componentDefinitions.find((component) => component.name === name);
    setSelected(component);
  };

  const handleSearchComponent = (event) => {
    const searchValue = event.target.value.toLowerCase();

    if (searchValue) {
      setComponentDefinitionsList(componentDefinitions.filter((c) => c.name.toLowerCase().includes(searchValue)));
      return;
    }

    setComponentDefinitionsList(componentDefinitions);
  };

  useEffect(() => {
    return () => {
      setSelected(null);
    };
  }, []);

  return (
    <div className="modal fade" id={id} tabIndex={-1} aria-labelledby={`${id}-label`} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <p className="modal-title" id={`${id}-label`}>
                Select a component
              </p>
              <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">
                <i className="fe fe-x" />
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="dp-component-selection-modal-body">
              <div>
                <TextInput label="Search" placeholder="Search for a component..." onChange={handleSearchComponent} />
              </div>
              <div className="components">
                {componentDefinitionsList && componentDefinitionsList.length ? (
                  componentDefinitionsList.map((c, index) => <ComponentButton key={index} c={c} handleSelectComponent={() => handleSelectComponent(c.name)} />)
                ) : (
                  <div className="text-center text-muted my-5 py-5">No components found.</div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={handleAddComponent} data-bs-dismiss="modal" disabled={!selected}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
