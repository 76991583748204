import { Modal } from 'bootstrap';
import { useReducer } from 'react';
import { imageInitialState, imageReducer } from '../../../../images/lib';

import ImageCropperInput from '../../../../images/_image_wizard/ImageCropperInput';
import FileDropperInput from '../../../../images/_image_wizard/FileDropperInput';

export default function ImageUploadModal({ id, onChange, state, dispatch }) {
  const [imageState, imageDispatch] = useReducer(imageReducer, imageInitialState());
  const modalId = `imageUpload-${id}-Modal`;
  const { page } = imageState;

  const handleSave = (resImg) => {
    // Programatically close the modal
    const modal = document.getElementById(modalId);
    if (modal) {
      const bsModal = Modal.getInstance(modal) || new Modal(modal);
      bsModal.hide();
    }

    dispatch({
      type: 'SET_IMAGES',
      payload: {
        images: [resImg, ...state.images],
        filteredImages: [resImg, ...state.images],
      },
    });

    onChange({ id: resImg.id, url: resImg.url, alt: '', title: '' });
  };

  return (
    <div className="modal fade" id={modalId} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-3">Upload an image</h1>
          </div>
          <div className="modal-body">
            <div className="file-selector-container">
              {page === 0 && <FileDropperInput {...{ state: imageState, dispatch: imageDispatch }} />}
              {page === 1 && <ImageCropperInput {...{ isStandAlone: true, state: imageState, dispatch: imageDispatch, handleCallback: handleSave }} />}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
