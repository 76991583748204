import { useEffect, useState } from 'react';

import { $getRoot, $getSelection } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';

import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import InitialContentLoader from '../lexical/InitialContentLoader';

import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { HashtagNode } from '@lexical/hashtag';

import { MentionsPlugin, MentionNode } from '../lexical/mentions/MentionPlugin';

import { EmojiPlugin, EmojiToolbar } from '../lexical/emojis/EmojiPlugin';

import LinkedInLargeArticlePreview from '../previews/LinkedInLargeArticlePreview';

import TextInput from '../../../../inputs/form-inputs/TextInput';
import SwitchInput from '../../../../inputs/form-inputs/SwitchInput';

function onError(error) {
  console.error(error);
}

export default function PostContentStep(props) {
  const { formState, formDispatch } = props;

  useEffect(() => {
    if (!formState.data.post) {
      formDispatch({ type: 'update', payload: { isInvalid: true, error: 'Please fill in post content to continue.' } });
      return;
    }

    formDispatch({ type: 'update', payload: { isInvalid: false, error: '' } });
  }, [formState.data.post]);

  const initialConfig = {
    namespace: 'lexical-editor',
    editable: !formState.isDisabled,
    nodes: [HashtagNode, MentionNode],
    onError: console.error,
    theme: {
      root: 'editable-area flex-grow-1',
      link: 'cursor-pointer',
      hashtag: 'editor-text-hashtag',
    },
  };

  const handleChange = function (editorState, editor) {
    editorState.read(() => {
      let content = $generateHtmlFromNodes(editor, null);
      const textContent = content
        .replaceAll(/<p([ >])?[^>]+>/g, '\n') // replace p with line breaks
        .replaceAll(/<br[ /]*>/g, '\n') // replace br with line breaks
        .replaceAll(/<[^>]+>/g, '') // remove any remaining tags
        .trim();

      if (formState.data.post != textContent) {
        formDispatch({ type: 'update_data', payload: { post: textContent } });
      }
    });
  };

  return (
    <>
      <div className="card-body">
        <h2>What would you like to Post?</h2>
        <div>
          <div className="social-post-editor d-flex flex-column">
            <LexicalComposer initialConfig={initialConfig}>
              <PlainTextPlugin contentEditable={<ContentEditable />} placeholder={''} ErrorBoundary={LexicalErrorBoundary} />
              <AutoFocusPlugin />
              <InitialContentLoader initialText={formState.data.post} />
              <OnChangePlugin onChange={handleChange} ignoreSelectionChange={true} />
              <HashtagPlugin />
              <MentionsPlugin />
              <EmojiPlugin />
              <div className="toolbar d-flex">
                <EmojiToolbar />
              </div>
            </LexicalComposer>
          </div>
        </div>
      </div>
      {formState.data.article && (
        <div className="card-body">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex gap-3">
              <h3 className="d-flex align-items-center justify-content-center mb-0">Article Preview</h3>
              <button
                type="button"
                className="btn btn-sm btn-primary"
                style={{ width: 30, height: 30 }}
                data-bs-toggle="modal"
                data-bs-target="#articleModal"
                disabled={formState.isDisabled}
              >
                <i className="fe fe-edit-3" />
              </button>
            </div>

            <ArticleModal {...{ formState, formDispatch }} />
            <LinkedInLargeArticlePreview {...{ formState }} />
          </div>
        </div>
      )}
    </>
  );
}

function ArticleModal(props) {
  const { formState, formDispatch } = props;

  const [article, setArticle] = useState({
    image: formState.data.article?.image || '',
    show_image: formState.data.article?.show_image || true,
    title: formState.data.article?.title || '',
    description: formState.data.article?.description || '',
  });

  const handleSave = () => {
    formDispatch({ type: 'update_data', payload: { article: { ...article } } });
  };

  const handleCancel = () => {
    setArticle({ ...formState.data.article });
  };

  useEffect(() => {
    setArticle({ ...formState.data.article });
  }, [formState.data.article]);

  return (
    <div className="modal fade" id="articleModal" tabIndex="-1" aria-labelledby="articleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="articleModalLabel">
              Edit Article Preview
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-2">
                <label className="text-muted">Image</label>
                <SwitchInput
                  onChange={() => setArticle({ ...article, show_image: !article.show_image })}
                  value={article.show_image}
                  checked={article.show_image}
                  label="Include Thumbnail?"
                  disabled={!article.image}
                />
                <div className="rounded overflow-hidden bg-dark d-flex align-items-center justify-content-center w-100" style={{ height: 300 }}>
                  {formState.data.article?.image ? (
                    <img className="object-fit-contain" src={formState.data.article.image} width="100%" height="100%" />
                  ) : (
                    <p className="text-muted">No Image Preview found for this site.</p>
                  )}
                </div>
              </div>
              <div className="d-flex flex-column gap-2">
                <label className="text-muted">Title</label>
                <TextInput
                  type="text"
                  className="form-control"
                  value={article.title}
                  maxLength={400}
                  onChange={(e) => setArticle({ ...article, title: e.target.value })}
                />
              </div>
              <div className="d-flex flex-column gap-2">
                <label className="text-muted">Description</label>
                <TextInput
                  type="text"
                  maxLength={4086}
                  className="form-control"
                  value={article.description}
                  onChange={(e) => setArticle({ ...article, description: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleCancel}>
              Close
            </button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleSave}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
