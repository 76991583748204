const accountsTableHeaders = [{ label: "#" }, { label: "Profile Pic" }, { label: "Full Name" }, { label: "Scope" }, { label: "Actions" }];

const handleRemoveAccount = (id, endpoints) => {
  if (!window.confirm("Are you sure you want to remove this account?")) return;

  // Remove account
  fetch(`${endpoints.apiUrl}${id}.json`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
  }) // Send a DELETE request to the server
    .then((response) => {
      if (response.ok) {
        // If the request was successful, reload the page
        window.location.reload();
      } else {
        // If the request was not successful, log the error to the console
        console.error("Failed to remove account:", response);
      }
    })
    .catch((error) => {
      // If the request was not successful, log the error to the console
      console.error("Failed to remove account:", error);
    });
};

export default function AccountsTable(props) {
  const { accounts, endpoints } = props;

  return (
    <div>
      {accounts.length ? (
        <div className="card card-primary overflow-hidden">
          <table className="table table-sm card-table mb-0 border-0">
            <thead>
              <tr>
                {accountsTableHeaders.map((header) => (
                  <th key={header.label} scope="col">
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {accounts.map((account, index) => (
                <tr key={account.id}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    {account.picture ? (
                      <img src={account.picture} style={{ borderRadius: "50%", width: 32, height: 32 }} />
                    ) : (
                      <div className="border d-flex justify-content-center align-items-center" style={{ borderRadius: "50%", height: 32, width: 32 }}>
                        <i className="fe fe-user" />
                      </div>
                    )}
                  </td>
                  <td>
                    {account.name}{" "}
                    <a href={`https://www.linkedin.com/in/${account.vanity_name}`}>
                      <i className="fe fe-linkedin" />
                    </a>
                  </td>
                  <td className="text-capitalize">{account.scope ?? "user"}</td>
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      <a as="button" className="btn btn-primary" href={`/_sf/admin/social/accounts/${account.id}/edit`}>
                        Edit
                      </a>
                      <button className="btn btn-danger" onClick={() => handleRemoveAccount(account.id, endpoints)}>
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <div className="card card-primary overflow-hidden">
            <table className="table table-sm card-table mb-0 border-0">
              <thead>
                <tr>
                  {accountsTableHeaders.map((header) => (
                    <th key={header.label} scope="col">
                      {header.label}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          </div>
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
            <span className="d-block mb-2">No accounts found.</span>
            <a className="btn btn-sm btn-primary" type="button" href={endpoints.manageInviteUrl}>
              Send an invitation
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
