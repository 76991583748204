import { useEffect, useState } from 'react';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $getSelection, $isRangeSelection, $createTextNode, createCommand } from 'lexical';

import { EmojiToolbar } from './EmojiToolbar';
import { ADD_EMOJI_COMMAND } from "./EmojiCommands";

function EmojiPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      ADD_EMOJI_COMMAND,
      (payload) => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          selection.insertNodes([$createTextNode(payload)]);
        }
        return true;
      },
      1
    );
  }, []);

  return (null);
}

export { EmojiPlugin, EmojiToolbar };