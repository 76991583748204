export const imageInitialState = (image) => ({
  dataUrl: image || '',
  filename: '',
  originalFilename: undefined,
  page: 0,
});

export const imageReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IMAGE':
      return {
        ...state,
        dataUrl: action.payload.dataUrl,
        filename: action.payload.filename,
        originalFilename: action.payload.originalFilename,
        page: 1,
      };
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload.page,
        dataUrl: action.payload.dataUrl || state.dataUrl,
        filename: action.payload.filename || state.filename,
      };
    case 'SET_FILENAME':
      return {
        ...state,
        filename: action.payload.filename,
      };
    default:
      return state;
  }
};

const httpRequest = async (url, options = {}) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };

  if (options.queryParams) {
    const queryParams = new URLSearchParams(options.queryParams);

    url = url + '?' + queryParams.toString();
  }

  const response = await fetch(url, { headers: defaultHeaders, ...options });
  return await response.json();
};

export const getAuthToken = async () => {
  try {
    const response = await httpRequest('/_sf/api/v1/auth_tokens.json', {
      method: 'POST',
      body: JSON.stringify({
        scope: ['image/upload', 'image/delete'],
      }),
    });
    const data = await response;
    return data.token;
  } catch (error) {
    console.error('Error:', error);
  }
};

const IMAGE_SERVICE_URL = 'https://image-manager.sourceflow.co.uk';

export const getImages = async (authorizationToken, size) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        authorization: `Bearer ${authorizationToken}`,
      },
      queryParams: {
        size,
      },
    };

    if (size != null) {
      options.queryParams = { size };
    }

    const response = await httpRequest(`${IMAGE_SERVICE_URL}/api/v1/images`, options);
    const data = await response;

    if (data.error) {
      throw new Error(data.error);
    }

    return data;
  } catch (error) {
    console.error('Error getting all the images from the Lambda:', error);
  }
};

export const uploadImage = async (authorizationToken, base64, filename) => {
  try {
    const response = await httpRequest(`${IMAGE_SERVICE_URL}/api/v1/images/upload`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${authorizationToken}`,
      },
      body: JSON.stringify({
        image_name: filename,
        image: base64,
      }),
    });
    const data = await response;

    if (data.error) {
      throw new Error(data.error);
    }

    return data;
  } catch (error) {
    console.error('Error uploading images to Lambda:', error);
  }
};

export const authorisedGetImages = async (size) => {
  const token = await getAuthToken();
  return getImages(token, size);
};

export const authorisedUploadImage = async (base64, filename) => {
  const token = await getAuthToken();
  return uploadImage(token, base64, filename);
};
