import { useReducer, useRef, useEffect } from 'react';

import { PostConfigStep, PostContentStep, PostAccountsStep, PostSchedulingStep } from './steps';

const initialFormState = (mode, post, step) => {
  return {
    status: '',
    error: '',
    isLoading: false,
    isSaving: false,
    isInvalid: false,
    isArticleInvalid: false,
    isDisabled: post && (post.status === 'publishing' || post.status === 'published'),
    data:
      mode === 'edit'
        ? post
        : {
            name: '',
            post: '',
            scheduled_at: null,
            scheduled_option: null,
            linkedin_users: [],
            linkedin_organisations: [],
            promotion_option: 0,
            promotion_option_content: '',
            status: '',
            article: {
              source: '',
              title: '',
              description: '',
              show_image: true,
              image: '',
            },
          },
    step: step ? Number(step) : 1,
    promotionOptions: [],
  };
};

const formReducer = (state, action) => {
  const urlParams = new URLSearchParams(window.location.search);

  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };
    case 'update_data':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'next':
      urlParams.set('step', state.step + 1);
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
      return { ...state, step: state.step + 1 };
    case 'back':
      urlParams.set('step', state.step - 1);
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
      return { ...state, step: state.step - 1 };
    default:
      return state;
  }
};

const handleCreatePost = (endpoints, formState, formDispatch) => {
  formDispatch({ type: 'update', payload: { isSaving: true, status: 'Saving...' } });

  const body = {
    social_post: {
      name: formState.data.name,
      post: formState.data.post,
      promotion_option: formState.data.promotion_option,
      promotion_option_content: formState.data.promotion_option_content,
      article: {
        source: formState.data.article?.source,
        title: formState.data.article?.title,
        description: formState.data.article?.description,
        show_image: formState.data.article?.show_image,
        image: formState.data.article?.image,
      },
    },
  };

  // Fetch Request to create post
  fetch(`${endpoints.socialPostsAPIUrl}.json`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', credentials: 'same-origin' },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        formDispatch({ type: 'update', payload: { isSaving: true, status: 'Saved!' } });

        return response.json();
      } else {
        formDispatch({ type: 'update', payload: { status: 'Failed to save.' } });

        // If the request was not successful, log the error to the console
        console.error('Failed to create post:', response);
      }
    })
    .then((data) => {
      formDispatch({ type: 'update_data', payload: { id: data.social_post.id, status: data.social_post.status } });

      formDispatch({ type: 'next' });

      history.pushState({}, '', `/_sf/admin/social/posts/${data.social_post.id}/edit?step=2`);
    })
    .catch((error) => {
      formDispatch({ type: 'update', payload: { status: 'Failed to save.' } });

      // If the request was not successful, log the error to the console
      console.error('Failed to create post:', error);
    });
};

const handleUpdatePost = (formState, formDispatch) => {
  formDispatch({ type: 'update', payload: { isSaving: true, status: 'Saving...' } });

  const body = {
    social_post: {
      name: formState.data.name,
      promotion_option: formState.data.promotion_option,
      promotion_option_content: formState.data.promotion_option_content,
      post: formState.data.post,
      linkedin_users: formState.data.linkedin_users,
      linkedin_organisations: formState.data.linkedin_organisations,
      article: {
        source: formState.data.article?.source,
        title: formState.data.article?.title,
        description: formState.data.article?.description,
        show_image: formState.data.article?.show_image,
        image: formState.data.article?.image,
      },
    },
  };

  // Fetch Request to update post
  fetch(`/_sf/api/v1/cms/social_posts/${formState.data.id}.json`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', credentials: 'same-origin' },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        formDispatch({ type: 'update', payload: { isSaving: false, status: 'Saved!' } });
      } else {
        // If the request was not successful, log the error to the console
        console.error('Failed to update post:', response);

        formDispatch({ type: 'update', payload: { isSaving: false, status: `Failed to save :(` } });
      }
    })

    .catch((error) => {
      // If the request was not successful, log the error to the console
      console.error('Failed to update post:', error);
      formDispatch({ type: 'update', payload: { isSaving: false, status: `Failed to save :(` } });
    });
};

const handleSubmitPost = (formState, formDispatch, endpoints) => {
  if (!window.confirm('Are you sure you want to submit this post?')) return;

  formDispatch({ type: 'update', payload: { isSaving: true, status: 'Saving...' } });

  const body = {
    social_post: {
      id: formState.data.id,
      scheduled_at: formState.data.scheduled_at,
      scheduled_option: formState.data.scheduled_option,
    },
  };

  // Fetch Request to update post
  fetch(`${endpoints.socialPostsSubmitUrl}.json`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', credentials: 'same-origin' },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        formDispatch({ type: 'update', payload: { isSaving: false, status: 'Saved!' } });

        window.location.replace(endpoints.postDashboardUrl);
      } else {
        // If the request was not successful, log the error to the console
        console.error('Failed to update post:', response);

        formDispatch({ type: 'update', payload: { isSaving: false, status: `Failed to save :(` } });
      }
    })

    .catch((error) => {
      // If the request was not successful, log the error to the console
      console.error('Failed to update post:', error);
      formDispatch({ type: 'update', payload: { isSaving: false, status: `Failed to save :(` } });
    });
};

export default function ManagePost(props) {
  const { mode, linkedin_users, linkedin_organisations, post, endpoints } = props;

  // Check query params for step
  const urlParams = new URLSearchParams(window.location.search);
  const step = urlParams.get('step');

  const [formState, formDispatch] = useReducer(formReducer, initialFormState(mode, post, step));

  const isFirstRenderRef = useRef(true);

  const controlStep = () => {
    switch (formState.step) {
      case 1:
        return <PostConfigStep {...{ formState, formDispatch, endpoints }} />;
      case 2:
        return <PostContentStep {...{ formState, formDispatch }} />;
      case 3:
        return <PostAccountsStep {...{ formState, formDispatch, linkedin_users, linkedin_organisations }} />;
      case 4:
        return <PostSchedulingStep {...{ formState, formDispatch, post }} />;
      default:
        return 'default';
    }
  };

  useEffect(() => {
    // Skip if it's the first render, we only want to save on value changes
    if (isFirstRenderRef.current) {
      // Sets first render to false
      isFirstRenderRef.current = false;
      // Returns to skip the rest of the code below
      return;
    }

    // Skip if ID is not present meaning it's a new post that hasn't been saved yet
    if (!formState.data.id) return;

    // Updates the UI to show that it's saving
    formDispatch({ type: 'update', payload: { isSaving: true, status: 'Saving...' } });

    // Saves to API on every value change
    const debounceSavePost = setTimeout(() => {
      handleUpdatePost(formState, formDispatch);
    }, 1000);

    // Cleanup function to clear the timeout when it's re-rendered or unmounted
    return () => clearTimeout(debounceSavePost);
  }, [
    formState.data.name,
    formState.data.post,
    formState.data.promotion_option,
    formState.data.promotion_option_content,
    formState.data.linkedin_users,
    formState.data.linkedin_organisations,
    formState.data.article?.show_image,
    formState.data.article?.title,
    formState.data.article?.description,
  ]);

  return (
    <div className="d-flex flex-column gap-4" style={{ maxWidth: 1100 }}>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-3 my-2 align-items-center justify-content-center">
              <h3 className="mb-0">Step {formState.step}</h3>
              {formState.isLoading && (
                <div className="spinner-grow spinner-grow-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center gap-3">
              <span className="text-muted fst-italic">{formState.status}</span>
              {formState.data.id ? (
                formState.data.status ? (
                  <span className="badge bg-primary-soft mb-0">
                    {formState.data.status ? formState.data.status.charAt(0).toUpperCase() + formState.data.status.slice(1) : 'Draft'}
                  </span>
                ) : (
                  <span className="badge bg-primary-soft mb-0">{`Draft`}</span>
                )
              ) : null}
            </div>
          </div>
        </div>
        {controlStep()}
        <div className="border-top">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <button className="btn btn-info" onClick={() => formDispatch({ type: 'back' })} disabled={formState.step <= 1}>
                  Previous Step
                </button>
              </div>
              <div className="d-flex gap-3 align-items-center">
                <div>
                  <span className="text-muted fst-italic mb-0">{formState.error}</span>
                </div>
                {formState.step === 1 && mode !== 'edit' && !formState.data.id ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => handleCreatePost(endpoints, formState, formDispatch)}
                    disabled={formState.isDisabled || formState.isInvalid || formState.isSaving || formState.isLoading}
                  >
                    Save & Continue
                  </button>
                ) : formState.step === 4 ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => handleSubmitPost(formState, formDispatch, endpoints)}
                    disabled={formState.isDisabled || formState.isInvalid || formState.isSaving || formState.isLoading}
                  >
                    {formState.isSaving ? (
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <span>Saving</span>
                        <div className="spinner-border spinner-border-sm" role="isSaving" />
                      </div>
                    ) : (
                      'Save'
                    )}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={() => formDispatch({ type: 'next' })}
                    disabled={formState.isInvalid || formState.isArticleInvalid}
                  >
                    Continue
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
