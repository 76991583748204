/**
 * Editor for WYSIWYG Inputs
 * @description Dependencies: `@tinymce/tinymce-react`
 */
import { Editor } from "@tinymce/tinymce-react";

export default function FormattedTextInput(props) {
  return (
    <Editor
      init={{
        menubar: false,
        // skin and content_css is disabled to avoid the normal loading process and is instead loaded as a string via content_style
        skin: false,
        content_css: false,
        content_style: "body { color: white; font-family: sans-serif; font-size: 15px; }",
        plugins: "accordion aisuggest autolink code fullscreen hr image link lists media table",
        toolbar:
          "undo redo | styles | bold italic forecolor removeformat | numlist bullist hr | table accordion image link media | alignleft aligncenter alignright alignjustify | aisuggest fullscreen code",
        media_poster: false,
        media_alt_source: false,
        convert_urls: false,
        link_class_list: [
          { title: 'None', value: '' },
          { title: 'Primary Colour Button', value: 'primaryBtn' },
          { title: 'Secondary Colour Button', value: 'secondaryBtn' },
          { title: 'Custom Style Button', value: 'customBtn' },
          { title: 'Custom Style Link', value: 'customLink' }
        ],
        sandbox_iframes: false,
      }}
      {...props}
    />
  );
}
