import { useReducer } from "react";
import AccountInfo from "../_components/AccountInfo";
import AccountOrganisations from "../_components/AccountOrganisations";

const initialFormState = {
  status: "",
  error: "",
  isInitialLoad: true,
  isInvalid: false,
  isSaving: false,
  isLoading: false,
  isDisabled: true,
  data: { organisations: null },
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    case "update_data":
      return { ...state, data: { ...state.data, ...action.payload } };
    case "next":
      return { ...state, step: state.step + 1 };
    case "back":
      return { ...state, step: state.step - 1 };
    default:
      return state;
  }
};

export default function EditAccount(props) {
  const { linkedin_user, is_admin, granted_organisations, endpoints } = props;

  const [formState, formDispatch] = useReducer(formReducer, initialFormState);

  return (
    <div className="d-flex flex-column gap-4" style={{maxWidth: 1100}}>
      <AccountInfo {...{ linkedin_user }} />
      <AccountOrganisations {...{ linkedin_user, is_admin, granted_organisations, endpoints, formState, formDispatch }} />
    </div>
  );
}
