import { useEffect, useReducer, useState } from 'react';
import { getInitialBuilderState, builderReducer, convertMetadata, formatContent } from '../lib';
import { dataStateKeyword } from '../lib';

import BuilderEditor from '../BuilderEditor';
import BuilderSidebar from '../BuilderSidebar';

export default function NewDynamicPage({ allSitePages, categoriesWithTemplates, categoriesLinked, componentDefinitions, endpoints }) {
  const { indexUrl, createUrl, previewSource } = endpoints;

  const [initialState, _] = useState(getInitialBuilderState());

  const [state, dispatch] = useReducer(builderReducer, initialState);

  const handleSave = () => {
    const dynamicPage = {
      ...state.data,
      url_slug: state.parentSlug ? (state.parentSlug === '/' ? state.data.url_slug : `${state.parentSlug}/${state.data.url_slug}`) : state.data.url_slug,
      metadata: convertMetadata(state.data.metadata),
      content: formatContent(state.data.content),
    };

    fetch(`${createUrl}.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
      body: JSON.stringify({
        dynamic_page: dynamicPage,
        files: state.attachmentUploadBuffer,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to save page');
        }
        window.location.replace(indexUrl);
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE', payload: { isError: true, error: JSON.stringify(error) } });
      });
  };

  useEffect(() => {
    sessionStorage.setItem(dataStateKeyword, JSON.stringify([])); // Always initialise and clear the data state on first render load.
  }, []);

  useEffect(() => {
    if (!state.isUndoRedo) {
      const dataStates = JSON.parse(sessionStorage.getItem(dataStateKeyword)) || [];
      const data = JSON.stringify(state.data);
      dataStates.push(data);
      sessionStorage.setItem(dataStateKeyword, JSON.stringify(dataStates));

      dispatch({
        type: 'SET_DATA_STATE_INDEX',
        payload: { index: JSON.parse(sessionStorage.getItem(dataStateKeyword))?.length - 1 },
      });

      return;
    }

    dispatch({ type: 'SET_IS_UNDO_REDO', payload: { isUndoRedo: false } });
  }, [state.data]);

  return (
    <div className="dp-editor">
      <BuilderSidebar {...{ state, dispatch, allSitePages, categoriesWithTemplates, categoriesLinked, componentDefinitions, endpoints }} />
      <BuilderEditor {...{ state, dispatch, handleSave, previewSource, indexUrl, initialState }} />
    </div>
  );
}
