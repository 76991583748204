import React, { useState, useRef } from 'react';
import ReactCrop, { convertToPixelCrop } from 'react-image-crop';
import { centerAspectCrop } from './lib';

import TextInput from '../../inputs/form-inputs/TextInput';
import { authorisedUploadImage } from '../lib';

const aspectRatios = [
  { label: 'Freeform', value: undefined },
  { label: '1:1', value: 1 },
  { label: '16:9', value: 16 / 9 },
  { label: '9:16', value: 1.5 },
  { label: '4:3', value: 4 / 3 },
  { label: '3:4', value: 0.75 },
];

export default function ImageCropperInput({ isStandAlone, state, dispatch, endpoints, handleCallback }) {
  const [isSaving, setIsSaving] = useState(false);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(undefined); // Either a number or undefined

  const imgRef = useRef(null);
  // const previewCanvasRef = useRef(null);

  const setNaturalSize = (element) => {
    const { width, height } = element;
    setCrop({
      x: 0,
      y: 0,
      width: width,
      height: height,
      unit: 'px',
    });
    setCompletedCrop({
      x: 0,
      y: 0,
      width: width,
      height: height,
      unit: 'px',
    });
  };

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
      return;
    }
    setNaturalSize(e.currentTarget);
  };

  const handleReset = () => {
    setCrop(null);
    setCompletedCrop(null);
    setScale(1);
    setRotate(0);
    setAspect(undefined);
  };

  const handleMaximise = () => {
    if (!imgRef.current) return;
    setNaturalSize(imgRef.current);
    setScale(1);
    setRotate(0);
    setAspect(undefined);
  };

  const handleToggleAspectOnClick = (aspect) => {
    setAspect(aspect);

    if (imgRef.current) {
      const { width, height } = imgRef.current;
      const newCrop = centerAspectCrop(width, height, aspect);
      setCrop(newCrop);
      // Updates the preview
      setCompletedCrop(convertToPixelCrop(newCrop, width, height));
    }
  };

  const handleUpload = (base64Data) => {
    setIsSaving(true);
    authorisedUploadImage(base64Data, state.filename, endpoints)
      .then((res) => {
        if (!res.image) return;
        if (isStandAlone) {
          handleCallback(res.image);
          return;
        }
        window.location.href = endpoints.adminImagesUrl;
      })
      .catch((error) => {
        console.log('Error with uploading image:', error);
        setIsSaving(false);
      });
  };

  const handleSave = async () => {
    const image = imgRef.current;
    if (!image || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    // This will size relative to the uploaded image
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY);
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    // You might want { type: "image/jpeg", quality: <0 to 1> } to reduce image size
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });

    // Convert the blob to base64
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64Data = reader.result;
      handleUpload(base64Data.split(',')[1]);
    };
  };

  const handleOnChange = (e) => {
    dispatch({ type: 'SET_FILENAME', payload: { filename: e.target.value } });
  };

  const isInvalidFilename = state.filename === '' || state.originalFilename === state.filename;
  const isInvalidCrop = !completedCrop || completedCrop.width === 0 || completedCrop.height === 0;

  // useDebounceEffect(
  //   async () => {
  //     if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
  //       // We use canvasPreview as it's much faster than imgPreview.
  //       canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
  //     }
  //   },
  //   100,
  //   [completedCrop, scale, rotate]
  // );

  return (
    <div className="react-cropper-parent-container">
      <div className="input-group">
        <span className="input-group-text">Filename</span>
        <TextInput {...{ value: state.filename || '', onChange: handleOnChange, placeholder: "Enter image name here..." }} />
      </div>
      <div className="d-flex justify-content-between w-100">
        <button className="btn btn-outline" onClick={() => dispatch({ type: 'SET_PAGE', payload: { page: 0, dataUrl: '', filename: '' } })}>
          <i className="fe fe-arrow-left" />
        </button>
        <div className="d-flex gap-3">
          <button className="btn btn-outline" onClick={handleReset}>
            <i className="fe fe-refresh-cw" />
          </button>
          <button className="btn btn-outline" onClick={handleMaximise}>
            <i className="fe fe-maximize" />
          </button>
          {aspectRatios.map((a) => (
            <React.Fragment key={a.label}>
              <input
                type="radio"
                className="btn-check"
                name="options-aspect"
                id={`option-${a.label}`}
                autoComplete="off"
                checked={aspect === a.value}
                onChange={() => handleToggleAspectOnClick(a.value)}
              />
              <label className="btn" htmlFor={`option-${a.label}`}>
                {a.label}
              </label>
            </React.Fragment>
          ))}
        </div>
        <button className="btn btn-primary" onClick={handleSave} disabled={!!isSaving || !!isInvalidFilename || !!isInvalidCrop}>
          {isSaving ? (
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <span>Save</span>
          )}
        </button>
      </div>
      <div className="react-cropper-container">
        <ReactCrop className="react-cropper" crop={crop} aspect={aspect} onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)}>
          <img ref={imgRef} src={state.dataUrl} onLoad={onImageLoad} crossOrigin="anonymous" style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }} />
        </ReactCrop>
      </div>
      {/* {!!completedCrop && (
        <div>
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        </div>
      )} */}
    </div>
  );
}
