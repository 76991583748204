import TemplatePropField from './TemplatePropField';
import TemplatePropFieldCategory from './TemplatePropFieldCategory';
import SortableItem from '../../../../../dnd-kit/SortableItem/SortableItem';

export default function SortableTemplate({
  index,
  contentId,
  state,
  dispatch,
  templateName,
  templateItem,
  template_schema,
  images,
  handleRemoveTemplateItem,
  categoriesLinked,
}) {
  const { id } = templateItem;

  return (
    <SortableItem id={id}>
      <div className="accordion dp-template-accordion" id={`accordion-${id}`}>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${id}`}
              aria-expanded="true"
              aria-controls={`#collapse-${id}`}
            >
              <span>Template Item #{index + 1}</span>
            </button>
          </h2>
          <div id={`collapse-${id}`} className="accordion-collapse collapse" data-bs-parent={`#accordion-${id}`}>
            <div className="accordion-body">
              <div className="dp-template" key={id}>
                <div className="d-flex align-items-center justify-content-center">
                  <hr className="w-100" />
                  <button className="btn btn-sm btn-outline" onClick={() => handleRemoveTemplateItem(templateName, id)}>
                    <i className="fe fe-trash-2" />
                  </button>
                  <hr className="w-100" />
                </div>
                {template_schema.map((prop, index) => {
                  if (prop.category_id) {
                    const category = categoriesLinked.find((c) => c.id === prop.category_id);
                    return <TemplatePropFieldCategory key={index} {...{ contentId, templateName, templateItem, dispatch, category, ...prop }} />;
                  }

                  return <TemplatePropField key={`${id}-${prop.name}`} {...{ contentId, templateName, templateItem, state, dispatch, images, ...prop }} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SortableItem>
  );
}
