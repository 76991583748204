import { useState } from 'react';

import TreeLeaf from './TreeLeaf';

const SPLIT_INDEX = 5;

export default function TreeLeaves({ pages, dispatch, endpoints, isSimple, currentPage }) {
  const [isShowMore, setIsShowMore] = useState(false);

  const firstPart = pages.slice(0, SPLIT_INDEX);
  const secondPart = pages.slice(SPLIT_INDEX);

  return (
    <div className="d-flex flex-column" style={{ paddingLeft: 18 }}>
      <div className="d-flex flex-column">
        {firstPart.map((page) => (
          <TreeLeaf key={page.id} {...{ page, endpoints, dispatch, isSimple, currentPage }} />
        ))}
      </div>
      {isShowMore && secondPart.map((page) => <TreeLeaf key={page.id} {...{ page, endpoints, dispatch, isSimple, currentPage }} />)}
      {secondPart.length > 0 && (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <button className="btn text-muted" onClick={() => setIsShowMore((isShowMore) => !isShowMore)}>
            {isShowMore ? 'Show less' : `Show ${secondPart.length} more...`}
          </button>
        </div>
      )}
    </div>
  );
}
