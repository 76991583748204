import { Modal } from 'bootstrap';
import { useEffect, useReducer } from 'react';
import { imageInitialState, imageReducer } from '../../../../images/lib';

import ImageCropperInput from '../../../../images/_image_wizard/ImageCropperInput';

export default function ImageCropModal({ id, image, onChange, state, dispatch }) {
  const [imageState, imageDispatch] = useReducer(imageReducer, imageInitialState(image.url));
  const modalId = `imageCrop-${id}-Modal`;

  const handleSave = (resImg) => {
    // Programatically close the modal
    const modal = document.getElementById(modalId);
    if (modal) {
      const bsModal = Modal.getInstance(modal) || new Modal(modal);
      bsModal.hide();
    }

    dispatch({
      type: 'SET_IMAGES',
      payload: {
        images: [resImg, ...state.images],
        filteredImages: [resImg, ...state.images],
      },
    });

    onChange({ id: resImg.id, url: resImg.url, alt: image?.alt || '', title: image?.title || '' });
  };

  useEffect(() => {
    imageDispatch({ type: 'SET_IMAGE', payload: { dataUrl: image.url, filename: image.filename } });
  }, [image]);

  return (
    <div className="modal fade" id={modalId} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-3">Select an image</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="file-selector-container">
              <ImageCropperInput {...{ isStandAlone: !!image, state: imageState, dispatch: imageDispatch, handleCallback: handleSave }} />
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
