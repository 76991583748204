import { useEffect, useState, useRef } from 'react';
import { ERRORS, VIEWPORT_OPTIONS } from './lib';

import ViewportOptions from './_builder_editor/ViewportOptions';
import PageOptionsBar from './_builder_editor/PageOptionsBar';

export default function BuilderEditor({ state, dispatch, previewSource, indexUrl, handleSave, handleDelete, initialState }) {
  const iFrameRef = useRef(null);
  const [viewport, setViewport] = useState(0);
  const [source, setSource] = useState(previewSource);
  const [isLoading, setIsLoading] = useState(false);

  const handleLoad = () => {
    const content = JSON.stringify(state.data.content);
    iFrameRef.current.contentWindow.postMessage({ type: 'SET_CONTENT', message: content }, '*');
  };

  const handleFocusContent = () => {
    if (state.componentBeingEditedIndex === undefined) return;

    const currentContentBeingEditedId = state.data.content[state.componentBeingEditedIndex].id;

    const message = JSON.stringify({ id: currentContentBeingEditedId });

    iFrameRef.current.contentWindow.postMessage({ type: 'FOCUS_CONTENT', message }, '*');
  };

  const handleClearFocusContent = () => {
    if (state.componentClearFocusId === undefined) return;

    const message = JSON.stringify({ id: state.componentClearFocusId });

    iFrameRef.current.contentWindow.postMessage({ type: 'CLEAR_FOCUS_CONTENT', message }, '*');

    dispatch({ type: 'SET_COMPONENT_CLEAR_FOCUS', payload: { id: undefined } });
  };

  const handleForceRefresh = () => {
    setIsLoading(true);
    setSource(`${previewSource}?t=${new Date().getTime()}`);

    setTimeout(() => {
      handleLoad();
      setIsLoading(false);
    }, 750);
  };

  const validatePage = () => {
    const errorKeys = [];

    if (!state.data.title) {
      errorKeys.push(ERRORS[0].key);
    }

    if (!state.data.url_slug) {
      errorKeys.push(ERRORS[1].key);
    }

    if (state.data.url_slug && state.data.url_slug !== '/' && /(^\/+|\/+$|\s)/.test(state.data.url_slug)) {
      errorKeys.push(ERRORS[2].key);
    }

    const isError = errorKeys.length > 0;
    const errorMessages = errorKeys.map((err) => ERRORS.find((error) => error.key === err).message).join(', ');

    dispatch({
      type: 'UPDATE',
      payload: {
        isError,
        error: isError ? errorMessages : null,
        errorKeys,
      },
    });
  };

  useEffect(() => {
    validatePage();
  }, [state.data.title, state.data.url_slug]);

  useEffect(() => {
    const iframe = iFrameRef.current;
    if (!iframe) return;

    // Add event listener to the iframe to run handleLoad when the iframe is loaded
    iframe.addEventListener('load', handleLoad);
    handleLoad(); // Important: Run handleLoad after the event listener to ensure it works properly

    return () => {
      iframe.removeEventListener('load', handleLoad);
    };
  }, [state.data.content]);

  useEffect(() => {
    handleFocusContent();
  }, [state.componentBeingEditedIndex]);

  useEffect(() => {
    handleClearFocusContent();
  }, [state.componentClearFocusId]);

  return (
    <div className="dp-editor-right">
      <div className="top-input">
        <div className="placeholder-left" />
        <ViewportOptions {...{ viewport, setViewport }} />
        <PageOptionsBar {...{ state, dispatch, initialState, indexUrl, handleSave, handleDelete, handleForceRefresh }} />
      </div>
      <div className="iframe-preview">
        {isLoading && (
          <div className="spinner-overlay">
            <div className="spinner-border text-dark" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <iframe ref={iFrameRef} src={source} className={`${VIEWPORT_OPTIONS[viewport].className}`} />
      </div>
    </div>
  );
}
