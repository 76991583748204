/*
This is a stub component that forwards the props onto the Google Chart component
It is needed to expose the package to the react-rails view helpers
*/

import React from 'react';
import Chart from 'react-google-charts';

class GoogleChart extends React.Component {
  render(){
    return <Chart {...this.props} />;
  }
}

export default GoogleChart;