import Card from "../../card/Card";

export default function ErrorMessage(props) {
  const { messages } = props;
  return (
    <Card cardClass="bg-danger border">
      <h3 className="d-flex align-items-center gap-2">
        <i className="fe fe-alert-triangle" />
        {messages.code && `${messages.code} |`} {messages.description}
      </h3>
      <ul>
        {messages.details.map((each, index) => (
          <li key={index}>{each}</li>
        ))}
      </ul>
    </Card>
  );
}
