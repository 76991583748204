export const toUrlSlug = (input) => {
  return input
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading and trailing whitespace
    .replace(/[^a-z0-9\s-_]/g, '') // Remove non-alphanumeric characters except hyphens and underscores
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/^-+|-+$/g, ''); // Trim leading and trailing hyphens
};

export const getQueryParam = (param) => {
  return new URLSearchParams(window.location.search).get(param);
};
