import { useEffect, useState } from "react";

const orgsTableHeader = [{ label: "Organisation Picture" }, { label: "Page Name" }, { label: "Vanity Name" }, { label: "Actions" }];

const handleGrantOrg = (endpoints, linkedin_user, org, setIsLoading, setIsDisabled) => {
  setIsLoading(true);
  setIsDisabled(true);

  const body = {
    social_account: {
      id: linkedin_user.id,
      organisation_urn: org.id,
    },
  };

  fetch(`${endpoints.linkedin.grantOrgUrl}.json`, {
    method: "POST",
    headers: { "Content-Type": "application/json", credentials: "same-origin" },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        setIsLoading(false);
        setIsDisabled(false);
        window.location.reload();
      } else {
        setIsLoading(false);
        setIsDisabled(false);
        console.error("Failed to grant organisation:", response);
      }
    })
    .catch((error) => {
      setIsLoading(false);
      setIsDisabled(false);
      console.error("Failed to grant organisation:", error);
    });
};

const handleRevokeOrg = (endpoints, linkedin_user, org, setIsLoading, setIsDisabled) => {
  setIsLoading(true);
  setIsDisabled(true);

  const body = {
    social_account: {
      id: linkedin_user.id,
      organisation_id: org.id,
    },
  };

  fetch(`${endpoints.linkedin.revokeOrgUrl}.json`, {
    method: "POST",
    headers: { "Content-Type": "application/json", credentials: "same-origin" },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        setIsLoading(false);
        setIsDisabled(false);
        window.location.reload();
      } else {
        setIsLoading(false);
        setIsDisabled(false);
        console.error("Failed to revoke organisation:", response);
      }
    })
    .catch((error) => {
      setIsLoading(false);
      setIsDisabled(false);
      console.error("Failed to revoke organisation:", error);
    });
};

const handleGetAllOrganisations = (linkedin_user, formDispatch) => {
  const body = {
    social_account: {
      id: linkedin_user.id,
    },
  };

  fetch(`/_sf/api/v1/cms/social_accounts/organisations.json`, {
    method: "POST",
    headers: { "Content-Type": "application/json", credentials: "same-origin" },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw new Error("Failed to fetch organisations", response);
    })
    .then((data) => {
      formDispatch({ type: "update", payload: { isInitialLoad: false } });
      formDispatch({ type: "update_data", payload: { organisations: data } });
    })
    .catch((error) => {
      console.error("Failed to fetch organisations:", error);
    });
};

function GrantButton(props) {
  const { endpoints, linkedin_user, org, isDisabled, setIsDisabled } = props;

  const [isLoading, setIsLoading] = useState(false);

  return (
    <button
      className="btn btn-success"
      onClick={() => handleGrantOrg(endpoints, linkedin_user, org, setIsLoading, setIsDisabled)}
      disabled={isLoading || isDisabled}
    >
      {isLoading ? "Granting.." : "Grant"}
    </button>
  );
}

function RevokeButton(props) {
  const { endpoints, linkedin_user, org, isDisabled, setIsDisabled } = props;

  const [isLoading, setIsLoading] = useState(false);

  return (
    <button className="btn btn-danger" onClick={() => handleRevokeOrg(endpoints, linkedin_user, org, setIsLoading, setIsDisabled)} disabled={isDisabled}>
      {isLoading ? "Revoking.." : "Revoke"}
    </button>
  );
}

export default function AccountOrganisations(props) {
  const { linkedin_user, is_admin, granted_organisations, endpoints, formState, formDispatch } = props;

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    handleGetAllOrganisations(linkedin_user, formDispatch);
  }, []);

  return (
    <div>
      <div className="card card-primary overflow-hidden">
        <table className="table table-sm card-table mb-0 border-0">
          <thead>
            <tr>
              {orgsTableHeader.map((header) => (
                <th key={header.label} scope="col">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {is_admin ? (
            <tbody>
              {granted_organisations?.length
                ? granted_organisations.map((org) => (
                    <tr key={org.id}>
                      <td>
                        <div
                          className="border overflow-hidden rounded-circle d-flex align-items-center justify-content-center"
                          style={{ height: 40, width: 40 }}
                        >
                          {org.picture ? <img height={40} width={40} src={org.picture} /> : <i className="fe fe-briefcase" />}
                        </div>
                      </td>
                      <td>{org.localized_name}</td>
                      <td>{org.vanity_name}</td>
                      <td>
                        <div className="d-flex gap-2 align-items-center">
                          <RevokeButton {...{ endpoints, linkedin_user, org, isDisabled, setIsDisabled }} />
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
              {formState.isInitialLoad ? (
                <tr>
                  <td colSpan={orgsTableHeader.length}>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                      <p className="mb-0">Fetching all the associated organisations</p>
                      <div className="spinner-border spinner-border-sm text-primary" role="status" />
                    </div>
                  </td>
                </tr>
              ) : formState.data.organisations?.length ? (
                formState.data.organisations.map((org) => (
                  <tr key={org.id}>
                    <td>
                      <div className="border overflow-hidden rounded-circle d-flex align-items-center justify-content-center" style={{ height: 40, width: 40 }}>
                        {org.picture ? <img height={40} width={40} src={org.picture} /> : <i className="fe fe-briefcase" />}
                      </div>
                    </td>
                    <td>{org.localizedName}</td>
                    <td>{org.vanityName}</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <GrantButton {...{ endpoints, linkedin_user, org, isDisabled, setIsDisabled }} />
                      </div>
                    </td>
                  </tr>
                ))
              ) : !granted_organisations.length ? (
                <tr>
                  <td colSpan={orgsTableHeader.length}>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                      <p className="text-muted mb-0">No organisations are associated with this user.</p>
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={orgsTableHeader.length}>
                  <div className="d-flex align-items-center justify-content-center">
                    <p className="text-muted mb-0">Organisations can only be managed by LinkedIn admin accounts.</p>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
