import { useState } from 'react';

function WebhookTriggers({ triggers, trigger_types, forms }) {
  const [triggerState, setTriggerState] = useState(triggers);

  const updateTriggerType = function(index, newTriggerType){
    let trigger = triggerState[index]

    trigger.trigger_type = newTriggerType

    if(newTriggerType == 'job_application'){
      trigger.triggering_object_type = null;
      trigger.triggering_object_id = null;
    }

    setTriggerState(triggerState.with(index, trigger))
  }

  const updateTriggerObject = function(index, newTriggerObject){
    let trigger = triggerState[index]

    if(trigger.trigger_type == 'form_submission'){
      trigger.triggering_object_id = newTriggerObject;
      trigger.triggering_object_type = 'Form';
    }

    setTriggerState(triggerState.with(index, trigger))
  }

  const addNewTrigger = function(){
    const newTrigger = [{
      trigger_type: trigger_types[0]?.type
    }]

    setTriggerState(triggerState.concat(newTrigger))
  }

  const deleteTrigger = function(index){
    let trigger = triggerState[index]

    trigger._destroy = 1

    setTriggerState(triggerState.with(index, trigger))
  }

  return(
    <>
      { triggerState.map((trigger, index) =>
        <div className='trigger' key={index}>
          { trigger.id &&
            <input type="hidden" name={`webhook[triggers_attributes][${index}][id]`} value={trigger.id} /> }

          { trigger._destroy &&
            <input type="hidden" name={`webhook[triggers_attributes][${index}][_destroy]`} value="1" /> }

          { !trigger._destroy &&
            <div className="row">
              { index > 0 && <hr /> }

              <div className="col-10">
                <div className="form-group">
                  <div className="input select optional webhook_triggers_trigger_type">
                    <label className="select optional form-label">Trigger Type</label>
                    <select
                      className="select optional form-control"
                      name={`webhook[triggers_attributes][${index}][trigger_type]`}
                      value={trigger.trigger_type}
                      onChange={({ target: { value } } ) => updateTriggerType(index, value)}
                    >
                      { trigger_types.map(type =>
                        <option key={type.type} value={type.type}>{type.name}</option>
                      )}
                    </select>
                  </div>
                </div>
                { trigger.trigger_type == 'form_submission'  &&
                  <div className="form-group">
                    <div className="input select optional webhook_triggers_trigger_type">
                      <label className="select optional form-label">Triggered By</label>
                      <select
                        className="select optional form-control"
                        name={`webhook[triggers_attributes][${index}][triggering_object_id]`}
                        value={trigger.triggering_object_id}
                        onChange={({ target: { value } } ) => updateTriggerObject(index, value)}
                      >
                        <option>ANY FORM SUBMISSION</option>
                        { forms.map(form =>
                          <option key={form.id} value={form.id}>{form.name}</option>
                        )}
                      </select>
                      <input type="hidden" name={`webhook[triggers_attributes][${index}][triggering_object_type]`} value="Form" />
                    </div>
                  </div>
                }
              </div>
              <div className="col-2 text-end">
                <button className="btn btn-outline-danger" type="button" onClick={() => deleteTrigger(index)}>
                  <i className="fe fe-trash-2" />
                </button>
              </div>
            </div>
          }
        </div>
      )}
      <div className="text-center mt-4">
        <button className="btn btn-primary" type="button" onClick={() => addNewTrigger()}>Add Trigger</button>
      </div>
    </>
  );
}

export default WebhookTriggers;