import { useState } from 'react';

export default function TableView({ job_search_used_filters_count }) {
  // State to track which categories are collapsed
  const [open, setOpen] = useState({});

  // Toggle function for collapsing and expanding tables
  const toggleCollapse = (index) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [index]: !prevOpen[index],
    }));
  };

  return (
    <div className="d-flex flex-column gap-3 py-2">
      {job_search_used_filters_count.length ? (
        job_search_used_filters_count.map((category, index) => (
          <div key={index} className="d-flex flex-column">
            <h3>{category.name}</h3>
            <div className="card card-primary overflow-hidden">
              <table className="table table-sm mb-0 border-0">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" className="w-25">
                      Filter
                    </th>
                    <th scope="col">Count (Total)</th>
                    <th scope="col">Count (Past 30 Days)</th>
                    <th scope="col">Count (Yesterday)</th>
                    <th scope="col">Count (Today)</th>
                  </tr>
                </thead>
                <tbody>
                  {category.category_values.length ? (
                    category.category_values.slice(0, open[index] ? category.category_values.length : 10).map((cv, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{cv.value}</td>
                        <td>{cv.count_total}</td>
                        <td>{cv.count_30d}</td>
                        <td>{cv.count_yesterday}</td>
                        <td>{cv.count_today}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No category available.
                      </td>
                    </tr>
                  )}
                  {category.category_values.length > 10 ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        <button className="btn btn-sm btn-link" onClick={() => toggleCollapse(index)} style={{ cursor: 'pointer' }}>
                          {open[index] ? 'Show Less' : 'Show More'}
                        </button>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        ))
      ) : (
        <div>No categories are used as filters.</div>
      )}
    </div>
  );
}
