/*
This is a stub component that forwards the props onto the Editor component
It is needed to expose the package to the react-rails view helpers

Dependencies: `yarn add prismjs react-simple-code-editor`

Need to add `prismjs/themes/prism-dark.css` to the application css file

*/

import React from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';

function CodeEditor({ value, name }) {
  const [code, setCode] = React.useState(value);

  return(
    <div className="form-control">
      <Editor
        highlight={code => highlight(code, languages.js)}
        onValueChange={code => setCode(code)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          minHeight: '10rem'
        }}
        value={code}
        name={name}
      />
    </div>
  );
}

export default CodeEditor;