import { useState, useEffect } from 'react';
import { Tooltip } from 'bootstrap';

import TreeNode from './_site_tree/TreeNode';

const initializeExpandedKeys = (pages, parentKey = '', level = 0, expandedKeys = {}) => {
  if (level >= 2) return expandedKeys; // Only expand up to level 2

  pages.forEach((page, index) => {
    const key = parentKey ? `${parentKey}-${index}` : `${index}`;
    expandedKeys[key] = true;
    if (page.children.length > 0) {
      initializeExpandedKeys(page.children, key, level + 1, expandedKeys);
    }
  });
  return expandedKeys;
};

export default function SiteTree({ endpoints, state, dispatch, isSimple, currentPage }) {
  const [expandedKeys, setExpandedKeys] = useState(initializeExpandedKeys(state.pages));

  const toggleExpand = (key) => {
    setExpandedKeys((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

    // TODO, store in localStorage to persist across page refresh
  };

  useEffect(() => {
    const initializeTooltips = () => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
        if (!Tooltip.getInstance(tooltipTriggerEl)) {
          new Tooltip(tooltipTriggerEl);
        }
      });
    };

    initializeTooltips();

    const observer = new MutationObserver(() => {
      initializeTooltips();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      [...tooltipTriggerList].forEach((tooltipTriggerEl) => Tooltip.getInstance(tooltipTriggerEl)?.dispose());
      observer.disconnect();
    };
  }, []);

  if (!state.pages.length) {
    return <div className="d-flex align-items-center justify-content-center text-muted pt-5">No pages found.</div>;
  }

  return (
    <div className="dp-site-tree-list">
      {state.pages.map((page, index) => {
        return <TreeNode key={index} {...{ page, index, endpoints, dispatch, expandedKeys, toggleExpand, isSimple, currentPage }} />;
      })}
    </div>
  );
}
