import { useEffect } from 'react';
import FileDropperInput from './_image_wizard/FileDropperInput';
import ImageCropperInput from './_image_wizard/ImageCropperInput';

export default function ImageWizard({ state, dispatch, endpoints }) {
  const { page } = state;

  useEffect(() => {
    // Get the query parameter "src" from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const src = urlParams.get('img_src');
    const filename = urlParams.get('filename');

    // If the query parameter "src" is present, set the image data URL and filename
    if (src) {
      dispatch({
        type: 'SET_IMAGE',
        payload: {
          dataUrl: src,
          filename: filename,
          originalFilename: filename,
        },
      });
    }
  }, []);

  return (
    <div className="d-flex flex-column gap-3" style={{ maxWidth: 1100 }}>
      <div className="file-selector-container">
        {page === 0 && <FileDropperInput {...{ state, dispatch }} />}
        {page === 1 && <ImageCropperInput {...{ state, dispatch, endpoints }} />}
      </div>
    </div>
  );
}
