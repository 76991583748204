import TextInput from '../../../inputs/form-inputs/TextInput';

export default function SEOList({ state, dispatch }) {
  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex flex-column">
          <h3 className="mb-1">SEO</h3>
          <p className="text-muted mb-0">Configure your SEO here</p>
        </div>
      </div>
      <div className="d-flex flex-column gap-2">
        <div>
          <label htmlFor="seo-title" className="form-label">
            Title
          </label>
          <div className="input-group">
            <textarea
              className="form-control"
              value={state.data.seo.title}
              onChange={(e) => dispatch({ type: 'UPDATE_SEO', payload: { title: e.target.value } })}
              id="seo-title"
              aria-describedby="seo-title-addon"
            />
          </div>
          <div className="form-text" id="seo-title-addon3">
            Enter your title tag here.
          </div>
        </div>

        <div>
          <label htmlFor="seo-description" className="form-label">
            Description
          </label>
          <div className="input-group">
            <textarea
              className="form-control"
              value={state.data.seo.description}
              onChange={(e) => dispatch({ type: 'UPDATE_SEO', payload: { description: e.target.value } })}
              id="seo-description"
              aria-describedby="seo-description-addon"
            />
          </div>
          <div className="form-text" id="seo-description-addon">
            Enter your meta description here.
          </div>
        </div>

        <div>
          <label htmlFor="seo-keywords" className="form-label">
            Keywords
          </label>
          <div className="input-group">
            <textarea
              className="form-control"
              value={state.data.seo.keywords}
              onChange={(e) => dispatch({ type: 'UPDATE_SEO', payload: { keywords: e.target.value } })}
              id="seo-keywords"
              aria-describedby="seo-keywords-addon"
            />
          </div>
          <div className="form-text" id="seo-keywords-addon">
            Enter your meta keywords here.
          </div>
        </div>

        <div>
          <label htmlFor="seo-canonical" className="form-label">
            Canonical
          </label>
          <div className="input-group">
            <textarea
              className="form-control"
              value={state.data.seo.canonical}
              onChange={(e) => dispatch({ type: 'UPDATE_SEO', payload: { canonical: e.target.value } })}
              id="seo-canonical"
              aria-describedby="seo-canonical-addon"
            />
          </div>
          <div className="form-text" id="seo-canonical-addon">
            Enter your canonical URL here.
          </div>
        </div>

        <div>
          <label htmlFor="seo-robots" className="form-label">
            Robots
          </label>
          <div className="input-group">
            <textarea
              className="form-control"
              value={state.data.seo.robots}
              onChange={(e) => dispatch({ type: 'UPDATE_SEO', payload: { robots: e.target.value } })}
              id="seo-robots"
              aria-describedby="seo-robots-addon"
            />
          </div>
          <div className="form-text" id="seo-robots-addon">
            Enter your robots meta tag here.
          </div>
        </div>

        <div>
          <label htmlFor="seo-copyright" className="form-label">
            Copyright
          </label>
          <div className="input-group">
            <textarea
              className="form-control"
              value={state.data.seo.copyright}
              onChange={(e) => dispatch({ type: 'UPDATE_SEO', payload: { copyright: e.target.value } })}
              id="seo-copyright"
              aria-describedby="seo-copyright-addon"
            />
          </div>
          <div className="form-text" id="seo-copyright-addon">
            Enter your copyright information here.
          </div>
        </div>

        <div>
          <label htmlFor="seo-alternates" className="form-label">
            Alternates
          </label>
          <div className="input-group">
            <TextInput
              value={state.data.seo.alternate.hreflang}
              onChange={(e) => dispatch({ type: 'UPDATE_SEO', payload: { alternate: { ...state.data.seo.alternate, hreflang: e.target.value } } })}
              placeholder="HREFLANG"
              id="seo-alternates-hreflang"
              aria-describedby="seo-alternates-addon"
            />
            <TextInput
              value={state.data.seo.alternate.url}
              onChange={(e) => dispatch({ type: 'UPDATE_SEO', payload: { alternate: { ...state.data.seo.alternate, url: e.target.value } } })}
              placeholder="URL"
              id="seo-alternates-url"
              aria-describedby="seo-alternates-addon"
            />
          </div>
          <div className="form-text" id="seo-alternates-addon">
            Enter your alternate HREFLANG and URLs here.
          </div>
        </div>

        <div>
          <label htmlFor="seo-json-schema" className="form-label">
            JSON Schema
          </label>
          <div className="input-group">
            <textarea
              className="form-control"
              value={state.data.seo.json_schema}
              onChange={(e) => dispatch({ type: 'UPDATE_SEO', payload: { json_schema: e.target.value } })}
              id="seo-json-schema"
              aria-describedby="seo-json-schema-addon"
            />
          </div>
          <div className="form-text" id="seo-json-schema-addon">
            Enter your JSON-LD schema here.
          </div>
        </div>
      </div>
    </div>
  );
}
