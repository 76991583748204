export default function StatCard({ tooltipTitle, title, value, iconClass }) {
  return (
    <div className="col-12 col-lg-6 col-xl">
      <div className="card mb-0">
        <div className="card-body" data-bs-toggle="tooltip" title={tooltipTitle}>
          <div className="row align-items-center gx-0">
            <div className="col">
              <h6 className="text-uppercase text-muted mb-2">{title}</h6>
              <span className="h2 mb-0">{value}</span>
            </div>
            <div className="col-auto">
              <span className={`h2 ${iconClass} text-muted mb-0`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
