export default function InviteDashboard(props) {
  const { invite, linkedin, images } = props;

  const handleAddLinkedInAccount = () => {
    // Fetch the LinkedIn auth URL
    fetch(`${linkedin.authUrl}.json`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ linkedin_auth: { invite_code: invite.invite_code } }),
    })
      .then((response) => response.json())
      .then((data) => {
        window.open(data.auth_url);
      });
  };

  return (
    <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center overflow-hidden">
      <h1 className="drop-in">Welcome</h1>
      <h2 className="drop-in-2">You have been invited to join the club!</h2>
      <div className="card card-primary p-4">
        <div className="d-flex flex-column justify-content-center align-items-center gap-2">
          <h3>Add your social account below.</h3>
          <button className="btn btn-link" onClick={handleAddLinkedInAccount}>
            <img src={images.linkedin} style={{ maxWidth: 180 }} />
          </button>
        </div>
      </div>
    </div>
  );
}
