/**
 * Code Editor Imports
 * @description Dependencies: `yarn add prismjs react-simple-code-editor`
 *
 * Dependencies: `yarn add prismjs react-simple-code-editor`
 *
 * Need to add `prismjs/themes/prism-dark.css` to the application css file
 */
import CodeEditor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";

export default function CodeInput(props) {
  return (
    <div className="form-control">
      <CodeEditor
        highlight={(code) => highlight(code, languages.js)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          minHeight: "10rem",
        }}
        {...props}
      />
    </div>
  );
}
