import TextInput from '../inputs/form-inputs/TextInput';
import FormattedTextInput from '../inputs/form-inputs/FormattedTextInput';
import CodeInput from '../inputs/form-inputs/CodeInput';
import DateInput from '../inputs/form-inputs/DateInput';
import DropdownInput from '../inputs/form-inputs/DropdownInput';
import SwitchInput from '../inputs/form-inputs/SwitchInput';
import NumberInput from '../inputs/form-inputs/NumberInput';

export default function SchemaOptions(props) {
  const { schema, forms, categories, disabled, selectedIdentifier, requiredOnChange, descriptionOnChange, defaultValueOnChange } = props;

  const dataType = schema.data_type;
  const description = schema.description;
  const defaultValue = schema.default_value ?? '';
  const isRequired = schema.isRequired ?? selectedIdentifier?.id === schema.id ? true : false;
  const foreignCategoryId = schema.foreign_category_id ?? '';
  const isMulti = schema.isMulti ?? false;

  return (
    <div className="d-flex flex-column">
      {dataType !== 'checkbox' ? (
        <div className="d-flex flex-column gap-3 pt-4">
          <label htmlFor={`${schema.id}-field-required`} className="small text-muted">
            Required
          </label>
          <SwitchInput
            disabled={selectedIdentifier?.id === schema?.id ? true : disabled}
            onChange={requiredOnChange}
            value={isRequired}
            checked={isRequired}
            label={isRequired ? 'Yes' : 'No'}
          />
        </div>
      ) : null}

      {dataType !== 'file' && dataType !== 'object' && (
        <div className="d-flex flex-column gap-3 pt-3">
          <label className="small text-muted">Default Value</label>
          {dataType === 'text' && <TextInput placeholder="Enter a default value" value={defaultValue} onChange={defaultValueOnChange} disabled={disabled} />}
          {dataType === 'formatted_text' && <FormattedTextInput value={defaultValue} onEditorChange={defaultValueOnChange} disabled={disabled} />}
          {dataType === 'code' && <CodeInput value={defaultValue} onValueChange={defaultValueOnChange} disabled={disabled} />}
          {dataType === 'number' && <NumberInput value={defaultValue} onChange={defaultValueOnChange} disabled={disabled} />}
          {dataType === 'date' && <DateInput value={defaultValue} onChange={defaultValueOnChange} disabled={disabled} />}
          {dataType === 'datetime' && (
            <DateInput
              value={defaultValue}
              onChange={defaultValueOnChange}
              disabled={disabled}
              options={{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
              }}
            />
          )}
          {dataType === 'form' && (
            <DropdownInput value={defaultValue} onChange={defaultValueOnChange} formattedDropdownValues={forms} isClearable disabled={disabled} />
          )}
          {dataType === 'category' && (
            <DropdownInput
              key={`${schema.id}-${foreignCategoryId}-${isMulti}`} // Assign key to force re-render when either elements change
              value={defaultValue}
              onChange={defaultValueOnChange}
              formattedDropdownValues={categories
                .find((c) => c.id === foreignCategoryId)
                ?.values?.map((c) => ({
                  value: c.id,
                  label: c.name,
                }))}
              isClearable
              disabled={disabled}
              isMulti={isMulti}
            />
          )}
          {dataType === 'checkbox' && (
            <SwitchInput
              onChange={defaultValueOnChange}
              value={JSON.parse(defaultValue || 'false')}
              checked={JSON.parse(defaultValue || 'false')}
              disabled={disabled}
            />
          )}
          {dataType === 'dropdown' && (
            <DropdownInput
              key={`${schema.id}-${isMulti}`} // Assign key to force re-render when either elements change
              isMulti={schema.isMulti}
              value={defaultValue}
              onChange={defaultValueOnChange}
              dropdownValues={schema.dropdown_values}
              isClearable={true}
              disabled={disabled}
            />
          )}
        </div>
      )}

      <div className="d-flex flex-column gap-3 pt-3">
        <label htmlFor={`${schema.id}-field-description`} className="small text-muted">
          Description
        </label>
        <div>
          <textarea
            disabled={disabled}
            className="form-control"
            placeholder="Leave a description"
            id={`${schema.id}-field-description`}
            style={{ height: 100 }}
            defaultValue={description}
            onChange={descriptionOnChange}
          />
        </div>
      </div>
    </div>
  );
}
