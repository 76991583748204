import CountUp from 'react-countup';

export default function FiltersUsedCard({ title, value }) {
  return (
    <div className="py-2 w-25">
      <div className="d-flex flex-column">
        <h4>{title}</h4>
        <div className="d-flex gap-2">
          <p className="mb-0" style={{ fontSize: 36 }}>
            <CountUp end={value} duration={2} />
          </p>
        </div>
      </div>
    </div>
  );
}
