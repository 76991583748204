import TextInput from '../../../inputs/form-inputs/TextInput';
import SwitchInput from '../../../inputs/form-inputs/SwitchInput';
import FormattedTextInput from '../../../inputs/form-inputs/FormattedTextInput';
import TemplatesInput from './inputs/TemplatesInput';
import NumberInput from '../../../inputs/form-inputs/NumberInput';

import { typeBasedDefaultValue, snakeToRegularString } from '../../lib';
import ImageInput from './inputs/ImageInput';

export default function PropField({
  state,
  dispatch,
  name,
  label,
  type,
  defaultValue,
  options = [],
  isRequired,
  component,
  template_schema,
  images,
  categoriesLinked,
}) {
  const value = component.props[name];

  const handleAddTemplateItem = (templateName) => {
    dispatch({
      type: 'ADD_TEMPLATE_ITEM',
      payload: {
        contentId: component.id,
        templateName: templateName,
        templateItem: template_schema.reduce((acc, schemaField) => {
          acc[schemaField.name] = schemaField.defaultValue ?? typeBasedDefaultValue(schemaField.type);
          return acc;
        }, {}),
      },
    });
  };

  const handleRemoveTemplateItem = (templateName, templateItemId) => {
    dispatch({
      type: 'REMOVE_TEMPLATE_ITEM',
      payload: {
        contentId: component.id,
        templateName: templateName,
        templateItemId: templateItemId,
      },
    });
  };

  const handleStringChange = (e) => {
    dispatch({ type: 'UPDATE_INDIVIDUAL_CONTENT', payload: { ...component, props: { ...component.props, [name]: e.target.value } } });
  };

  const handleNumberChange = (e) => {
    dispatch({ type: 'UPDATE_INDIVIDUAL_CONTENT', payload: { ...component, props: { ...component.props, [name]: Number(e.target.value) } } });
  };

  const handleBooleanChange = (e) => {
    dispatch({ type: 'UPDATE_INDIVIDUAL_CONTENT', payload: { ...component, props: { ...component.props, [name]: e.target.checked } } });
  };

  const handleArrayChange = (e) => {
    dispatch({ type: 'UPDATE_INDIVIDUAL_CONTENT', payload: { ...component, props: { ...component.props, [name]: JSON.parse(e.target.value) } } });
  };

  const handleFormattedTextChange = (value) => {
    dispatch({ type: 'UPDATE_INDIVIDUAL_CONTENT', payload: { ...component, props: { ...component.props, [name]: value } } });
  };

  const handleFileChange = (value) => {
    dispatch({ type: 'UPDATE_INDIVIDUAL_CONTENT', payload: { ...component, props: { ...component.props, [name]: value } } });
  };

  const renderStringInput = () => {
    if (options && options.length) {
      return (
        <select className="form-select" value={value || ''} onChange={handleStringChange}>
          <option value="" disabled>
            Select an option
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    }

    return <TextInput value={value || ''} onChange={handleStringChange} />;
  };

  const renderNumberInput = () => <NumberInput value={value || 0} onChange={handleNumberChange} />;

  const renderBooleanInput = () => <SwitchInput checked={!!value} onChange={handleBooleanChange} />;

  const renderArrayInput = () => (
    <select className="form-select" value={JSON.stringify(value) || ''} onChange={handleArrayChange}>
      <option value="" disabled>
        Select an option
      </option>
      {options.length ? (
        options.map((option, index) => (
          <option key={index} value={JSON.stringify(option.value)}>
            {option.label}
          </option>
        ))
      ) : (
        <option value="">No options found.</option>
      )}
    </select>
  );

  const renderFormattedTextInput = () => <FormattedTextInput value={value || ''} onEditorChange={handleFormattedTextChange} />;

  const renderFileInput = () => <ImageInput id={name} value={value} onChange={handleFileChange} state={state} dispatch={dispatch} />;

  const renderTemplatesInput = () => (
    <TemplatesInput
      {...{
        component,
        name,
        state,
        dispatch,
        template_schema,
        value,
        handleAddTemplateItem,
        handleRemoveTemplateItem,
        images,
        categoriesLinked,
      }}
    />
  );

  const renderOptions = {
    string: renderStringInput,
    boolean: renderBooleanInput,
    array: renderArrayInput,
    number: renderNumberInput,
    formatted_text: renderFormattedTextInput,
    file: renderFileInput,
    template: renderTemplatesInput,
  };

  if (type !== 'string' && type !== 'boolean' && type !== 'array' && type !== 'number' && type !== 'formatted_text' && type !== 'file' && type !== 'template') {
    return <div>This prop is not configured properly.</div>;
  }

  return (
    <div className="d-flex flex-column gap-1">
      <label className="text-muted">{snakeToRegularString(label || name)}</label>
      {renderOptions[type]()}
    </div>
  );
}
