import { useReducer, useEffect } from "react";

const intialFormState = {
  status: "",
  isLoading: false,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const openLinkedInLogin = async (endpoints, formDispatch, helpers) => {
  const body = {
    linkedin_auth: { instant: true },
  };

  fetch(`${endpoints.linkedin.authUrl}.json`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) return;

      return response.json();
    })
    .then((data) => {
      formDispatch({
        type: "update",
        payload: { isLoading: true, status: "Awaiting login from new tab..." },
      });

      const loginWindow = window.open(data.auth_url, "_blank");

      const checkWindowClosed = setInterval(() => {
        if (loginWindow.closed) {
          const user_id = localStorage.getItem(helpers.localStorageLinkedinLoggedInKey);

          if (!user_id || user_id === "null") {
            formDispatch({
              type: "update",
              payload: { isLoading: false, status: "Login failed. Please try again." },
            });
          } else {
            formDispatch({
              type: "update",
              payload: { isLoading: false, status: "Successfully logged in! Redirecting to edit page..." },
            });

            window.location.href = `/_sf/admin/social/accounts/${user_id}/edit`;
          }

          clearInterval(checkWindowClosed);
        }
      }, 500); // Check every 500ms
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export default function NewAccount(props) {
  const { endpoints, images, helpers } = props;

  const [formState, formDispatch] = useReducer(formReducer, intialFormState);

  useEffect(() => {
    localStorage.setItem(helpers.localStorageLinkedinLoggedInKey, null);
  }, []);

  return (
    <div className="d-flex flex-column gap-4" style={{ maxWidth: 1100 }}>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-3 my-2 align-items-center">
              <h3 className="mb-0">Connect to your LinkedIn now!</h3>
              <span className="text-muted fst-italic mb-0">{formState.error}</span>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div>
            <div className="d-flex flex-column gap-2">
              <button className="btn btn-link" onClick={() => openLinkedInLogin(endpoints, formDispatch, helpers)}>
                <img src={images.linkedin} style={{ maxWidth: 180 }} />
              </button>
            </div>
          </div>
        </div>
        <div className="border-top">
          <div className="card-body">
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex gap-3 align-items-center">
                <div className="text-muted d-flex align-items-center gap-2">
                  {formState.isLoading ? <div className="spinner-border spinner-border-sm" role="status" /> : null}
                  <span className="fst-italic">{formState.status}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
