import { formatDateTime } from "../../../utils/DateFormatters";

export default function ShowPost(props) {
  const { post } = props;

  if (!post) return <div>No post found.</div>;

  return (
    <div style={{ maxWidth: 1100 }}>
      <div className="card card-primary">
        <div className="card-header">Details:</div>
        <div className="card-body">
          <div className="d-flex flex-column gap-3 ">
            <div>
              <h5 className="mb-0">Name:</h5>
              <p className="text-muted">{post.name}</p>
            </div>
            <div>
              <h5 className="mb-0">Post Content:</h5>
              <p className="text-muted">{post.post || "-"}</p>
            </div>
            <div>
              <h5 className="mb-0">Created At:</h5>
              <p className="text-muted">{formatDateTime(post.created_at)}</p>
            </div>
            <div>
              <h5 className="mb-0">Last Updated:</h5>
              <p className="text-muted">{formatDateTime(post.updated_at)}</p>
            </div>
            <div>
              <h5 className="mb-0">Scheduled for:</h5>
              <p className="text-muted">{formatDateTime(post.scheduled_at)}</p>
            </div>
            <div>
              <h5 className="mb-0">Status:</h5>
              <p className="text-muted">{post.status ? post.status.charAt(0).toUpperCase() + post.status.slice(1) : "Draft"}</p>
            </div>
          </div>
        </div>

        <table className="table table-sm card-table mb-0 border-0">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Picture</th>
              <th scope="col">Name</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {post.social_post_jobs.length ? (
              post.social_post_jobs.map((spj, index) => (
                <tr key={spj.id}>
                  <th scope="row">{index + 1}</th>
                  {spj.posted_by === "org" && (
                    <>
                      <td>
                        <div
                          className="d-flex border align-items-center justify-content-center"
                          style={{ width: 36, height: 36, borderRadius: "50%", overflow: "hidden" }}
                        >
                          {spj.linkedin_organisation?.picture ? (
                            <img src={spj.linkedin_organisation.picture} style={{ width: "100%", height: "100%" }} />
                          ) : (
                            <i className="fe fe-briefcase" />
                          )}
                        </div>
                      </td>
                      <td>
                        {spj.linkedin_organisation?.localized_name ?? `Organisation removed from the platform.`}{" "}
                        {`(By: ${spj.linkedin_user?.name || "User removed from the platform"})`}
                      </td>
                      <td>
                        <div className="d-flex gap-2">
                          {spj.status ? spj.status.charAt(0).toUpperCase() + spj.status.slice(1) : "Pending..."}
                          {spj.urn ? (
                            <a className="link-opacity-75-hover" href={`https://www.linkedin.com/feed/update/${spj.urn}`}>
                              <i className="fe fe-linkedin" />
                            </a>
                          ) : null}
                        </div>
                      </td>
                    </>
                  )}

                  {spj.posted_by === "user" && (
                    <>
                      <td>
                        <div
                          className="d-flex border align-items-center justify-content-center"
                          style={{ width: 36, height: 36, borderRadius: "50%", overflow: "hidden" }}
                        >
                          {spj.linkedin_user?.picture ? (
                            <img src={spj.linkedin_user.picture} style={{ width: "100%", height: "100%" }} />
                          ) : (
                            <i className="fe fe-user" />
                          )}
                        </div>
                      </td>
                      <td>{spj.linkedin_user?.name ?? "User removed from the platform."}</td>
                      <td>
                        <div className="d-flex gap-2">
                          {spj.status ? spj.status.charAt(0).toUpperCase() + spj.status.slice(1) : "Pending..."}
                          {spj.urn ? (
                            <a className="link-opacity-75-hover" href={`https://www.linkedin.com/feed/update/${spj.urn}`}>
                              <i className="fe fe-linkedin" />
                            </a>
                          ) : null}
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  <p className="text-muted mb-0">No accounts or organisations have been selected for this post.</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}