import { PostsTable } from "../_components";

export default function PostsDashboard(props) {
  const { posts, endpoints } = props;

  return (
    <div className="d-flex flex-column gap-4" style={{ maxWidth: 1100 }}>
      {posts.length ? (
        <PostsTable posts={posts} endpoints={endpoints} />
      ) : (
        <div className="card card-primary">
          <div className="card-body">
            <h3 className="card-title">Create your first post!</h3>
            <p className="card-text">Click the button below to begin.</p>
            <a className="btn btn-primary" href={endpoints.newPostsPath}>
              Post (+)
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
