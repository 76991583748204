import { useMemo } from 'react';

import TextInput from '../../../../inputs/form-inputs/TextInput';
import ImageSelectorModal from './ImageSelectorModal';
import ImageUploadModal from './ImageUploadModal';
import ImageCropModal from './ImageCropModal';

const EmptyImageInput = ({ id, onChange, state, dispatch }) => {
  return (
    <div className="image-input">
      <div className="image-preview">
        <div className="background">
          <i className="fe fe-image" />
          <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#imageSelector-${id}-Modal`}>
            Select Image
          </button>
        </div>
      </div>
      <ImageSelectorModal {...{ id, onChange, state, dispatch }} />
      <ImageUploadModal {...{ id, onChange, state, dispatch }} />
    </div>
  );
};

const ExistingImageInput = ({ id, value, onChange, state, dispatch }) => {
  const { images } = state;

  const image = useMemo(() => images?.find((image) => image.id === value.id), [images, value.id]);

  const handleAltOnChange = (e) => {
    onChange({ ...value, alt: e.target.value });
  };

  const handleTitleOnChange = (e) => {
    onChange({ ...value, title: e.target.value });
  };

  if (!image) {
    return null;
  }

  return (
    <div className="image-input">
      <div className="image-preview">
        <div className="image-buttons">
          <button className="image-button" data-bs-toggle="modal" data-bs-target={`#imageCrop-${id}-Modal`}>
            <i className="fe fe-crop" />
          </button>
          <button className="image-button" data-bs-toggle="modal" data-bs-target={`#imageSelector-${id}-Modal`}>
            <i className="fe fe-upload" />
          </button>
        </div>
        <img className="image" src={image.url} />
      </div>
      <div className="d-flex align-items-center justify-content-center gap-3 w-100">
        <div className="d-flex flex-column gap-2 w-100">
          <label className="text-muted">Alt</label>
          <TextInput {...{ value: value.alt, onChange: handleAltOnChange }} />
        </div>
        <div className="d-flex flex-column gap-2 w-100">
          <label className="text-muted">Title</label>
          <TextInput {...{ value: value.title, onChange: handleTitleOnChange }} />
        </div>
      </div>
      <ImageSelectorModal {...{ id, onChange, state, dispatch }} />
      <ImageCropModal {...{ id, image, onChange, state, dispatch }} />
      <ImageUploadModal {...{ id, onChange, state, dispatch }} />
    </div>
  );
};

export default function ImageInput({ id, value, onChange, state, dispatch }) {
  if (!value || !value.id) {
    return <EmptyImageInput {...{ id, value, onChange, state, dispatch }} />;
  }

  return <ExistingImageInput {...{ id, value, onChange, state, dispatch }} />;
}
