import MetadataList from './_page_metadata_seo/MetadataList';
import SEOList from './_page_metadata_seo/SEOList';

export default function PageMetadataSEO({ state, dispatch, setActivePane }) {
  return (
    <div className="d-flex flex-column gap-3 pe-2">
      <SEOList {...{ state, dispatch }} />
      <MetadataList {...{ state, dispatch, setActivePane }} />
    </div>
  );
}
