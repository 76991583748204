import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

// Register the plugins
registerPlugin(FilePondPluginFileEncode, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

const CreateFilePond = (props) => {
  const { id, attachmentName, attachmentUploadBuffer, setAttachmentUploadBuffer } = props;

  return (
    <FilePond
      id={id}
      onaddfile={(error, file) => {
        if (error) {
          console.error('Filepond error:', error);
          return;
        }

        const base64Data = file.getFileEncodeBase64String();

        if (file.filename && base64Data) {
          setAttachmentUploadBuffer({
            ...attachmentUploadBuffer,
            [attachmentName]: {
              filename: file.filename,
              base64_file_data: base64Data,
            },
          });
        } else {
          console.error(`Filepond error: File data or filename is missing ${file.filename} || file data: ${base64Data}`);
        }
      }}
      onremovefile={() => {
        setAttachmentUploadBuffer((currentBuffer) => {
          const newBuffer = { ...currentBuffer };
          delete newBuffer[attachmentName];
          return newBuffer;
        });
      }}
      maxFiles={1}
      credits={false}
      labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
      imagePreviewMaxFileSize="2MB"
      maxFileSize="10MB"
      labelMaxFileSize="Total file size should be lesser than 10MB"
    />
  );
};

const EditFilePond = (props) => {
  const { id, attachmentName, attachments, deleteAttachment, setAttachmentDeleteBuffer } = props;

  return (
    <div className="d-flex flex-column">
      <FilePond
        id={id}
        name="file" // Changing this requires a change in the API controller as well.
        files={attachments}
        maxFiles={1}
        allowRevert={false}
        onremovefile={() => {
          const attrToRemove = attachments?.filter((a) => a.attachment.key.includes(attachmentName));
          if (attrToRemove.length > 0) {
            setAttachmentDeleteBuffer((curr) => {
              return [...curr, ...attrToRemove];
            });
          }

          // Delete from local state after deleting it from server.
          deleteAttachment(attachmentName);
        }}
        server={{
          load: (source, load, error, _, abort) => {
            const myRequest = new Request(source);
            fetch(myRequest, {
              headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' },
            }).then((response) => {
              if (!response.ok) {
                error();
                return;
              }

              response.blob().then((myBlob) => {
                load(myBlob);
              });
            });

            return {
              abort: () => {
                // User tapped cancel, abort our ongoing actions here

                // Let FilePond know the request has been cancelled
                abort();
              },
            };
          },
        }}
        credits={false}
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        imagePreviewMaxFileSize="2MB"
        imagePreviewMarkupShow={false}
        maxFileSize="10MB"
        labelMaxFileSize="Total file size should be lesser than 10MB"
      />
      {attachments[0]?.source && (
        <a href={attachments[0].source} target="_blank" rel="noopener noreferrer" className="mb-2">
          {attachments[0].source}
        </a>
      )}
    </div>
  );
};

export default function FileInput(props) {
  const { mode } = props;

  return <div>{mode === 'create' ? <CreateFilePond {...props} /> : <EditFilePond {...props} />}</div>;
}
