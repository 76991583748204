import { useEffect, useReducer } from 'react';
import { LayoutGroup } from 'framer-motion';

import TextInput from '../../../inputs/form-inputs/TextInput';
import DynamicPagesTable from './DynamicPagesTable';

const initialState = {
  isLoading: true,
  isError: false,
  status: '',
  selectedPage: null,
  pages: [],
  currentPageChunk: 1,
  search: '',
  sort: {
    identifier: 0,
    order: 0,
  },
};

const SORT_IDENTIFIERS = [
  { id: 'title', label: 'Page Title', data_type: 'string', icon: 'fe fe-type' },
  { id: 'url_slug', label: 'URL Slug', data_type: 'string', icon: 'fe fe-link-2' },
  { id: 'updated_at', label: 'Last Modified', data_type: 'datetime', icon: 'fe fe-clock' },
];

const SORT_ORDER = [{ id: 'None' }, { id: 'ASC' }, { id: 'DESC' }];

const ITEMS_PER_PAGE = 5;

function reducer(state, action) {
  switch (action.type) {
    case 'SET_SELECTED_PAGE':
      return { ...state, selectedPage: action.payload };
    case 'SET_PAGES':
      return { ...state, pages: action.payload };
    case 'SET_CURRENT_PAGE_CHUNK':
      return { ...state, currentPageChunk: action.payload };
    case 'SET_SEARCH':
      return { ...state, search: action.payload };
    case 'SET_SORT_IDENTIFIER':
      return { ...state, sort: { ...state.sort, identifier: action.payload } };
    case 'SET_SORT_ORDER':
      return { ...state, sort: { ...state.sort, order: action.payload } };
    case 'TOGGLE_IS_LOADING':
      return { ...state, isLoading: !state.isLoading };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'TOGGLE_IS_ERROR':
      return { ...state, isError: !state.isError };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    default:
      return state;
  }
}

const filterPages = (pages, keyword) => {
  return pages.filter((page) => page.title.toLowerCase().includes(keyword.toLowerCase()) || page.url_slug.toLowerCase().includes(keyword.toLowerCase()));
};

const sortPages = (pages, sortIdentifier, sortOrder) => {
  if (sortOrder === 0) return pages;

  const dataType = sortIdentifier.data_type;
  const id = sortIdentifier.id;

  return pages.sort((a, b) => {
    if (dataType === 'string') {
      return sortOrder === 1 ? a[id].localeCompare(b[id]) : b[id].localeCompare(a[id]);
    } else if (dataType === 'datetime') {
      return sortOrder === 1 ? new Date(a[id]) - new Date(b[id]) : new Date(b[id]) - new Date(a[id]);
    }
  });
};

const splitPagesIntoChunks = (array) => {
  const chunks = [];

  // Using a for loop to create chunks based on the desired number of items per page
  for (let i = 0; i < array.length; i += ITEMS_PER_PAGE) {
    chunks.push(array.slice(i, i + ITEMS_PER_PAGE));
  }

  return chunks;
};

// Updated groupPages function
const groupPages = (array) => {
  return array.map((chunk) => {
    return chunk.reduce((acc, page) => {
      if (!acc[page.group_key]) {
        acc[page.group_key] = [];
      }
      acc[page.group_key].push(page);
      return acc;
    }, {});
  });
};

function LoadingSpinner() {
  return (
    <div className="d-flex gap-2 justify-content-center align-items-center p-5" style={{ height: 250 }}>
      <div className="spinner-grow spinner-grow-sm" role="status" />
      <div className="spinner-grow spinner-grow-sm" role="status" />
      <div className="spinner-grow spinner-grow-sm" role="status" />
      <span className="visually-hidden">Loading...</span>
    </div>
  );
}

export default function DynamicPagesDashboard({ dynamicPages, endpoints }) {
  const [state, dispatch] = useReducer(reducer, { ...initialState, pages: dynamicPages });

  const sortIconStyle = {
    transform: state.sort.order === 2 ? 'rotate(180deg)' : '',
    transition: 'transform 200ms ease',
  };

  const handleSearch = (e) => {
    dispatch({
      type: 'SET_SEARCH',
      payload: e.target.value,
    });
  };

  const handleSortIdentifierOnClick = () => {
    const nextIdentifier = (state.sort.identifier + 1) % SORT_IDENTIFIERS.length;
    dispatch({
      type: 'SET_SORT_IDENTIFIER',
      payload: nextIdentifier,
    });
  };

  const handleSortOrderOnClick = () => {
    const nextIdentifier = (state.sort.order + 1) % SORT_ORDER.length;
    dispatch({
      type: 'SET_SORT_ORDER',
      payload: nextIdentifier,
    });
  };

  useEffect(() => {
    const filteredPages = filterPages(dynamicPages, state.search);
    const sortedPages = sortPages(filteredPages, SORT_IDENTIFIERS[state.sort.identifier], state.sort.order);
    const chunkedPages = splitPagesIntoChunks(sortedPages);
    const groupedPages = groupPages(chunkedPages);

    dispatch({
      type: 'SET_PAGES',
      payload: groupedPages,
    });

    // Set isLoading to false after the data has been processed
    dispatch({
      type: 'SET_IS_LOADING',
      payload: false,
    });
  }, [state.search, state.sort.order, state.sort.identifier]);

  return (
    <LayoutGroup>
      <div className="pb-4 w-100 d-flex justify-content-between align-items-center">
        <div style={{ width: '40%' }}>
          <TextInput label="Search" placeholder="Search by page title or URL..." onChange={handleSearch} />
        </div>
        <div className="d-flex gap-3 align-items-center">
          <span>Sort by</span>
          <button type="button" className="btn align-items-center justify-content-center dp-top-button" onClick={handleSortIdentifierOnClick}>
            <i className={SORT_IDENTIFIERS[state.sort.identifier].icon} />
            <span>{SORT_IDENTIFIERS[state.sort.identifier].label}</span>
          </button>
          <button type="button" className="btn dp-top-button" onClick={handleSortOrderOnClick}>
            {!state.sort.order ? <i className="fe fe-minus" /> : <i className="fe fe-corner-right-down" style={sortIconStyle} />}
            <span>{SORT_ORDER[state.sort.order].id}</span>
          </button>
        </div>
      </div>
      {state.isLoading ? <LoadingSpinner /> : <DynamicPagesTable {...{ state, dispatch, endpoints }} />}
    </LayoutGroup>
  );
}
