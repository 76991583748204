import { useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 16,
  borderColor: '#1E3A5C',
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  color: '#FFF',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  paddingTop: '40px',
};

const focusedStyle = {
  borderColor: '#2C7BE5',
};

const acceptStyle = {
  borderColor: '#00D97E',
};

const rejectStyle = {
  borderColor: '#E63757',
};

const validator = (file) => {
  const maxSize = 10485760; // In bytes
  if (file.size > maxSize) {
    return {
      code: 'file-too-large',
      message: `File is too large. Max file size is ${maxSize / 1024}MB.`,
    };
  }
};

export default function FileDropperInput({ state, dispatch }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const reader = new FileReader();
        const file = acceptedFiles[0];
        reader.onload = () => {
          const dataUrl = reader.result;
          dispatch({ type: 'SET_IMAGE', payload: { dataUrl, filename: file.name } });
        };
        reader.readAsDataURL(file); // Ensure the file is read as a data URL
      } else {
        console.log('No files accepted');
      }
    },
    [dispatch]
  );

  const { fileRejections, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, isFocused } = useDropzone({
    onDrop,
    validator,
    accept: 'image/*',
    maxFiles: 1,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {isDragActive ? <p>Drop the file here ...</p> : <p>Drag and drop an image here, or click to select the image.</p>}
      <em>(Only *.jpeg, *.png, *.webp images will be accepted)</em>
      <ul>{fileRejectionItems}</ul>
    </div>
  );
}
