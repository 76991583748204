const STATUS_MAP = {
  is_from_sitemap: { text: 'Published', className: 'text-bg-success' },
  is_draft: { text: 'Draft', className: 'text-bg-secondary' },
  default: { text: 'Pending Build', className: 'text-bg-info' },
};

export default function StatusBadge({ page }) {
  const status = page.is_from_sitemap ? STATUS_MAP.is_from_sitemap : page.is_draft ? STATUS_MAP.is_draft : STATUS_MAP.default;

  return <span className={`badge ${status.className}`}>{status.text}</span>;
}
