export default function LinkedInLargeArticlePreview(props) {
  const { formState } = props;

  const image = formState.data.article?.image;
  const show_image = formState.data.article?.show_image;
  const title = formState.data.article?.title;
  const description = formState.data.article?.description;
  const source = formState.data.article?.source;

  return (
    <div className="overflow-hidden py-2 rounded d-flex align-items-center justify-content-center">
      <div className="linkedin-article-preview-container">
        <div className="linkedin-article-content">
          {show_image && image && <img src={image} className="article-image" />}
          <div className="article-footer">
            {title && <p className="article-title">{title}</p>}
            {!show_image && <p className="article-source">{source}</p>}
            {!show_image && description && <p className="article-description">{description}</p>}
            {show_image && <p className="article-source">{source}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
