import { useEffect } from 'react';
import { componentIdentifierKeyword } from '../lib';
import { authorisedGetImages } from '../../images/lib';

import PropField from './_component_settings/PropField';
import PropFieldCategory from './_component_settings/PropFieldCategory';

export default function ComponentSettingsPane({ state, dispatch, componentDefinitions, categoriesLinked, setActivePane, endpoints }) {
  const component = state.data.content[state.componentBeingEditedIndex] || null;

  const definition = componentDefinitions.find((c) => {
    const componentIdentifier = c[componentIdentifierKeyword];
    const currentComponentIdentifier = component?.[componentIdentifierKeyword];

    return componentIdentifier !== undefined && currentComponentIdentifier !== undefined && componentIdentifier === currentComponentIdentifier;
  });

  const handleRemoveComponent = () => {
    dispatch({ type: 'REMOVE_CONTENT', payload: { id: component.id } });
    setActivePane(1);
  };

  const handleDuplicateComponent = () => {
    dispatch({ type: 'ADD_CONTENT', payload: { ...component, id: crypto.randomUUID() } });
    setActivePane(1);
  };

  // Remove this whole migration logic once all client sites no longer have issues.
  const handleMigrateComponents = () => {
    const updatedContent = state.data.content.map(({ name, ...rest }) => ({ component: name, ...rest }));
    dispatch({ type: 'UPDATE_DATA', payload: { content: updatedContent } });
    setActivePane(1);
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const images = await authorisedGetImages();
        const sortedImages = images.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch({ type: 'SET_IMAGES', payload: { images: sortedImages } });
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };
    fetchImages();

    return () => {
      dispatch({ type: 'SET_COMPONENT_CLEAR_FOCUS', payload: { id: component?.id } });
    };
  }, []);

  useEffect(() => {
    if (component) return;

    setActivePane(1);
  }, [component]);

  if (!definition) {
    return (
      <div className="d-flex flex-column gap-3">
        <h3>Error</h3>
        <p className="text-muted">This current existing component is not configured properly.</p>
        <p className="text-muted">Please ensure the code responsible for this component is updated to the latest version.</p>
        <p className="text-muted">Remove it and re-add back in again once the latest version is available.</p>
        <button type="button" className="btn btn-info" onClick={handleMigrateComponents}>
          Migrate All Components
        </button>
        <button type="button" className="btn btn-danger" onClick={handleRemoveComponent}>
          Remove
        </button>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column gap-3 max-92-vh pb-4 overflow-auto pe-2">
      <div className="d-flex flex-column gap-1">
        <div className="d-flex flex-column">
          <h3 className="mb-1 w-100 d-inline-block text-truncate">Editing {definition[componentIdentifierKeyword] || 'Component'}</h3>
          <p className="text-muted mb-0">This component is #{state.componentBeingEditedIndex + 1} on the components list.</p>
        </div>
        <div>
          <div className="btn-group">
            <button type="button" className="btn btn-danger" onClick={handleRemoveComponent}>
              Remove
            </button>
            <button type="button" className="btn btn-danger dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
              <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu">
              <li>
                <button type="button" className="dropdown-item" onClick={handleDuplicateComponent}>
                  Duplicate
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {definition?.propSchema?.map((prop, index) => {
        if (prop.category_id) {
          const category = categoriesLinked.find((c) => c.id === prop.category_id);
          if (category) {
            return <PropFieldCategory key={index} {...{ component, dispatch, category, ...prop }} />;
          }
          return <span>Error: Category not found.</span>;
        }

        return <PropField key={index} {...{ component, state, dispatch, endpoints, categoriesLinked, ...prop }} />;
      })}
    </div>
  );
}
