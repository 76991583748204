import React, { useState } from "react";

export default function Collapse(props) {
  const { id, header, body } = props;
  const [isOpen, setIsOpen] = useState(false);

  /**
   * A style object for the sort button.
   */
  const buttonStyle = {
    transform: isOpen ? "rotate(180deg)" : "",
    transition: "transform 200ms ease",
  };

  return (
    <div>
      <div className="d-flex flex-row gap-4">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <button
            className="btn btn-outline-primary sort-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${id}`}
            aria-expanded={isOpen}
            aria-controls={id}
            onClick={() => setIsOpen(!isOpen)}
          >
            <i className="fe fe-chevron-down" style={buttonStyle} />
          </button>
        </div>

        <div className="w-100">{header}</div>
      </div>
      <div className="collapse" id={id}>
        {body}
      </div>
    </div>
  );
}
