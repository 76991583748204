import React from 'react';

class BuildButton extends React.Component {
  constructor() {
    super();

    this.state = {
      pending: false
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    this.setState({ pending: true});
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content },
        body: JSON.stringify({}),
        credentials: 'same-origin'
    };
    fetch(`/${process.env.PATH_PREFIX}/api/v1/site/build.json`, requestOptions)
        .then(response => response.json())
  }

  render(){
    let content;
    if(this.state.pending){
      content = <><span className="spinner-border spinner-border-sm me-3"></span>Publishing</>
    } else {
      content = <>Publish Changes</>
    }
    return(
      <div id='build-button' className='mb-3'>
        <button className='btn btn-primary w-100' onClick={this.handleClick} disabled={this.state.pending}>
          { content }
        </button>
      </div>
    );
  }
}

export default BuildButton;
