import { useState } from 'react';

import FiltersUsedCard from './FiltersUsedCard';

const timeRanges = [
  { key: 'count_total', label: 'Count (Total)' },
  { key: 'count_30d', label: 'Count (Past 30 Days)' },
  { key: 'count_yesterday', label: 'Count (Yesterday)' },
  { key: 'count_today', label: 'Count (Today)' },
];

export default function CardView({ job_search_used_filters_count }) {
  const [timeRange, setTimeRange] = useState(timeRanges[0].key);

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex gap-2">
        {timeRanges.map((tr) => (
          <div key={tr.key}>
            <input
              type="radio"
              className="btn-check btn-sm"
              name="options-time-range"
              id={tr.key}
              checked={timeRange === tr.key}
              onChange={() => setTimeRange(tr.key)}
            />
            <label className="btn" htmlFor={tr.key}>
              {tr.label}
            </label>
          </div>
        ))}
      </div>
      {job_search_used_filters_count.length ? (
        job_search_used_filters_count.map((category, index) => (
          <div key={index} className="card card-primary p-4">
            <h3 className="text-muted">{category.name}</h3>
            <div className="d-flex flex-wrap">
              {category.category_values.length ? (
                category.category_values.map((cv, index) => <FiltersUsedCard key={index} title={cv.value} value={cv[timeRange]} />)
              ) : (
                <div className="text-muted">No category values are used as filters.</div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div>No categories are used as filters.</div>
      )}
    </div>
  );
}
