import { useState, useEffect } from 'react';

export default function MetadataSettingsModal({ m, dispatch }) {
  const [key, setKey] = useState(m.key);
  const [value, setValue] = useState(m.value);

  const handleSave = () => {
    dispatch({ type: 'UPDATE_METADATA', payload: { id: m.id, key, value } });
  };

  const handleRemove = () => {
    dispatch({ type: 'REMOVE_METADATA', payload: { id: m.id } });
  };

  useEffect(() => {
    setKey(m.key);
    setValue(m.value);
  }, [m]);

  return (
    <div className="modal fade" id={`metadata-${m.id}-settings-modal`} tabIndex={-1} aria-labelledby={`label-${m.id}-settings`} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <p className="modal-title" id={`label-${m.id}-settings`}>
                Configure this metadata entry
              </p>
              <button type="button" className="btn" data-bs-dismiss="modal">
                <i className="fe fe-x" />
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="input-group mb-3">
              <span className="input-group-text">Key</span>
              <input type="text" className="form-control" placeholder="Key" aria-label="Key" value={key} onChange={(e) => setKey(e.target.value)} />
            </div>
            <div className="input-group">
              <span className="input-group-text">Value</span>
              <textarea className="form-control" aria-label="With textarea" value={value} onChange={(e) => setValue(e.target.value)} />
            </div>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end w-100">
              <div className="btn-group">
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleSave}>
                  Save
                </button>
                <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <button type="button" className="dropdown-item" data-bs-dismiss="modal" onClick={handleRemove}>
                      Delete
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
