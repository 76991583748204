import { useEffect, useCallback } from 'react';
import { Tooltip } from 'bootstrap';
import { deepEqual } from '../../utils/ObjectComparators';

import ConfirmationDialog from '../../confirmation-dialog/ConfirmationDialog';
import { dataStateKeyword } from '../lib';

export default function PageOptionsBar({ state, dispatch, initialState, handleSave, indexUrl, handleDelete, handleForceRefresh }) {
  const hasUnsavedChanges = !deepEqual(state.data, initialState.data);

  // Uncomment this to see the differences between the state and the initial state
  // Detailed logging to identify differences
  // if (!deepEqual(state.data, initialState.data)) {
  //   console.log('Differences found:');
  //   for (const key of Object.keys(state.data)) {
  //     if (!deepEqual(state.data[key], initialState.data[key])) {
  //       console.log(`Difference in property "${key}":`);
  //       console.log('State.data:', state.data[key]);
  //       console.log('initialState.data:', initialState.data[key]);
  //     }
  //   }
  // }

  const handleBeforeUnload = useCallback((e) => {
    e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
  }, []);

  const handleOnClickDelete = () => {
    // Remove the beforeunload event listener
    window.removeEventListener('beforeunload', handleBeforeUnload);
    // Call the delete function
    handleDelete();
  };

  const handleOnClickSave = () => {
    // Remove the beforeunload event listener
    window.removeEventListener('beforeunload', handleBeforeUnload);
    // Call the save function
    handleSave();
  };

  const handleDiscardChanges = () => {
    // Remove the beforeunload event listener
    window.removeEventListener('beforeunload', handleBeforeUnload);
    // Navigate to the index page
    window.location.href = indexUrl;
  };

  const dataStateIndex = state.dataStateIndex;
  const dataStates = JSON.parse(sessionStorage.getItem(dataStateKeyword));

  const handleUndo = () => {
    dispatch({ type: 'HANDLE_UNDO_REDO', payload: { isUndoRedo: true, dataStateIndex: dataStateIndex - 1, data: JSON.parse(dataStates[dataStateIndex - 1]) } });
  };

  const handleRedo = () => {
    dispatch({ type: 'HANDLE_UNDO_REDO', payload: { isUndoRedo: true, dataStateIndex: dataStateIndex + 1, data: JSON.parse(dataStates[dataStateIndex + 1]) } });
  };

  useEffect(() => {
    if (!hasUnsavedChanges) return;

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');

    tooltips.forEach((tooltip) => {
      new Tooltip(tooltip);
    });

    return () => {
      tooltips.forEach((tooltip) => {
        const tooltipInstance = Tooltip.getInstance(tooltip);
        if (tooltipInstance) {
          tooltipInstance.dispose();
        }
      });
    };
  }, [state]);

  return (
    <div className="d-flex gap-3">
      <div className="d-flex gap-1">
        <button className="btn btn-sm btn-outline" onClick={handleUndo} disabled={dataStateIndex === 0}>
          Undo
        </button>
        <button className="btn btn-sm btn-outline" onClick={handleRedo} disabled={dataStateIndex === dataStates?.length - 1}>
          Redo
        </button>
      </div>
      <div className="dropdown">
        <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fe fe-more-horizontal" />
        </button>
        <ul className="dropdown-menu">
          <li>
            {hasUnsavedChanges ? (
              <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#discardConfirmationModal-builder">
                Discard Changes
              </button>
            ) : (
              <a className="dropdown-item" href={indexUrl}>
                Discard Changes
              </a>
            )}
          </li>
          {handleDelete && (
            <>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteConfirmationModal-builder">
                  Delete Page
                </button>
              </li>
            </>
          )}
          <li>
            <hr className="dropdown-divider" />
          </li>
          <button className="dropdown-item" onClick={handleForceRefresh}>
            Refresh
          </button>
        </ul>
      </div>
      <div className="d-flex gap-3 align-items-center justify-content-center">
        {state.isError && <i className="fe fe-info text-danger" data-bs-toggle="tooltip" data-bs-title={state.error} />}
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#saveConfirmationModal-builder"
          disabled={state.isError || !hasUnsavedChanges}
        >
          Save Changes
        </button>
      </div>
      {handleDelete && (
        <ConfirmationDialog
          id="deleteConfirmationModal-builder"
          title="Confirm Delete"
          message="Are you sure you want to delete this page?"
          confirmButtonText="Delete"
          confirmButtonClass="btn-danger"
          onConfirm={handleOnClickDelete}
        />
      )}
      <ConfirmationDialog
        id="discardConfirmationModal-builder"
        title="Confirm leave"
        message="You have unsaved changes, are you sure you want to leave?"
        confirmButtonText="Leave"
        confirmButtonClass="btn-secondary"
        onConfirm={handleDiscardChanges}
      />
      <ConfirmationDialog
        id="saveConfirmationModal-builder"
        title="Confirm Save"
        message="Are you sure you want to save this new page?"
        confirmButtonText="Save"
        confirmButtonClass="btn-primary"
        onConfirm={handleOnClickSave}
      />
    </div>
  );
}
