import { useEffect } from "react"
import * as WebAuthnJSON from "@github/webauthn-json"

export default function ChallengeCredential(props) {
  const csrfToken = document.querySelector("[name='csrf-token']").content

  useEffect(() => {
    callValidate()
  }, []);

  const callValidate = function(){
    WebAuthnJSON.get({ "publicKey": props.challenge }).then(async function(credential) {
      fetch(props.formUrl, {
        method: 'POST',
        headers: {
        Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        credentials: 'same-origin',
        body: JSON.stringify({ credential }),
      }).then((response) => {
        if(response.ok){
          return response.json()
        }
      }).then((data) => {
        window.location.replace(data.redirectTo)
      })
    }).catch(function(error) {
      console.log("something is wrong", error);
    });
  }

  return(
    <></>
  )
}


