import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

import SortableTemplate from './_template_input/SortableTemplate';

export default function TemplatesInput({
  component,
  name,
  state,
  dispatch,
  template_schema,
  value,
  handleAddTemplateItem,
  handleRemoveTemplateItem,
  images,
  categoriesLinked,
}) {
  const { id: contentId } = component;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = value.findIndex((m) => m.id === active.id);
      const newIndex = value.findIndex((m) => m.id === over.id);

      // Reorder the metadata entries
      const newMetadata = arrayMove(value, oldIndex, newIndex);

      // Updates the category schema with the new order of the fields
      dispatch({ type: 'UPDATE_TEMPLATES', payload: { contentId, templates: newMetadata, templateName: name } });
    }
  };

  return (
    <div className="dp-templates">
      {value && value.length ? (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={value} strategy={verticalListSortingStrategy}>
            {value.map((templateItem, index) => {
              return (
                <SortableTemplate
                  key={templateItem.id}
                  {...{
                    index,
                    contentId,
                    state,
                    dispatch,
                    templateName: name,
                    templateItem,
                    template_schema,
                    images,
                    handleRemoveTemplateItem,
                    categoriesLinked,
                  }}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      ) : (
        <span className="text-muted text-center">Click "Add Item" below to add your first template item.</span>
      )}
      <button className="btn btn-primary" onClick={() => handleAddTemplateItem(name)}>
        Add Item
      </button>
    </div>
  );
}
