import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { formatDateTime } from '../../../utils/DateFormatters';

import ResponsivePagination from 'react-responsive-pagination';

const TABLE_HEADERS = [
  { label: 'Page Title', width: '20%' },
  { label: 'Slug', width: '20%' },
  { label: 'Last Modified', width: '20%' },
  { label: 'Status', width: '20%' },
  { label: 'Options', width: '20%' },
];

function StatusBadge({ isDraft }) {
  if (isDraft) {
    return <span className="dp-table-badge-draft">Draft</span>;
  }

  // TODO CHECK SITEMAP OR CHECK LAST REPLOYED VS UPDATED AT
  // const last_deployed = ????;
  // if (updated_at < last_deployed) {
  //   return <span className="dp-table-badge-published">Published</span>;
  // }

  return null;
}

function RowOptions({ page, endpoints, dispatch }) {
  const { currentDomain, editUrl, deleteUrl } = endpoints;

  const handleDelete = (id) => {
    dispatch({ type: 'TOGGLE_IS_LOADING' });

    fetch(`${deleteUrl.replace(':id', id)}.json`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to delete page.');
        }
        window.location.reload();
      })
      .catch((error) => {
        dispatch({ type: 'TOGGLE_IS_LOADING' });
        dispatch({ type: 'TOGGLE_IS_ERROR' });
        dispatch({ type: 'SET_STATUS', payload: `Some error occured: [${JSON.stringify(error)}]` });
      });
  };

  return (
    <td>
      <div className="d-flex gap-3">
        <a className="btn" href={editUrl.replace(':id', page.id)}>
          <i className="fe fe-edit" />
        </a>
        <a className="btn" href={`${currentDomain}${page.url_slug}`}>
          <i className="fe fe-eye" />
        </a>
        <div className="dropdown">
          <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fe fe-more-horizontal" />
          </button>
          <ul className="dropdown-menu">
            <li>
              <button className="dropdown-item" onClick={() => handleDelete(page.id)}>
                Delete
              </button>
            </li>
          </ul>
        </div>
      </div>
    </td>
  );
}

function TableRowSubGroup({ pages, endpoints, dispatch }) {
  return pages.map((page, index) => (
    <motion.tr
      key={index}
      className="dp-table-subgroup-row"
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={{
        open: { opacity: 1 },
        collapsed: { opacity: 0 },
      }}
      transition={{ duration: 0.33 }}
    >
      <th>{page.title}</th>
      <td>/{page.url_slug}</td>
      <td>{formatDateTime(page.updated_at)}</td>
      <td>
        <StatusBadge isDraft={page.is_draft} />
      </td>
      <RowOptions {...{ page, endpoints, dispatch }} />
    </motion.tr>
  ));
}

function TableRowGrouped({ groupKey, pages, endpoints, dispatch }) {
  const [isGroupOpen, setIsGroupOpen] = useState(true);

  const chevronStyle = {
    fontSize: '1.1rem',
    transition: 'transform 0.3s ease',
    transform: isGroupOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  };

  return (
    <>
      <motion.tr className="dp-table-row">
        <motion.th className="py-0" colSpan={TABLE_HEADERS.length - 4}>
          <motion.button
            className="btn d-flex justify-content-center align-items-center gap-3 ps-0 pe-3"
            type="button"
            onClick={() => setIsGroupOpen(!isGroupOpen)}
          >
            <motion.i className="fe fe-chevron-down" style={chevronStyle} />
            <motion.span className="rounded bg-secondary px-3 py-2">/{groupKey}</motion.span>
          </motion.button>
        </motion.th>
        <motion.td className="py-0" colSpan={TABLE_HEADERS.length - 1}>
          <motion.span className="text-muted fst-italic">Pages: {pages.length}</motion.span>
        </motion.td>
      </motion.tr>
      <AnimatePresence>{isGroupOpen && <TableRowSubGroup {...{ pages, endpoints, dispatch, isGroupOpen }} />}</AnimatePresence>
    </>
  );
}

export default function DynamicPagesTable({ state, dispatch, endpoints }) {
  const handlePageChange = (page) => {
    dispatch({ type: 'SET_CURRENT_PAGE_CHUNK', payload: page });
  };

  return (
    <div className="dynamic-pages-table">
      <table className="table">
        <thead>
          <tr>
            {TABLE_HEADERS.map((header, index) => (
              <th scope="col" className="border-top-0" key={index} style={{ width: header.width }}>
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {state.pages.length <= 0 ? (
            <tr>
              <td colSpan={TABLE_HEADERS.length} className="text-center py-5">
                No pages found.
              </td>
            </tr>
          ) : (
            Object.entries(state.pages[state.currentPageChunk - 1]).map(([key, pages], index) => {
              return <TableRowGrouped key={index} groupKey={key} {...{ pages, endpoints, dispatch }} />;
            })
          )}
        </tbody>
      </table>
      <ResponsivePagination
        className="pagination justify-content-end"
        current={state.currentPageChunk}
        total={state.pages.length}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
