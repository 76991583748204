import { useState } from "react"
import * as WebAuthnJSON from "@github/webauthn-json"
import TextInput from "../inputs/form-inputs/TextInput"

export default function RegisterCredential(props) {
  const csrfToken = document.querySelector("[name='csrf-token']").content

  const [nickname, setNickname] = useState("")
  const [error, setError] = useState(null)

  const callRegister = function(){
    fetch(props.registerUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      credentials: 'same-origin',
    }).then((response) => {
      return response.json()
    }).then((data) => {
      WebAuthnJSON.create({ "publicKey": data }).then(async function(credential) {
        fetch(props.createUrl, {
          method: 'POST',
          headers: {
          Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken,
          },
          credentials: 'same-origin',
          body: JSON.stringify({ nickname, credential }),
        }).then((response) => {
          return response.json()
        }).then((data) => {
          if(data.status == 'success'){
            window.location.replace(props.redirectTo)
          } else {
            setError(data.message)
          }
        })
      }).catch(function(error) {
        console.log("something is wrong", error);
      });
    })
  }

  return(
    <>
      <div className="card">
        <div className="card-body">
          { error &&
            <div className="card bg-info border">
              <div className="card-body">
                <i className="fe fe-alert-triangle me-1"></i>
                {error}
              </div>
            </div>
          }
          <div className="input-group">
            <TextInput onChange={(event) => setNickname(event.target.value)} placeholder="Nickname for credential" />
            <button className="btn btn-primary" onClick={() => callRegister()}>
              Create
            </button>
          </div>
        </div>
      </div>
    </>
  )
}


