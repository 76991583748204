import { Fragment } from 'react';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

import SortableMetadata from '../_metadata_list/SortableMetadata';

export default function MetadataList({ state, dispatch, setActivePane }) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const metadata = state.data.metadata;

  const generateNewMetadataEntry = () => {
    const key = `new_entry_${metadata.length + 1}`;
    const value = `New Entry #${metadata.length + 1}`;
    return { id: crypto.randomUUID(), key, value };
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = metadata.findIndex((m) => m.id === active.id);
      const newIndex = metadata.findIndex((m) => m.id === over.id);

      // Reorder the metadata entries
      const newMetadata = arrayMove(metadata, oldIndex, newIndex);

      // Updates the category schema with the new order of the fields
      dispatch({ type: 'UPDATE_DATA', payload: { metadata: newMetadata } });
    }
  };

  const handleAddMetadataAtIndex = (index) => {
    dispatch({ type: 'ADD_METADATA_AT_INDEX', payload: { metadata: generateNewMetadataEntry(), index } });
  };

  const handleAddMetadataItemLast = () => {
    dispatch({ type: 'ADD_METADATA', payload: generateNewMetadataEntry() });
  };

  return (
    <div className="d-flex flex-column gap-2 border-top pt-3">
      <div className="d-flex flex-column">
        <h3 className="mb-1">Metadata</h3>
        <p className="text-muted mb-0">Configure every detail about your site here</p>
      </div>
      <div className="dp-metadata-container mh-100 overflow-auto">
        {metadata.length ? (
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={metadata} strategy={verticalListSortingStrategy}>
              {metadata.map((m, index) => {
                return (
                  <Fragment key={index}>
                    <SortableMetadata {...{ m, index, dispatch, setActivePane }} />
                    {index !== metadata.length - 1 && (
                      <button className="dp-add-in-between" onClick={() => handleAddMetadataAtIndex(index + 1)}>
                        <hr />
                        <i className="fe fe-plus" />
                        <hr />
                      </button>
                    )}
                  </Fragment>
                );
              })}
            </SortableContext>
          </DndContext>
        ) : (
          <div className="text-center text-muted my-5 py-5">No metadata has been added yet.</div>
        )}
        <button className="btn btn-primary mt-3" onClick={handleAddMetadataItemLast}>
          Add (<i className="fe fe-plus" />)
        </button>
      </div>
    </div>
  );
}
