import React, { useState } from 'react';
import { Chart } from 'react-google-charts';

const timeRanges = [
  { key: 'count_total', label: 'Count (Total)' },
  { key: 'count_30d', label: 'Count (Past 30 Days)' },
  { key: 'count_yesterday', label: 'Count (Yesterday)' },
  { key: 'count_today', label: 'Count (Today)' },
];

function BarChart({ category, timeRange }) {
  if (!category.category_values.length) {
    return (
      <div className="card border p-5">
        <div className="d-flex flex-column justify-content-center align-items-center gap-3">
          <div className="d-flex">
            <h4>{category.name}</h4>
          </div>
          <p>No category values are used as filters here.</p>
        </div>
      </div>
    );
  }

  const options = {
    title: category.name,
    chartArea: { width: '50%' },
    animation: {
      duration: 350,
      startup: true,
      easing: 'out',
    },
    hAxis: {
      minValue: 0,
      format: '0',
      title: 'Frequency',
      textStyle: {
        color: '#FFF',
      },
      titleTextStyle: {
        color: '#FFF',
      },
    },
    vAxis: {
      title: 'Filters',
      textStyle: {
        color: '#FFF',
      },
      titleTextStyle: {
        color: '#FFF',
      },
    },
    legend: {
      textStyle: {
        color: '#FFF',
      },
    },
    titleTextStyle: {
      color: '#FFF',
    },
    backgroundColor: 'transparent',
    baselineColor: 'white',
    colors: ['#B1C2D9'],
  };

  const data = category.category_values.map((cv) => [cv.value, cv[timeRange]]);

  // Add headers at the start of the array
  data.unshift(['Value', `${timeRanges.find((tr) => tr.key === timeRange).label}`]);

  // Remove all rows with 0 values
  const sanitisedData = data.filter((row) => row[1] !== 0);

  // If there is only one row left, it means all values are 0
  if (sanitisedData.length <= 1) {
    return (
      <div className="card border p-5">
        <div className="d-flex flex-column justify-content-center align-items-center gap-3">
          <div className="d-flex">
            <h4>{category.name}</h4>
          </div>
          <p>No category values are used as filters here.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="card border">
      <Chart chartType="BarChart" width="100%" height={500} data={sanitisedData} options={options} />
    </div>
  );
}

export default function ChartView({ job_search_used_filters_count }) {
  const [timeRange, setTimeRange] = useState(timeRanges[0].key);

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex gap-2">
        {timeRanges.map((tr) => (
          <div key={tr.key}>
            <input
              type="radio"
              className="btn-check btn-sm"
              name="options-time-range"
              id={tr.key}
              checked={timeRange === tr.key}
              onChange={() => setTimeRange(tr.key)}
            />
            <label className="btn" htmlFor={tr.key}>
              {tr.label}
            </label>
          </div>
        ))}
      </div>
      {job_search_used_filters_count.length ? (
        job_search_used_filters_count.map((category, index) => <BarChart key={index} category={category} timeRange={timeRange} />)
      ) : (
        <div>No categories are used as filters.</div>
      )}
    </div>
  );
}
