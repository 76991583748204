import { useEffect, useState } from 'react';
import { Tooltip } from 'bootstrap';

import StatCard from '../../card/stat-card/StatCard';

import TableView from './_components/TableView';
import CardView from './_components/CardView';
import ChartView from './_components/ChartView';

const views = [
  { key: 'table', label: 'Table View' },
  { key: 'card', label: 'Card View' },
  { key: 'chart', label: 'Chart View' },
];

const VIEW_COMPONENTS = {
  table: TableView,
  card: CardView,
  chart: ChartView,
};

export default function JobInsightsDashboard({ job_search_used_filters_count, job_searches_count, job_searches_yesterday_count, job_searches_today_count }) {
  const [view, setView] = useState(views[0].key);

  const totalTodayCount = job_search_used_filters_count.reduce((total, category) => {
    const categoryTodayCount = category.category_values.reduce((total, value) => {
      return total + value.count_today;
    }, 0);
    return total + categoryTodayCount;
  }, 0);

  const totalYesterdayCount = job_search_used_filters_count.reduce((total, category) => {
    const categoryTodayCount = category.category_values.reduce((total, value) => {
      return total + value.count_yesterday;
    }, 0);
    return total + categoryTodayCount;
  }, 0);

  const total30DCount = job_search_used_filters_count.reduce((total, category) => {
    const categoryTodayCount = category.category_values.reduce((total, value) => {
      return total + value.count_30d;
    }, 0);
    return total + categoryTodayCount;
  }, 0);

  const renderView = () => {
    const ViewComponent = VIEW_COMPONENTS[view];
    return ViewComponent ? <ViewComponent {...{ job_search_used_filters_count }} /> : null;
  };

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
  }, []);

  return (
    <div className="d-flex flex-column gap-4" style={{ maxWidth: 1100 }}>
      <div className="d-flex gap-4">
        <StatCard title="Total Searches (All Time)" value={job_searches_count} iconClass="fe fe-search" />
        <StatCard title="Total Searches (Yesterday)" value={job_searches_yesterday_count} iconClass="fe fe-search" />
        <StatCard title="Total Searches (Today)" value={job_searches_today_count} iconClass="fe fe-search" />
      </div>
      <div className="d-flex gap-4">
        <StatCard title="Total Filters Used (30 Days)" value={total30DCount} iconClass="fe fe-filter" />
        <StatCard title="Total Filters Used (Yesterday)" value={totalYesterdayCount} iconClass="fe fe-filter" />
        <StatCard title="Total Filters Used (Today)" value={totalTodayCount} iconClass="fe fe-filter" />
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2">
          <h2 className="mb-0 ">Filters Statistics</h2>
          <i
            type="button"
            className="fe fe-info mb-0"
            data-bs-toggle="tooltip"
            data-bs-html="true"
            data-bs-title="<b>Category Values</b> used for job filters will appear here if they are used at least <u>once</u>."
          />
        </div>
        <div className="d-flex gap-2">
          {views.map((v) => (
            <button key={v.key} className={`btn btn-outline-primary btn-sm ${view === v.key ? 'active' : ''}`} onClick={() => setView(v.key)}>
              {v.label}
            </button>
          ))}
        </div>
        <div className="pt-2">
          {job_search_used_filters_count?.length ? (
            renderView()
          ) : (
            <div className="card card-primary p-4">
              <h3 className="text-muted mb-0">No filters used yet.</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
