import { useEffect, Fragment } from 'react';

function LinkedinUsersList(props) {
  const { formState, formDispatch, linkedin_users } = props;

  const handleLinkedinUsersChange = (formState, formDispatch, liu) => {
    if (formState.data.linkedin_users.find((u) => u.id === liu.id)) {
      formDispatch({ type: 'update_data', payload: { linkedin_users: formState.data.linkedin_users.filter((u) => u.id !== liu.id) } });
      return;
    }
    formDispatch({ type: 'update_data', payload: { linkedin_users: [...formState.data.linkedin_users, { id: liu.id }] } });
  };

  return (
    <div className="d-flex flex-column px-3 gap-3">
      <h3 className="mb-0">LinkedIn Accounts</h3>
      {linkedin_users.length ? (
        linkedin_users.map((liu) => (
          <button
            key={liu.id}
            className="linkedin_users_button"
            type="button"
            onClick={() => handleLinkedinUsersChange(formState, formDispatch, liu)}
            disabled={formState.isDisabled}
          >
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={{ id: liu.id }}
                  checked={Boolean(formState.data.linkedin_users.find((u) => u.id === liu.id))}
                  onChange={() => handleLinkedinUsersChange(formState, formDispatch, liu)}
                  disabled={formState.isDisabled}
                />
              </div>
            </div>
            <div className="col ps-4">
              {liu.picture ? (
                <img src={liu.picture} style={{ borderRadius: '50%', width: 32, height: 32 }} />
              ) : (
                <div className="border d-flex justify-content-center align-items-center" style={{ borderRadius: '50%', height: 32, width: 32 }}>
                  <i className="fe fe-user" />
                </div>
              )}
            </div>
            <div className="col-4">{liu.name}</div>
            <div className="col-4" />
          </button>
        ))
      ) : (
        <div>
          <div className="w-100 d-flex justify-content-center">
            <p className="text-muted">No LinkedIn users found.</p>
          </div>
        </div>
      )}
    </div>
  );
}

function LinkedinOrganisationsList(props) {
  const { formState, formDispatch, linkedin_organisations } = props;

  const handleLinkedinOrganisationsChange = (formState, formDispatch, o, liu) => {
    const orgs = formState.data.linkedin_organisations;
    const org = orgs.find((org) => org.id === o.id);

    if (org && org.linkedin_user_id === liu.id) {
      formDispatch({
        type: 'update_data',
        payload: {
          linkedin_organisations: orgs.filter((org) => org.id !== o.id),
        },
      });
      return;
    }

    formDispatch({
      type: 'update_data',
      payload: {
        linkedin_organisations: orgs.find((org) => org.id === o.id)
          ? orgs.map((org) => (org.id === o.id ? { ...org, linkedin_user_id: liu.id } : org))
          : [...orgs, { id: o.id, linkedin_user_id: liu.id }],
      },
    });
  };

  return (
    <div className="d-flex flex-column px-3 gap-3">
      <h3 className="mb-0">LinkedIn Organisations</h3>
      {linkedin_organisations.length ? (
        linkedin_organisations.map((o) => (
          <div key={o.id} className="accordion" id={`accordion-parent-${o.id}`}>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse-${o.id}`}
                  aria-expanded={false}
                  aria-controls={`collapse-${o.id}`}
                >
                  <div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={{ id: o.id }}
                        checked={Boolean(formState.data.linkedin_organisations.find((org) => org.id === o.id))}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col ps-4">
                    {o.picture ? (
                      <img src={o.picture} style={{ borderRadius: '50%', width: 32, height: 32 }} />
                    ) : (
                      <div className="border d-flex justify-content-center align-items-center" style={{ borderRadius: '50%', height: 32, width: 32 }}>
                        <i className="fe fe-user" />
                      </div>
                    )}
                  </div>
                  <div className="col-4">{o.localized_name}</div>
                  <div className="col-4">{o.vanity_name}</div>
                </button>
              </h2>
              <div id={`collapse-${o.id}`} className="accordion-collapse collapse" data-bs-parent={`#accordion-parent-${o.id}`}>
                <div className="accordion-body">
                  <div className="d-flex flex-column gap-3 ps-5">
                    {o.linkedin_users.map((liu) => (
                      <div key={liu.id} className="input-group ps-2">
                        <button
                          type="button"
                          className="input-group-text col d-flex gap-4"
                          onClick={() => handleLinkedinOrganisationsChange(formState, formDispatch, o, liu)}
                          disabled={formState.isDisabled}
                        >
                          <div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={o.id}
                                value={{ id: liu.id }}
                                checked={formState.data.linkedin_organisations.find((org) => org.id === o.id)?.linkedin_user_id === liu.id}
                                onChange={() => handleLinkedinOrganisationsChange(formState, formDispatch, o, liu)}
                                disabled={formState.isDisabled}
                              />
                            </div>
                          </div>
                          <div>
                            {liu.picture ? (
                              <img src={liu.picture} style={{ borderRadius: '50%', width: 32, height: 32 }} />
                            ) : (
                              <div className="border d-flex justify-content-center align-items-center" style={{ borderRadius: '50%', height: 32, width: 32 }}>
                                <i className="fe fe-user" />
                              </div>
                            )}
                          </div>
                          <div className="col-3">{liu.name}</div>
                          <div className="col-4">{liu.email}</div>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>
          <div className="w-100 d-flex justify-content-center">
            <p className="text-muted ">No LinkedIn organisations found.</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default function PostAccountsStep(props) {
  const { formState, formDispatch, linkedin_users, linkedin_organisations } = props;

  useEffect(() => {
    if (!formState.data.linkedin_users.length && !formState.data.linkedin_organisations.length) {
      formDispatch({ type: 'update', payload: { isInvalid: true, error: 'Please select at least one account or organisation to continue.' } });
      return;
    }

    formDispatch({ type: 'update', payload: { isInvalid: false, error: '' } });
  }, [formState.data.linkedin_users, formState.data.linkedin_organisations]);

  return (
    <>
      <div className="card-body">
        <div className="d-flex flex-column gap-5">
          <h2 className="mb-0">Where would you like to post this?</h2>
          <div className="container">
            <LinkedinUsersList {...{ formState, formDispatch, linkedin_users }} />
          </div>
          <div className="container">
            <LinkedinOrganisationsList {...{ formState, formDispatch, linkedin_organisations }} />
          </div>
        </div>
      </div>
    </>
  );
}
