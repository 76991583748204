import { useEffect, useMemo } from 'react';

import { v4 as uuidv4 } from 'uuid';

import Card from '../card/Card';

import TextInput from './form-inputs/TextInput';
import SwitchInput from './form-inputs/SwitchInput';
import NumberInput from './form-inputs/NumberInput';
import DropdownInput from './form-inputs/DropdownInput';
import FileInput from './form-inputs/FileInput';
import CodeInput from './form-inputs/CodeInput';
import DateInput from './form-inputs/DateInput';
import FormattedTextInput from './form-inputs/FormattedTextInput';
import FormDescrption from '../form/description/FormDescription';
import SortableItem from '../dnd-kit/SortableItem/SortableItem';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { getDate, getDateTime } from '../../src/utils/DatesAndTime';

export default function ObjectValueInput(props) {
  const {
    schema,
    attachments,
    setAttachments,
    updateSchemaAttributes,
    attachmentUploadBuffer,
    setAttachmentDeleteBuffer,
    setAttachmentUploadBuffer,
    deleteAttachment,
    objectRequiredFields,
    setObjectRequiredFields,
    setRequiredFields,
    forms,
    categories,
  } = props;

  const addObjectEntry = (schema) => {
    const v = { id: uuidv4() };

    schema.object_entries.forEach((oe) => {
      const { name } = oe;

      v[name] = oe.default_value || '';
    });

    updateSchemaAttributes(schema.id, {
      ...schema,
      value: [...(schema.value || []), v],
    });
  };

  const removeObjectEntry = (schema, value) => {
    // Removes the attachment from the attachment React state buffer
    setAttachments((prev) => prev?.filter((each) => !each.attachment.key.includes(value.id)));

    // Adds Attachment to Deletion Buffer to be sent to API for deletion
    const attrToRemove = attachments?.filter((a) => a.attachment.key.includes(value.id));

    if (attrToRemove?.length) {
      setAttachmentDeleteBuffer((curr) => [...curr, ...attrToRemove]);
    }

    // Remove Attachment from Upload Buffer
    setAttachmentUploadBuffer((curr) => {
      const key = Object.keys(curr).find((k) => k.includes(value.id));

      const copy = structuredClone(curr);
      delete copy[key];

      return copy;
    });

    updateSchemaAttributes(schema.id, { ...schema, value: schema.value?.filter((each) => each.id !== value.id) });

    // Add object to requiredFields array if it is required
    if (schema.isRequired && schema.value.length <= 1) {
      setRequiredFields((prev) => [...prev, schema]);
    }
  };

  const updateObjectValue = (schema, objectValueId, newObjectValue) => {
    const newObjectValues = schema.value.map((v) => {
      if (v.id === objectValueId) {
        return newObjectValue;
      }

      return v;
    });

    updateSchemaAttributes(schema.id, { ...schema, value: newObjectValues });
  };

  // Memoise the array of required fields across re-renders
  const requiredObjectFields = useMemo(() => {
    return schema.object_entries?.filter((oe) => oe.isRequired);
  }, [schema.object_entries]);

  // Function to control classnames if field is required
  const requiredFieldClassname = (schema, oe, v) => {
    if (!objectRequiredFields[schema.name]?.[oe.name]?.find((o) => o.id === v.id)) return;

    switch (true) {
      case oe.data_type === 'file':
        return 'is-invalid-filepond';
      case oe.data_type === 'checkbox':
        return 'is-invalid-switch';
      case oe.data_type === 'dropdown' || oe.data_type === 'form' || oe.data_type === 'category':
        return 'is-invalid-dropdown';
      default:
        return 'is-invalid-field';
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = schema.value.findIndex((schema) => schema.id === active.id);
      const newIndex = schema.value.findIndex((schema) => schema.id === over.id);

      // Updates the category schema with the new order of the fields
      updateSchemaAttributes(schema.id, { ...schema, value: arrayMove(schema.value, oldIndex, newIndex) });
    }
  };

  // useEffect to check if required fields are filled
  useEffect(() => {
    // If there are no required fields, return
    if (!requiredObjectFields?.length) return;

    const objectsWithIssues = requiredObjectFields.reduce((acc, rof) => {
      const objects = schema.value
        .filter((v) => {
          // If the field is a file, check if it's uploaded or in the upload buffer
          if (rof.data_type === 'file') {
            // If it's not uploaded or not found in upload buffer, then it's required
            return (
              !Object.keys(attachmentUploadBuffer).find((k) => k.includes(rof.name) && k.includes(v.id)) &&
              !attachments?.find((a) => a.attachment.key.includes(rof.name) && a.attachment.key.includes(v.id))
            );
          }

          // If the field has no value, it is empty and thus it's required
          return !v[rof.name];
        })
        .map((v) => ({ id: v.id, index: schema.value.findIndex((i) => i.id === v.id) }));

      // If there are objects with issues, add them to the final reducer state
      if (objects.length) {
        acc[rof.name] = objects;
      }

      return acc;
    }, {});

    setObjectRequiredFields((prev) => {
      // Create a copy of the objectRequiredFields state
      const copy = { ...prev };
      if (Object.values(objectsWithIssues).every((element) => !element)) {
        // If there are no objects with issues, remove the field from the objectRequiredFields state
        delete copy[schema.name];
      } else {
        // If there are objects with issues, add them to the objectRequiredFields state
        copy[schema.name] = objectsWithIssues;
      }
      // Return the new state
      return copy;
    });
  }, [schema.value, requiredObjectFields, attachmentUploadBuffer, attachments]);

  if (!schema.object_entries?.length) {
    return <p className="text-warning">This template has no field entries.</p>;
  }

  return (
    <div className={`d-flex flex-column gap-4 py-4 rounded ${!schema.value.length && schema.isRequired ? 'is-invalid-object' : 'border'}`}>
      <div className="d-flex flex-column gap-4 w-100 px-4">
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={schema.value} strategy={verticalListSortingStrategy}>
            {!schema.value.length ? (
              <label className="text-info align-self-center">No fields are created for this template.</label>
            ) : (
              schema.value.map((v) => (
                <SortableItem key={v.id} id={v.id}>
                  <Card
                    cardClass="border me-4"
                    header={
                      !schema.value.length ? null : (
                        <div className="d-flex flex-row-reverse justify-content-between align-items-center">
                          <button type="button" className="btn btn-danger btn-sm" onClick={() => removeObjectEntry(schema, v)}>
                            <i className="fe fe-x" />
                          </button>
                        </div>
                      )
                    }
                  >
                    {schema.object_entries.map((oe) => {
                      return (
                        <div key={oe.id} className="d-flex flex-column gap-2">
                          <div className="d-flex gap-2">
                            <label>{oe.name}</label>
                            {oe.isRequired && <label className="text-danger">*</label>}
                          </div>

                          <div className={requiredFieldClassname(schema, oe, v)}>
                            {oe.data_type === 'text' && (
                              <TextInput
                                value={v[oe.name] ?? ''}
                                onChange={(event) => updateObjectValue(schema, v.id, { ...v, [oe.name]: event.target.value })}
                              />
                            )}
                            {oe.data_type === 'formatted_text' && (
                              <FormattedTextInput
                                value={v[oe.name] ?? ''}
                                onEditorChange={(value) => updateObjectValue(schema, v.id, { ...v, [oe.name]: value })}
                              />
                            )}
                            {oe.data_type === 'number' && (
                              <NumberInput
                                value={v[oe.name] ?? ''}
                                onChange={(event) => updateObjectValue(schema, v.id, { ...v, [oe.name]: event.target.value })}
                              />
                            )}
                            {oe.data_type === 'date' && (
                              <DateInput
                                value={v[oe.name] ?? ''}
                                onChange={([date]) => {
                                  if (!date) {
                                    updateObjectValue(schema, v.id, { ...v, [oe.name]: '' });
                                    return;
                                  }

                                  updateObjectValue(schema, v.id, {
                                    ...v,
                                    [oe.name]: getDate(date),
                                  });
                                }}
                              />
                            )}
                            {oe.data_type === 'datetime' && (
                              <DateInput
                                value={v[oe.name] ?? ''}
                                options={{
                                  enableTime: true,
                                  dateFormat: 'Y-m-d H:i',
                                }}
                                onChange={([datetime]) => {
                                  if (!datetime) {
                                    updateObjectValue(schema, v.id, { ...v, [oe.name]: '' });
                                    return;
                                  }

                                  updateObjectValue(schema, v.id, {
                                    ...v,
                                    [oe.name]: getDateTime(datetime),
                                  });
                                }}
                              />
                            )}
                            {oe.data_type === 'form' && (
                              <DropdownInput
                                value={v[oe.name] ?? ''}
                                onChange={(event) => {
                                  updateObjectValue(schema, v.id, { ...v, [oe.name]: event.value });
                                }}
                                formattedDropdownValues={forms}
                                isClearable
                              />
                            )}
                            {oe.data_type === 'category' && (
                              <DropdownInput
                                value={v[oe.name] ?? ''}
                                onChange={(event) => {
                                  updateObjectValue(schema, v.id, { ...v, [oe.name]: event.value });
                                }}
                                formattedDropdownValues={categories
                                  .find((c) => c.id === oe.foreign_category_id)
                                  ?.values?.map((v) => ({ value: v.id, label: v.name }))}
                                isClearable
                                isMulti={oe.isMulti}
                              />
                            )}
                            {oe.data_type === 'code' && (
                              <CodeInput
                                value={v[oe.name] ?? ''}
                                onValueChange={(code) => {
                                  updateObjectValue(schema, v.id, { ...v, [oe.name]: code });
                                }}
                              />
                            )}
                            {oe.data_type === 'checkbox' && (
                              <SwitchInput
                                onChange={(event) => {
                                  updateObjectValue(schema, v.id, { ...v, [oe.name]: event.target.checked });
                                }}
                                value={JSON.parse(v[oe.name] || 'false')}
                                checked={JSON.parse(v[oe.name] || 'false')}
                              />
                            )}
                            {oe.data_type === 'dropdown' && (
                              <DropdownInput
                                value={v[oe.name] ?? ''}
                                onChange={(newValues) => {
                                  let res = newValues;
                                  if (!Array.isArray(res)) {
                                    res = newValues.value;
                                  }
                                  updateObjectValue(schema, v.id, { ...v, [oe.name]: res });
                                }}
                                dropdownValues={oe.dropdown_values}
                                isMulti={oe.isMulti}
                              />
                            )}
                            {oe.data_type === 'file' && (
                              <FileInput
                                mode={
                                  attachments?.find((a) => a.attachment.key.split('.')[1] === oe.name && a.attachment.key.split('.')[2] === v.id)
                                    ? 'edit'
                                    : 'create'
                                }
                                attachmentName={`${schema.name}.${oe.name}.${v.id}`}
                                attachmentUploadBuffer={attachmentUploadBuffer}
                                setAttachmentUploadBuffer={setAttachmentUploadBuffer}
                                setAttachmentDeleteBuffer={setAttachmentDeleteBuffer}
                                attachments={attachments?.filter((a) => a.attachment.key.includes(oe.name) && a.attachment.key.includes(v.id))}
                                deleteAttachment={deleteAttachment}
                              />
                            )}
                          </div>

                          <FormDescrption description={oe.description} />
                        </div>
                      );
                    })}
                  </Card>
                </SortableItem>
              ))
            )}
          </SortableContext>
        </DndContext>
      </div>
      <div className="d-flex justify-content-end align-items-center w-100 px-4">
        <button
          type="button"
          className="btn btn-primary d-flex justify-content-around align-items-center"
          onClick={() => addObjectEntry(schema)}
          style={{
            minWidth: 80,
            maxWidth: 80,
          }}
        >
          <i className="fe fe-plus" /> Add
        </button>
      </div>
    </div>
  );
}
