import React from "react";
import { CSVParser } from "@sourceflow-uk/cms-csv-parser";

function CSVImporter({ ...props }) {
  return (
    <div>
      <CSVParser {...props} />
    </div>
  );
}

export default CSVImporter;
