import TextInput from '../../../../../inputs/form-inputs/TextInput';
import SwitchInput from '../../../../../inputs/form-inputs/SwitchInput';
import FormattedTextInput from '../../../../../inputs/form-inputs/FormattedTextInput';
import NumberInput from '../../../../../inputs/form-inputs/NumberInput';
import ImageInput from '../ImageInput';

import { snakeToRegularString } from '../../../../lib';

export default function TemplatePropField({ contentId, templateName, templateItem, state, dispatch, name, label, type, defaultValue, options = [], images }) {
  const value = templateItem[name];

  const handleStringChange = (e) => {
    dispatch({
      type: 'UPDATE_TEMPLATE_ITEM',
      payload: { contentId, templateName, templateItemId: templateItem.id, fieldName: name, fieldValue: e.target.value },
    });
  };

  const handleNumberChange = (e) => {
    dispatch({
      type: 'UPDATE_TEMPLATE_ITEM',
      payload: { contentId, templateName, templateItemId: templateItem.id, fieldName: name, fieldValue: Number(e.target.value) },
    });
  };

  const handleBooleanChange = (e) => {
    dispatch({
      type: 'UPDATE_TEMPLATE_ITEM',
      payload: { contentId, templateName, templateItemId: templateItem.id, fieldName: name, fieldValue: e.target.checked },
    });
  };

  const handleArrayChange = (e) => {
    dispatch({
      type: 'UPDATE_TEMPLATE_ITEM',
      payload: { contentId, templateName, templateItemId: templateItem.id, fieldName: name, fieldValue: JSON.parse(e.target.value) },
    });
  };

  const handleFormattedTextChange = (value) => {
    dispatch({
      type: 'UPDATE_TEMPLATE_ITEM',
      payload: { contentId, templateName, templateItemId: templateItem.id, fieldName: name, fieldValue: value },
    });
  };

  const handleFileChange = (value) => {
    dispatch({
      type: 'UPDATE_TEMPLATE_ITEM',
      payload: { contentId, templateName, templateItemId: templateItem.id, fieldName: name, fieldValue: value },
    });
  };

  const renderStringInput = () => {
    if (options && options.length) {
      return (
        <select className="form-select" value={value || ''} onChange={handleStringChange}>
          <option value="" disabled>
            Select an option
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    }

    return <TextInput value={value || ''} onChange={handleStringChange} />;
  };

  const renderNumberInput = () => <NumberInput value={value || 0} onChange={handleNumberChange} />;

  const renderBooleanInput = () => <SwitchInput checked={!!value} onChange={handleBooleanChange} />;

  const renderArrayInput = () => (
    <select className="form-select" value={JSON.stringify(value) || ''} onChange={handleArrayChange}>
      <option value="" disabled>
        Select an option
      </option>
      {options.length ? (
        options.map((option, index) => (
          <option key={index} value={JSON.stringify(option.value)}>
            {option.label}
          </option>
        ))
      ) : (
        <option value="">No options found.</option>
      )}
    </select>
  );

  const renderFormattedTextInput = () => <FormattedTextInput value={value || ''} onEditorChange={handleFormattedTextChange} />;

  const renderFileInput = () => (
    <ImageInput id={`${contentId}-${templateItem.id}-${name}`} value={value} onChange={handleFileChange} state={state} dispatch={dispatch} />
  );

  const renderOptions = {
    string: renderStringInput,
    boolean: renderBooleanInput,
    array: renderArrayInput,
    number: renderNumberInput,
    formatted_text: renderFormattedTextInput,
    file: renderFileInput,
  };

  if (type !== 'string' && type !== 'boolean' && type !== 'array' && type !== 'number' && type !== 'formatted_text' && type !== 'file') {
    return <div>This prop is not configured properly.</div>;
  }

  return (
    <div className="d-flex flex-column gap-1">
      <label className="text-muted">{snakeToRegularString(label || name)}</label>
      {renderOptions[type]()}
    </div>
  );
}
