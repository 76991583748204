import TextInput from '../../../../inputs/form-inputs/TextInput';
import ImageCard from './_image_selector_modal/ImageCard';

export default function ImageSelectorModal({ id, onChange, state, dispatch }) {
  const { images, filteredImages } = state;

  const handleSearch = (e) => {
    if (!e.target.value) {
      dispatch({ type: 'SET_FILTERED_IMAGES', payload: { filteredImages: images } });
      return;
    }
    const keyword = e.target.value.toLowerCase();
    const filtered = images.filter((image) => image.name.toLowerCase().includes(keyword));
    dispatch({ type: 'SET_FILTERED_IMAGES', payload: { filteredImages: filtered } });
  };

  return (
    <div className="modal fade" id={`imageSelector-${id}-Modal`} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h1 className="modal-title fs-3">Select an image</h1>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target={`#imageUpload-${id}-Modal`}>
                Upload
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="d-flex flex-column gap-3">
              <TextInput placeholder="Search for an image..." onChange={handleSearch} />
              <div className="dp-image-selector-content">
                {!images || !images.length ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : filteredImages?.length ? (
                  filteredImages.map((image) => <ImageCard key={image.id} {...{ image, onChange }} />)
                ) : (
                  <div className="w-100 d-flex align-items-center justify-content-center p-5">No images found.</div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
