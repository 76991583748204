import { useInView } from 'react-intersection-observer';

export default function ImageCard({ image, onChange }) {
  const { name, url, id } = image;
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  const handleSelectOnClick = () => {
    onChange({ id, url, alt: '', title: '' });
  };

  return (
    <div className="image-card-radio">
      <div ref={ref} className="image-container">
        {url ? (
          inView ? (
            <img src={url} alt={`Image ID: ${id}`} />
          ) : (
            <div className="preview-container">
              <i className="fe fe-file" />
            </div>
          )
        ) : (
          <div className="preview-container">CDN URL is not found.</div>
        )}
      </div>
      <div className="d-flex px-2 pt-3 align-items-center justify-content-between">
        <div className="d-flex gap-2 align-items-center justify-content-between">
          <label className="d-inline-block text-truncate" style={{ maxWidth: 170 }}>
            {name}
          </label>
        </div>
        <button className="btn btn-sm btn-primary" onClick={handleSelectOnClick} data-bs-dismiss="modal">
          Select
        </button>
      </div>
    </div>
  );
}
