import { useEffect, useReducer, useState } from 'react';
import { getInitialBuilderState, builderReducer, convertMetadata, formatContent } from '../lib';
import { dataStateKeyword } from '../lib';

import BuilderEditor from '../BuilderEditor';
import BuilderSidebar from '../BuilderSidebar';

export default function EditDynamicPage({ dynamicPage, allSitePages, categoriesWithTemplates, categoriesLinked, componentDefinitions, endpoints }) {
  const { indexUrl, pageUrl, previewSource } = endpoints;

  const [initialState, _] = useState(getInitialBuilderState(dynamicPage));

  const [state, dispatch] = useReducer(builderReducer, initialState);

  const handleSave = () => {
    const { site_id, created_at, updated_at, is_dynamic_page, is_from_sitemap, parent_category_id, ...data } = state.data;

    const dynamicPage = {
      ...data,
      metadata: convertMetadata(data.metadata),
      content: formatContent(data.content),
    };

    fetch(`${pageUrl}.json`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
      body: JSON.stringify({
        dynamic_page: dynamicPage,
        files: state.attachmentUploadBuffer,
        attachments_to_delete: state.attachmentDeleteBuffer,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to save page');
        }
        window.location.replace(indexUrl);
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE', payload: { isError: true, error: JSON.stringify(error) } });
      });
  };

  const handleDelete = () => {
    fetch(`${pageUrl}.json`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to delete page');
        }
        window.location.replace(indexUrl);
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE', payload: { isError: true, error: JSON.stringify(error) } });
      });
  };

  useEffect(() => {
    sessionStorage.setItem(dataStateKeyword, JSON.stringify([])); // Always initialise and clear the data state on first render load.
  }, []);

  useEffect(() => {
    if (!state.isUndoRedo) {
      const dataStates = JSON.parse(sessionStorage.getItem(dataStateKeyword)) || [];
      const data = JSON.stringify(state.data);
      dataStates.push(data);
      sessionStorage.setItem(dataStateKeyword, JSON.stringify(dataStates));

      dispatch({
        type: 'SET_DATA_STATE_INDEX',
        payload: { index: JSON.parse(sessionStorage.getItem(dataStateKeyword))?.length - 1 },
      });

      return;
    }

    dispatch({ type: 'SET_IS_UNDO_REDO', payload: { isUndoRedo: false } });
  }, [state.data]);

  return (
    <div className="dp-editor">
      <BuilderSidebar
        {...{
          state,
          dispatch,
          allSitePages,
          categoriesWithTemplates,
          categoriesLinked,
          componentDefinitions,
          endpoints,
          currentPageUrlSlug: dynamicPage.url_slug,
        }}
      />
      <BuilderEditor {...{ state, dispatch, handleSave, handleDelete, previewSource, indexUrl, initialState }} />
    </div>
  );
}
