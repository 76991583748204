import SortableItem from '../../../dnd-kit/SortableItem/SortableItem';
import { componentIdentifierKeyword } from '../../lib';

export default function SortableComponent({ index, component, componentBeingEditedIndex, setActivePane, dispatch, componentDefinitions }) {
  const handleEditComponent = () => {
    dispatch({ type: 'SET_COMPONENT_BEING_EDITED_INDEX', payload: { index: componentBeingEditedIndex } });
    setActivePane(3);
  };

  const latestComponent = componentDefinitions.find((c) => {
    const componentIdentifier = c[componentIdentifierKeyword];
    const currentComponentIdentifier = component[componentIdentifierKeyword];

    return componentIdentifier !== undefined && currentComponentIdentifier !== undefined && componentIdentifier === currentComponentIdentifier;
  });

  return (
    <SortableItem className="dp-sortable-item" key={component.id} id={component.id}>
      <div className="dp-sortable-item-content">
        <div className="d-flex gap-2 align-items-center text-truncate">
          <span className="number-bullet-point">{index + 1}</span>
          <h3 className="mb-0 d-inline-block text-truncate">
            {latestComponent ? (
              latestComponent.label
            ) : (
              <div className="d-flex flex-column gap-2">
                {component.name}
                <span className="text-danger fw-lighter fs-6">(Please replace this component)</span>
              </div>
            )}
          </h3>
        </div>
        <button className="btn btn-sm" onClick={handleEditComponent}>
          <i className="fe fe-edit" />
        </button>
      </div>
    </SortableItem>
  );
}
