export default function Card({ children, header, footer, cardClass = "", bodyClass = "flex-column gap-4", cardStyle, bodyStyle }) {

  return (
    <div className={`card mb-0 ${cardClass}`} style={cardStyle}>
      {header && <div className="card-header">{header}</div>}
      <div className={`card-body d-flex ${bodyClass}`} style={bodyStyle}>
        {children}
      </div>
      {footer && <div className="card-footer">{footer}</div>}
    </div>
  );
}
