import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useEffect } from "react";

import { $generateNodesFromDOM } from '@lexical/html';
import { $getRoot, $getSelection } from 'lexical';

export default function ({ initialText }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!initialText) { return; }

    const initialHtml = `<p>${initialText.replaceAll('\n', '</p><p>')}</p>`;

    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialHtml, "text/html");
      const nodes = $generateNodesFromDOM(editor, dom);

      $getRoot().clear();
      $getRoot().select()
      $getSelection().insertNodes(nodes);
    });
  }, []);

  return null;
}