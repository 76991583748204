import { useReducer } from 'react';

import { imageInitialState, imageReducer } from '../lib';

import ImageWizard from '../ImageWizard';

export default function NewImage({ endpoints }) {
  const [state, dispatch] = useReducer(imageReducer, imageInitialState());

  return <ImageWizard {...{ state, dispatch, endpoints }} />;
}
