import { useState } from 'react';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import { ADD_EMOJI_COMMAND } from "./EmojiCommands";

function EmojiToolbar() {
  const [editor] = useLexicalComposerContext();

  const [showPicker, setShowPicker] = useState(false);

  const togglePicker = () => setShowPicker(!showPicker);

  return (
    <div className="toolbar-emoji">
      <button className="btn toolbar-emoji-btn" onClick={() => togglePicker()}>
        <span className="d-inline-block">😀</span>
      </button>
      {showPicker &&
        <Picker
          data={data}
          theme='light'
          previewPosition='none'
          onEmojiSelect={(d) => {
            editor.dispatchCommand(ADD_EMOJI_COMMAND, d.native);
          }}
        />
      }
    </div>
  );
}

export { EmojiToolbar };