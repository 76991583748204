import { useState } from 'react';

function Webhookheaders({ headers }) {
  const [headerState, setHeaderState] = useState(headers);

  const updateHeaderKey = function(oldKey, newKey){
    const newObject = { ...headerState };

    // this nasty code duplicates the old value under the new key and then removes the old key
    delete Object.assign(newObject, newObject, {[newKey]: newObject[oldKey] })[oldKey];

    setHeaderState(newObject)
  }

  const updateHeaderValue = function(header, newVal){
    setHeaderState({ ...headerState, [header]: newVal })
  }

  const addNewheader = function(){
    setHeaderState({ ...headerState, "": "" })
  }

  const deleteheader = function(header){
    let newHeaders = { ...headerState }

    delete newHeaders[header]

    setHeaderState(newHeaders)
  }


  return(
    <>
      { Object.keys(headerState).map((header, index) =>
        <div className='headerItem' key={index}>
          <div className="row">
            <div className="col">
              <div className="input-group mb-3">
                <input className="input optional form-control" placeholder="Header" value={header} onChange={(e) => updateHeaderKey(header, e.target.value)} />
                <input className="input optional form-control" placeholder="Value" value={headerState[header]} onChange={(e) => updateHeaderValue(header, e.target.value)} />
                <button className="btn btn-outline-danger" type="button" onClick={() => deleteheader(header)}>
                  <i className="fe fe-trash-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-4">
        <button className="btn btn-primary" type="button" onClick={() => addNewheader()}>Add custom header</button>
      </div>

      { Object.keys(headerState).filter(v => v != "").map((header, index) =>
          <input key={`${header}Input`} type='hidden' name={`webhook[custom_headers][${header}]`} value={headerState[header]} />
        )
      }
    </>
  );
}

export default Webhookheaders;