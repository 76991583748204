import React, { useState, useEffect } from 'react';

import { Tooltip } from 'bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { updateJSONObj } from '../../src/utils/helpers';

import Card from '../card/Card';
import ErrorMessage from '../form/error-message/ErrorMessage';

import CategorySchemas from './CategorySchemas';

function CategorySettingWithUniqueIds(props) {
  const {
    allowedToLock,
    mode,
    redirectTo,
    datasets,
    data_types,
    simplified_api_names,
    category: categoryContext,
    taken_simplified_api_names,
    persisted,
    category_types,
    forms,
    categories,
  } = props;

  const [category, setCategory] = useState(categoryContext);

  const [selectedIdentifier, setSelectedIdentifier] = useState({
    name: categoryContext.identifier ?? '',
    id: categoryContext.schema.find((s) => s.name === categoryContext.identifier).id ?? '',
  });

  const [isSchemaValid, setIsSchemaValid] = useState({
    valid: true,
    message: null,
  });

  const [messages, setMessages] = useState({
    code: null,
    description: null,
    details: [],
  });

  const updateCategory = (targetKey, replacementValue) => {
    if (targetKey === 'job_categorisation' && replacementValue === false) {
      updateCategory('is_job_categorisation_required', false);
    }
    setCategory((prev) => updateJSONObj(prev, targetKey, replacementValue));
  };

  const updateSchema = (schemaId, newSchema) => {
    const updatedSchemas = category.schema.map((s) => {
      if (s.id === schemaId) {
        return newSchema;
      }

      return s;
    });

    updateCategory('schema', updatedSchemas);
  };

  const deleteSchema = (schemaId) => {
    const updatedSchemas = category.schema.filter((s) => (s.id !== schemaId ? s : null));

    updateCategory('schema', updatedSchemas);
  };

  const saveCategory = (event) => {
    event.preventDefault();

    const url = mode === 'create' ? `/_sf/api/v1/cms/categories.json` : `/_sf/api/v1/cms/categories/${category.id}.json`;

    const { id, created_at, updated_at, category_values_count, ...restOfCategory } = category;

    const categoryWithValidatedSchema = {
      ...restOfCategory,
      // Validates that is_job_categorisation_required is set to false if job_categorisation is set to false
      is_job_categorisation_required: restOfCategory.job_categorisation ? restOfCategory.is_job_categorisation_required : false,
      // Validates the schema object_entries to remove the id field
      schema: restOfCategory.schema?.map(({ id, object_entries, ...rest }) => {
        if (object_entries && object_entries.length) {
          // Remove the id field from the object_entries array so that the database doesn't have the UUID saved.
          const validatedObjectEntries = object_entries.map(({ id, ...restOfObjectEntry }) => restOfObjectEntry);
          return { ...rest, object_entries: validatedObjectEntries };
        }
        return rest;
      }),
    };

    fetch(url, {
      method: mode === 'create' ? 'POST' : 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(categoryWithValidatedSchema),
      credentials: 'same-origin',
    })
      .then((response) => {
        if (response.ok) {
          // Only redirects when no errors are found, happens when a successful create or update operation occurs.
          window.location.replace(redirectTo);

          return;
        }

        return response.json();
      })
      .then((data) => {
        if ('error' in data) {
          const description = (data) => {
            if (typeof data.error === 'string') {
              return data.error;
            }

            if (typeof data.error === 'object' && 'description' in data.error) {
              return data.error.description;
            }

            // Returns a generic error message if no useful information is passed back from response
            return 'Something unexpected went wrong.';
          };

          const details = (data) => {
            if (typeof data.error === 'object' && 'details' in data.error) {
              return data.error.details;
            }

            if (typeof data === 'object') {
              if ('params' in data) {
                return data.params;
              }

              // Return an array of every entry in the error object as details if either "params" or "details" is not passed back from response
              return Object.entries(data).map(([key, value]) => `${key}: ${value}`);
            }

            // Details must be in array format, Returns a generic error message if no useful information is passed back from response
            return ['Something went wrong.'];
          };

          setMessages({
            code: data.error.code,
            description: description(data),
            details: details(data),
          });

          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
      })
      .catch((reason) => console.log(reason));
  };

  useEffect(() => {
    // Reassign Tooltip on schema fields every time there's a new one or one is removed
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');

    tooltips.forEach((tooltip) => {
      new Tooltip(tooltip);
    });
  }, [category.schema.length]);

  useEffect(() => {
    if (category.schema.every((schema) => schema.data_type === 'object')) {
      if (category.schema.length <= 1) {
        setIsSchemaValid({
          valid: false,
          message: `The only field should not be of type "Template"`,
        });
      } else {
        setIsSchemaValid({
          valid: false,
          message: 'At least one schema field should not be of type Template.',
        });
      }
    } else {
      setIsSchemaValid({ valid: true, message: null });
    }
  }, [category.schema]);

  useEffect(() => {
    setCategory({
      ...category,
      identifier: selectedIdentifier.name,
    });
  }, [selectedIdentifier.name]);

  return (
    <form className="d-flex flex-column gap-5" style={{ maxWidth: 1100 }} onSubmit={saveCategory}>
      {messages.description ? <ErrorMessage messages={messages} /> : null}
      <Card cardClass="main-settings">
        <div className="input-group">
          <label className="input-group-text gap-2" htmlFor="category-name-input">
            <abbr title="required">*</abbr>Name
          </label>
          <input
            type="text"
            id="category-name-input"
            className="form-control shake-if-invalid"
            placeholder="(enter a category name here)"
            aria-label="Category Name"
            aria-required
            value={category.name}
            onChange={(event) => updateCategory('name', event.target.value)}
            disabled={category.locked}
            required
          />
        </div>
        <div className="input-group">
          <label className="input-group-text gap-2" htmlFor="category-description-input">
            Description
          </label>
          <input
            type="text"
            id="category-description-input"
            className="form-control"
            placeholder="(enter a category description here)"
            aria-label="Category Description"
            defaultValue={category.description}
            onChange={(event) => updateCategory('description', event.target.value)}
            disabled={category.locked}
          />
        </div>
        <div className="input-group">
          <label className="input-group-text gap-2" htmlFor="dataset-selector">
            <abbr title="required">*</abbr>Dataset
          </label>
          <select
            className="form-select"
            id="dataset-selector"
            defaultValue={category.dataset_id}
            onChange={(event) => updateCategory('dataset_id', event.target.value)}
            disabled={category.locked}
          >
            {datasets.map((dataset) => (
              <option key={`${dataset.id}-option`} value={dataset.id}>
                {dataset.name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label className="input-group-text gap-2" htmlFor="category-type-selector">
            Category Type
          </label>
          <select
            className="form-select"
            id="category-type-selector"
            defaultValue={category.category_type?.[0]}
            onChange={(event) => updateCategory('category_type', [event.target.value])}
            disabled={category.locked}
          >
            <option></option>
            {category_types.map((type) => (
              <option key={`${type[0]}-option`} value={type[0]}>
                {type[1]}
              </option>
            ))}
          </select>
        </div>
        {persisted && (
          <div>
            <div className="input-group">
              <label className="input-group-text gap-2" htmlFor="category-internal-build-identifier-input">
                <abbr title="required">*</abbr>Internal Build Identifier
              </label>
              <input
                type="text"
                id="category-internal-build-identifier-input"
                className="form-control"
                placeholder=""
                aria-label="Category Interal Build Identifier"
                defaultValue={category.internal_build_name}
                onChange={(event) => updateCategory('internal_build_name', event.target.value)}
                disabled={category.locked}
              />
            </div>
            <span className="text-muted small">Do not change this unless you know what you are doing! It could prevent the site from deploying!</span>
          </div>
        )}
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="use-for-pages"
            checked={category.has_template}
            onChange={() => updateCategory('has_template', !category.has_template)}
            disabled={category.locked}
          />
          <div className="d-flex flex-column gap-2">
            <label className="form-check-label" htmlFor="use-for-pages">
              Use for pages
            </label>
            <label className="text-muted small" htmlFor="use-for-pages">
              This category will be used for on-page content of the website
            </label>
          </div>
        </div>
        {category.has_template ? (
          <div>
            <div className="input-group">
              <span className="input-group-text gap-2" id="url-pattern-addon">
                <abbr title="required">*</abbr>URL Pattern
              </span>
              <input
                type="text"
                id="category-url-pattern-input"
                placeholder="(enter URL here)"
                className="form-control"
                aria-label="Category URL Pattern"
                aria-describedby="url-pattern-addon"
                value={category.url_pattern}
                onChange={(event) => updateCategory('url_pattern', event.target.value)}
                disabled={category.locked}
                required
              />
            </div>
            <label className="text-muted small" htmlFor="category-url-pattern-input">
              Example: "disciplines" would result in a url of "/disciplines/xxxx"
            </label>
          </div>
        ) : null}
        <div className="form-check">
          <input
            id="use-for-jobs"
            className="form-check-input"
            type="checkbox"
            checked={category.job_categorisation}
            onChange={() => updateCategory('job_categorisation', !category.job_categorisation)}
            disabled={category.locked}
          />
          <div className="d-flex flex-column gap-2">
            <label className="form-check-label" htmlFor="use-for-jobs">
              Use for jobs
            </label>
            <label className="text-muted small" htmlFor="use-for-jobs">
              This category can be assigned to jobs for use as filters
            </label>
            {category.job_categorisation ? (
              <div className="form-check">
                <input
                  id="is-job-categorisation-required"
                  className="form-check-input"
                  type="checkbox"
                  checked={category.is_job_categorisation_required}
                  onChange={() => updateCategory('is_job_categorisation_required', !category.is_job_categorisation_required)}
                  disabled={category.locked}
                />
                <div className="d-flex gap-2 align-items-center">
                  <label className="form-check-label" htmlFor="is-job-categorisation-required">
                    Required
                  </label>
                  <label className="text-muted small" htmlFor="is-job-categorisation-required">
                    Selecting a value for this category is required when posting a job
                  </label>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="nested-subvalues"
            checked={category.is_nested}
            onChange={() => updateCategory('is_nested', !category.is_nested)}
            disabled={category.locked}
          />
          <div className="d-flex flex-column gap-2">
            <label className="form-check-label" htmlFor="nested-subvalues">
              Nested sub-values
            </label>
            <label className="text-muted small" htmlFor="nested-subvalues">
              Allow the values in this category to have sub-values, e.g. Finance &gt; Accounting
            </label>
          </div>
        </div>
      </Card>

      <div className="d-flex flex-column gap-3">
        <div className="header-body">Schema Fields</div>

        {!isSchemaValid.valid ? <label className="text-danger">{isSchemaValid.message}</label> : null}

        <CategorySchemas
          category={category}
          forms={forms}
          categories={categories}
          updateSchema={updateSchema}
          deleteSchema={deleteSchema}
          updateCategory={updateCategory}
          data_types={data_types}
          selectedIdentifier={selectedIdentifier}
          setSelectedIdentifier={setSelectedIdentifier}
        />

        <div className="d-flex">
          <button
            type="button"
            className="btn btn-primary d-flex gap-2"
            onClick={() => updateCategory('schema', [...category.schema, { id: uuidv4(), name: '', data_type: data_types[0] }])}
            disabled={category.locked}
          >
            <i className="fe fe-plus" />
            Add Fields
          </button>
        </div>
      </div>

      <Card header="Default Sort Order">
        <div className="input-group">
          <label className="input-group-text gap-2" htmlFor="sort-field-selector">
            Field
          </label>
          <select
            className="form-select"
            id="sort-field-selector"
            defaultValue={category.default_sort_field}
            data-bs-toggle="tooltip"
            title="Select the identifier field that you want the sorting to be applied to."
            onChange={(event) => updateCategory('default_sort_field', event.target.value)}
            disabled={category.locked}
          >
            {category.schema.length >= 1 &&
              category.schema.map((s) => (
                <option key={s.id} value={s.name}>
                  {s.name}
                </option>
              ))}
          </select>
          <label className="input-group-text gap-2" htmlFor="sort-order-selector">
            Order
          </label>
          <select
            id="sort-order-selector"
            className="form-select"
            defaultValue={category.default_sort_direction}
            data-bs-toggle="tooltip"
            title="Select the order you want the sorting direction to be in."
            onChange={(event) => updateCategory('default_sort_direction', event.target.value)}
            disabled={category.locked}
          >
            {['ASC', 'DESC'].map((each) => (
              <option key={each} value={each}>
                {each}
              </option>
            ))}
          </select>
        </div>
      </Card>

      {simplified_api_names ? (
        <Card header="Simplified API Name">
          <select
            id="simplified-api-name-selector"
            className="form-select"
            defaultValue={category.simplified_api_name}
            data-bs-toggle="tooltip"
            title="Select the Simplified API name you wish to apply to this category."
            onChange={(event) => updateCategory('simplified_api_name', event.target.value || null)}
            disabled={category.locked}
          >
            <option value="">(none)</option>
            {simplified_api_names.map((each) => (
              <option key={each} value={each} disabled={taken_simplified_api_names.indexOf(each) > -1}>
                {each}
              </option>
            ))}
          </select>
        </Card>
      ) : null}

      <Card>
        <div className={`d-flex justify-content-between align-items-center ${!allowedToLock && 'flex-row-reverse'}`}>
          {allowedToLock ? (
            <div className="d-flex flex-column gap-2">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="category-locked"
                  value={category.locked}
                  onChange={() => {
                    updateCategory('locked', !category.locked);
                  }}
                  checked={category.locked}
                />
                <label className="form-check-label" htmlFor="category-locked">
                  {category.locked ? (
                    <div>
                      <i className="fe fe-lock" /> Category Locked
                    </div>
                  ) : (
                    <div>
                      <i className="fe fe-unlock" /> Category Unlocked
                    </div>
                  )}
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="category-locked-values"
                  value={category.locked_values}
                  onChange={() => {
                    updateCategory('locked_values', !category.locked_values);
                  }}
                  checked={category.locked_values}
                />
                <label className="form-check-label" htmlFor="category-locked-values">
                  {category.locked_values ? (
                    <div>
                      <i className="fe fe-lock" /> Category Values Locked
                    </div>
                  ) : (
                    <div>
                      <i className="fe fe-unlock" /> Category Values Unlocked
                    </div>
                  )}
                </label>
              </div>
            </div>
          ) : null}
          <button type="submit" className="btn btn-primary" disabled={!isSchemaValid.valid}>
            Save
          </button>
        </div>
      </Card>
    </form>
  );
}

export default function CategorySettings(props) {
  const { firstDataset, category } = props;

  if (!firstDataset) {
    return (
      <Card>
        <span>You need to assign a dataset first before creating a category.</span>
      </Card>
    );
  }

  const categoryWithUniqueIds = category
    ? {
        ...category,
        schema: category.schema.map((s) => {
          if (s.object_entries && s.object_entries.length) {
            return {
              ...s,
              id: uuidv4(),
              object_entries: s.object_entries.map((oe) => ({
                ...oe,
                id: uuidv4(),
              })),
            };
          }
          return { ...s, id: uuidv4() };
        }),
      }
    : {
        dataset_id: firstDataset.id,
        name: '',
        description: '',
        schema: [{ id: uuidv4(), name: '', data_type: 'text' }],
        has_template: false,
        url_pattern: '',
        identifier: '',
        job_categorisation: false,
        is_job_categorisation_required: false,
        is_nested: false,
        default_sort_field: '',
        default_sort_direction: 'ASC',
        simplified_api_name: null,
      };

  return <CategorySettingWithUniqueIds {...props} category={categoryWithUniqueIds} />;
}
