import { useEffect } from 'react';

import DropdownInput from '../../../../inputs/form-inputs/DropdownInput';
import TextInput from '../../../../inputs/form-inputs/TextInput';

const promotionOptions = [
  { label: 'Brand new amazing post', value: 0 },
  { label: 'Existing Website Content', value: 1 },
  { label: 'Something from the library', value: 2 },
  { label: 'One of our amazing jobs', value: 3 },
];

const fetchAllContent = (url, formDispatch) => {
  fetch(`${url}.json`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Response wasn't ok ${JSON.stringify(response)}`);
      }
      return response.json();
    })
    .then((data) => {
      formDispatch({
        type: 'update',
        payload: { promotionOptions: data, isLoading: false },
      });
    })
    .catch((error) => {
      console.log('Error', error);

      formDispatch({
        type: 'update',
        payload: { isLoading: false, isInvalid: true, error: "Couldn't fetch existing site content." },
      });
    });
};

const fetchAllJobs = (url, formDispatch) => {
  fetch(`${url}.json`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Response wasn't ok ${JSON.stringify(response)}`);
      }
      return response.json();
    })
    .then((data) => {
      formDispatch({
        type: 'update',
        payload: { promotionOptions: data, isLoading: false },
      });
    })
    .catch((error) => {
      console.log('Error', error);

      formDispatch({
        type: 'update',
        payload: { isLoading: false, isInvalid: true, error: 'Failed to fetch list of all jobs.' },
      });
    });
};

const fetchAllSocialPosts = (url, formState, formDispatch) => {
  const body = formState.data.id ? { social_post: { id: formState.data.id } } : { social_post: { id: '' } };

  fetch(`${url}.json`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    credentials: 'same-origin',
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Response wasn't ok ${JSON.stringify(response)}`);
      }
      return response.json();
    })
    .then((data) => {
      formDispatch({
        type: 'update',
        payload: { promotionOptions: data },
      });

      formDispatch({
        type: 'update',
        payload: { isLoading: false },
      });
    })
    .catch((error) => {
      console.log('Error', error);

      formDispatch({
        type: 'update',
        payload: { isLoading: false, isInvalid: true, error: "Couldn't fetch list of all social posts." },
      });
    });
};

const fetchPostData = (url, formDispatch, promotionOptionContent) => {
  const body = {
    social_post: {
      id: promotionOptionContent,
    },
  };

  fetch(`${url}.json`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    credentials: 'same-origin',
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Response wasn't ok ${JSON.stringify(response)}`);
      }
      return response.json();
    })
    .then((data) => {
      formDispatch({
        type: 'update_data',
        payload: {
          article: data.article ?? null,
          post: data.post ?? '',
        },
      });

      formDispatch({
        type: 'update',
        payload: { isLoading: false },
      });
    })
    .catch((error) => {
      console.log(error);

      formDispatch({
        type: 'update',
        payload: { isLoading: false, isInvalid: true, error: "Couldn't fetch post data." },
      });
    });
};

const fetchScrapedMetadata = (url, formState, formDispatch, promotionOptionContent) => {
  const body = {
    social_post: {
      promotion_option: formState.data.promotion_option,
      promotion_option_content: promotionOptionContent,
    },
  };

  fetch(`${url}.json`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    credentials: 'same-origin',
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Response wasn't ok ${JSON.stringify(response)}`);
      }
      return response.json();
    })
    .then((data) => {
      formDispatch({
        type: 'update_data',
        payload: {
          article: {
            source: data.source,
            title: data.title,
            description: data.description,
            show_image: data.image ? true : false,
            image: data.image,
          },
        },
      });

      formDispatch({
        type: 'update',
        payload: { isLoading: false },
      });
    })
    .catch((error) => {
      console.log('Error', error);

      formDispatch({
        type: 'update',
        payload: { isLoading: false, isInvalid: true, error: "Couldn't fetch selected option." },
      });
    });
};

export default function PostConfigStep(props) {
  const { formState, formDispatch, endpoints } = props;

  useEffect(() => {
    if (!formState.data.name) {
      formDispatch({ type: 'update', payload: { isInvalid: true, error: 'Please fill in post name to continue.' } });
      return;
    }

    if (
      !formState.data.promotion_option_content &&
      (formState.data.promotion_option === 1 || formState.data.promotion_option === 2 || formState.data.promotion_option === 3)
    ) {
      formDispatch({ type: 'update', payload: { isInvalid: true, error: 'Please select which content to share to continue.' } });
      return;
    }

    formDispatch({
      type: 'update',
      payload: { isInvalid: false, error: '', status: '' },
    });
  }, [formState.data.name, formState.data.promotion_option, formState.data.promotion_option_content]);

  useEffect(() => {
    if (formState.data.promotion_option === 0) {
      formDispatch({ type: 'update_data', payload: { article: null } });

      if (!formState.data.id) {
        formDispatch({ type: 'update_data', payload: { post: '' } });
      }

      return;
    }

    formDispatch({ type: 'update', payload: { promotionOptions: [], isLoading: true } });

    if (formState.data.promotion_option === 1) {
      fetchAllContent(endpoints.socialPostsContentUrl, formDispatch);
      return;
    }

    if (formState.data.promotion_option === 2) {
      fetchAllSocialPosts(endpoints.socialPostsAllPostsUrl, formState, formDispatch);
      return;
    }

    if (formState.data.promotion_option === 3) {
      fetchAllJobs(endpoints.socialPostsJobsUrl, formDispatch);
      return;
    }
  }, [formState.data.promotion_option]);

  return (
    <div className="card-body">
      <div className="d-flex flex-column gap-5 fade show active">
        <div className="d-flex flex-column gap-2">
          <label>
            Post Name <abbr className="text-danger">*</abbr>
          </label>
          <TextInput
            value={formState.data.name}
            onChange={(e) => formDispatch({ type: 'update_data', payload: { name: e.target.value } })}
            disabled={formState.isDisabled}
          />
        </div>
        <div className="d-flex flex-column gap-2">
          <label>What do you want to promote?</label>

          <div className="container">
            <div className="row">
              {promotionOptions.map((o) => (
                <label key={o.value} className="col">
                  <input
                    type="radio"
                    className="card-input-element"
                    id={o.value}
                    name="promotion_option"
                    value={o.value}
                    onChange={(e) => formDispatch({ type: 'update_data', payload: { promotion_option: Number(e.target.value), promotion_option_content: '' } })}
                    checked={formState.data.promotion_option === o.value}
                    disabled={formState.isDisabled}
                  />
                  <div className={`card card-input ${formState.isDisabled ? 'disabled' : ''}`}>
                    <div className="card-body">{o.label}</div>
                  </div>
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-2">
          <label>
            Choose the content{' '}
            {promotionOptions[formState.data.promotion_option] ? (
              <span className="content-description">{promotionOptions[formState.data.promotion_option]?.label?.toLowerCase()}</span>
            ) : null}{' '}
            that you'd like the link for:
          </label>
          {formState.promotionOptions.length ? (
            <DropdownInput
              key={`promotion_option_content_${formState.data.promotion_option_content}`}
              value={formState.data.promotion_option_content}
              onChange={(newValue) => {
                formDispatch({ type: 'update_data', payload: { promotion_option_content: newValue.value } });
                formDispatch({ type: 'update', payload: { isLoading: true } });

                if (formState.data.promotion_option === 2) {
                  formDispatch({ type: 'update_data', payload: { post: '', article: null } });

                  if (!newValue.value) {
                    formDispatch({ type: 'update', payload: { isLoading: false } });
                    return;
                  }

                  fetchPostData(endpoints.socialPostsPostUrl, formDispatch, newValue.value);
                  return;
                }

                if (formState.data.promotion_option === 1 || formState.data.promotion_option === 3) {
                  formDispatch({ type: 'update_data', payload: { article: null } });

                  if (!newValue.value) {
                    formDispatch({ type: 'update', payload: { isLoading: false } });
                    return;
                  }

                  fetchScrapedMetadata(endpoints.socialPostsScrapeMetadataUrl, formState, formDispatch, newValue.value);
                }
              }}
              formattedDropdownValues={formState.promotionOptions}
              isDisabled={formState.isDisabled || !formState.promotionOptions.length}
              isClearable
              isSearchable
            />
          ) : (
            <p className="text-muted">No content available.</p>
          )}
        </div>
      </div>
    </div>
  );
}
