import { snakeToRegularString } from '../../../../lib';
import DropdownInput from '../../../../../inputs/form-inputs/DropdownInput';

export default function TemplatePropFieldCategory({ contentId, templateName, templateItem, dispatch, category, name, label, allowMultipleOptions }) {
  const value = templateItem[name];
  const { category_values, name: categoryName } = category;

  const dropdownValues = category_values.map((each) => ({ value: each.id, label: each.title }));

  return (
    <div className="d-flex flex-column gap-1">
      <label className="text-muted">{snakeToRegularString(label || name)}</label>
      <DropdownInput
        placeholder={`Select a value from the ${categoryName} category`}
        value={value}
        defaultValue={value}
        formattedDropdownValues={dropdownValues}
        onChange={(event) =>
          dispatch({
            type: 'UPDATE_TEMPLATE_ITEM',
            payload: { contentId, templateName, templateItemId: templateItem.id, fieldName: name, fieldValue: event.value },
          })
        }
        isClearable
        isMulti={allowMultipleOptions}
      />
      <label className="text-muted fs-5">Select a value from the {categoryName} category.</label>
    </div>
  );
}
