import { TagsInput } from 'react-tag-input-component';
import { v4 as uuidv4 } from 'uuid';
import SortableItem from '../dnd-kit/SortableItem/SortableItem';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

import Card from '../card/Card';
import Collapse from '../collapse/Collapse';
import SchemaOptions from '../category/SchemaOptions';
import TransparentCollapse from '../collapse/TransparentCollapse';
import { getDate, getDateTime } from '../../src/utils/DatesAndTime';
import DropdownInput from './form-inputs/DropdownInput';

export default function ObjectSchemaInput(props) {
  const { schema, forms, categories, data_types, updateSchema, disabled } = props;

  const updateObjectEntry = (objectEntryId, newObjectEntry) => {
    const updatedObjectEntries = schema.object_entries.map((oe) => {
      if (oe.id === objectEntryId) {
        return newObjectEntry;
      }

      return oe;
    });

    updateSchema(schema.id, {
      ...schema,
      object_entries: updatedObjectEntries,
    });
  };

  const deleteObjectEntry = (objectEntryId) => {
    const updatedObjectEntries = schema.object_entries.filter((oe) => (oe.id !== objectEntryId ? oe : null));

    updateSchema(schema.id, {
      ...schema,
      object_entries: updatedObjectEntries,
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = schema.object_entries.findIndex((oe) => oe.id === active.id);
      const newIndex = schema.object_entries.findIndex((oe) => oe.id === over.id);

      // Updates the category schema with the new order of the fields
      updateSchema(schema.id, { ...schema, object_entries: arrayMove(schema.object_entries, oldIndex, newIndex) });
    }
  };

  return (
    <div className="ps-5">
      <TransparentCollapse id={`transparent-collapse-${schema.id}`} header="Template Fields">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-column gap-3">
            {schema.object_entries?.length ? (
              <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={schema.object_entries} strategy={verticalListSortingStrategy}>
                  {schema.object_entries?.map((oe) => (
                    <SortableItem key={oe.id} id={oe.id}>
                      <Card>
                        <Collapse
                          id={`input-field-${oe.id}`}
                          body={
                            <SchemaOptions
                              schema={oe}
                              forms={forms}
                              categories={categories}
                              requiredOnChange={(event) => {
                                updateObjectEntry(oe.id, {
                                  ...oe,
                                  isRequired: event.target.checked,
                                });
                              }}
                              descriptionOnChange={(event) => {
                                updateObjectEntry(oe.id, {
                                  ...oe,
                                  description: event.target.value,
                                });
                              }}
                              defaultValueOnChange={(event) => {
                                const defaultValue = (dataType, event) => {
                                  switch (true) {
                                    case dataType === 'date':
                                      const [date] = event;

                                      if (!date) return '';

                                      return getDate(date);
                                    case dataType === 'datetime':
                                      const [datetime] = event;

                                      if (!datetime) return '';

                                      return getDateTime(datetime);
                                    case dataType === 'dropdown':
                                      if (!Array.isArray(event)) return event.value;

                                      return event;
                                    case dataType === 'code' || dataType === 'formatted_text':
                                      return event;
                                    case dataType === 'checkbox':
                                      return JSON.stringify(event.target.checked);
                                    case dataType === 'form' || dataType === 'category':
                                      return event.value;
                                    default:
                                      return event.target.value;
                                  }
                                };

                                updateObjectEntry(oe.id, { ...oe, default_value: defaultValue(oe.data_type, event) });
                              }}
                            />
                          }
                          header={
                            <div className="d-flex flex-column gap-4">
                              <div className="d-flex gap-4">
                                <div className="input-group">
                                  <input
                                    id={`${oe.id}-name-field`}
                                    type="text"
                                    className="form-control"
                                    aria-label={`Object Entry Name for ${oe.id}`}
                                    aria-required
                                    value={oe.name}
                                    onChange={(event) => {
                                      updateObjectEntry(oe.id, {
                                        ...oe,
                                        name: event.target.value,
                                      });
                                    }}
                                    disabled={disabled}
                                    required
                                  />
                                  <select
                                    id={`${oe.id}-datatype-selector`}
                                    className="form-select btn btn-outline-secondary"
                                    defaultValue={oe.data_type}
                                    onChange={(event) => {
                                      // Everything about the field is reset every time the data type is changed.
                                      // Only assign the id, name and data_type, the other fields will be removed and reset.
                                      updateObjectEntry(oe.id, {
                                        id: oe.id,
                                        name: oe.name,
                                        data_type: event.target.value,
                                      });
                                    }}
                                    disabled={disabled}
                                  >
                                    {data_types
                                      .filter((item) => !['object'].includes(item))
                                      .map((dataType) => (
                                        <option key={dataType} value={dataType}>
                                          {dataType.charAt(0).toUpperCase() + dataType.slice(1)}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-outline-danger"
                                  onClick={() => {
                                    deleteObjectEntry(oe.id);
                                  }}
                                  disabled={disabled}
                                >
                                  <i className="fe fe-trash" />
                                </button>
                              </div>
                              {oe.data_type === 'dropdown' ? (
                                <div className="d-flex flex-column gap-4">
                                  <TagsInput
                                    value={oe.dropdown_values}
                                    onChange={(values) => {
                                      updateObjectEntry(oe.id, {
                                        ...oe,
                                        dropdown_values: values,
                                      });
                                    }}
                                    name={oe.name}
                                    placeholder="Enter dropdown values..."
                                    disabled={disabled}
                                  />
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id={`${oe.name}-isMulti`}
                                      value={oe.isMulti}
                                      defaultChecked={oe.isMulti}
                                      onChange={(event) => {
                                        updateObjectEntry(oe.id, {
                                          ...oe,
                                          default_value: null,
                                          isMulti: event.target.checked,
                                        });
                                      }}
                                    />
                                    <label className="form-check-label" htmlFor="category-locked-values">
                                      Allow selecting multiple values
                                    </label>
                                  </div>
                                </div>
                              ) : null}
                              {oe.data_type === 'category' ? (
                                <>
                                  <DropdownInput
                                    value={oe.foreign_category_id}
                                    formattedDropdownValues={categories.map((category) => ({
                                      value: category.id,
                                      label: category.name,
                                    }))}
                                    onChange={(event) => updateObjectEntry(oe.id, { ...oe, default_value: '', foreign_category_id: event.value })}
                                    isClearable
                                    disabled={disabled}
                                  />
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id={`${oe.name}-isMulti`}
                                      value={oe.isMulti}
                                      defaultChecked={oe.isMulti}
                                      onChange={(event) => {
                                        updateObjectEntry(oe.id, {
                                          ...oe,
                                          default_value: null,
                                          isMulti: event.target.checked,
                                        });
                                      }}
                                    />
                                    <label className="form-check-label" htmlFor="category-locked-values">
                                      Allow selecting multiple values
                                    </label>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          }
                        />
                      </Card>
                    </SortableItem>
                  ))}
                </SortableContext>
              </DndContext>
            ) : (
              <p className="text-muted text-center">No template field entries have been added.</p>
            )}
          </div>
          <div className="d-flex justify-content-end mb-3 me-3">
            <button
              type="button"
              className="btn btn-primary d-flex gap-2"
              onClick={() =>
                updateSchema(schema.id, {
                  ...schema,
                  object_entries: [...(schema.object_entries ?? []), { id: uuidv4(), name: '', data_type: data_types[0] }],
                })
              }
              disabled={disabled}
            >
              <i className="fe fe-plus" />
              Add Entry
            </button>
          </div>
        </div>
      </TransparentCollapse>
    </div>
  );
}
