export default function SwitchInput(props) {
  const { label, ...rest } = props;

  return (
    <div className="form-check form-switch">
      <input className="form-check-input" type="checkbox" role="switch" {...rest} />
      {label && <label className="form-check-label">{label}</label>}
    </div>
  );
}
