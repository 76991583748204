import React from 'react';

import Card from '../card/Card';
import ObjectSchemaInput from '../inputs/ObjectSchemaInput';
import Collapse from '../collapse/Collapse';
import SchemaOptions from './SchemaOptions';
import SortableItem from '../dnd-kit/SortableItem/SortableItem';

import { TagsInput } from 'react-tag-input-component';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { getDate, getDateTime } from '../../src/utils/DatesAndTime';
import DropdownInput from '../inputs/form-inputs/DropdownInput';

export default function CategorySchemas({
  category,
  forms,
  categories,
  updateSchema,
  deleteSchema,
  updateCategory,
  data_types,
  selectedIdentifier,
  setSelectedIdentifier,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = category.schema.findIndex((schema) => schema.id === active.id);
      const newIndex = category.schema.findIndex((schema) => schema.id === over.id);

      // Updates the category schema with the new order of the fields
      updateCategory('schema', arrayMove(category.schema, oldIndex, newIndex));
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={category.schema} strategy={verticalListSortingStrategy}>
        {category.schema.map((schema) => (
          <SortableItem key={schema.id} id={schema.id}>
            <div className={`d-flex flex-column gap-3 ${schema.data_type === 'object' && 'border rounded'}`}>
              <Card cardClass={schema.data_type === 'object' && 'rounded-0'}>
                <Collapse
                  id={`input-field-${schema.id}`}
                  body={
                    <SchemaOptions
                      schema={schema}
                      forms={forms}
                      categories={categories}
                      disabled={category.locked}
                      selectedIdentifier={selectedIdentifier}
                      requiredOnChange={(event) => {
                        updateSchema(schema.id, {
                          ...schema,
                          isRequired: event.target.checked,
                        });
                      }}
                      descriptionOnChange={(event) => {
                        updateSchema(schema.id, {
                          ...schema,
                          description: event.target.value,
                        });
                      }}
                      defaultValueOnChange={(event) => {
                        const defaultValue = (dataType, event) => {
                          switch (true) {
                            case dataType === 'date':
                              const [date] = event;

                              if (!date) return '';

                              return getDate(date);
                            case dataType === 'datetime':
                              const [datetime] = event;

                              if (!datetime) return '';

                              return getDateTime(datetime);

                            case dataType === 'dropdown':
                              if (!Array.isArray(event)) return event.value;

                              return event;
                            case dataType === 'code' || dataType === 'formatted_text':
                              return event;
                            case dataType === 'checkbox':
                              return JSON.stringify(event.target.checked);
                            case dataType === 'form' || dataType === 'category':
                              return event.value;
                            default:
                              return event.target.value;
                          }
                        };

                        updateSchema(schema.id, { ...schema, default_value: defaultValue(schema.data_type, event) });
                      }}
                    />
                  }
                  header={
                    <div className="d-flex flex-column gap-4">
                      <div className="d-flex gap-4">
                        <div className="input-group">
                          <div className="input-group-text">
                            {schema.data_type !== 'object' ? (
                              <div className="form-check">
                                <input
                                  id={`${schema.id}-identifier-radio`}
                                  aria-label={`Identifier Selector for ${schema.name}`}
                                  title="Please select this field if you want it to be the identifier"
                                  className="form-check-input"
                                  type="radio"
                                  name="schema-field-input-identifier"
                                  checked={selectedIdentifier.id === schema.id}
                                  onChange={() => {
                                    setSelectedIdentifier({
                                      name: schema.name,
                                      id: schema.id,
                                    });
                                    updateSchema(schema.id, {
                                      ...schema,
                                      isRequired: true,
                                    });
                                  }}
                                  data-bs-toggle="tooltip"
                                  disabled={category.locked || schema.data_type === 'checkbox'}
                                />
                              </div>
                            ) : (
                              <i className="fe fe-grid" />
                            )}
                          </div>
                          <input
                            id={`${schema.id}-schema-name-field`}
                            type="text"
                            className="form-control"
                            value={schema.name}
                            onChange={(event) => {
                              // Update the selected identifier if the name changes.
                              if (selectedIdentifier.id === schema.id) {
                                setSelectedIdentifier({
                                  name: event.target.value,
                                  id: schema.id,
                                });
                              }

                              updateSchema(schema.id, {
                                ...schema,
                                name: event.target.value,
                              });
                            }}
                            disabled={category.locked}
                            required
                          />
                          <select
                            id={`${schema.id}-datatype-selector`}
                            className="form-select btn btn-outline-secondary"
                            defaultValue={schema.data_type}
                            onChange={(event) => {
                              // Remove identifier if the field is now an object or checkbox.
                              if ((event.target.value === 'object' || event.target.value === 'checkbox') && selectedIdentifier.id === schema.id) {
                                setSelectedIdentifier({
                                  name: '',
                                  id: null,
                                });
                              }
                              // Everything about the field is reset every time the data type is changed.
                              // Only assign the id, name and data_type, the other fields will be removed and reset.
                              updateSchema(schema.id, {
                                id: schema.id,
                                name: schema.name,
                                data_type: event.target.value,
                              });
                            }}
                            disabled={category.locked}
                          >
                            {data_types.map((dataType, k) => (
                              <option key={k} value={dataType}>
                                {dataType === 'object' ? 'Template' : dataType.charAt(0).toUpperCase() + dataType.slice(1)}
                              </option>
                            ))}
                          </select>
                        </div>
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={() => {
                            deleteSchema(schema.id);
                          }}
                          disabled={category.schema.length <= 1 || category.locked}
                        >
                          <i className="fe fe-trash" />
                        </button>
                      </div>
                      {schema.data_type === 'dropdown' ? (
                        <div className="d-flex flex-column gap-4">
                          <TagsInput
                            value={schema.dropdown_values}
                            onChange={(values) => {
                              updateSchema(schema.id, {
                                ...schema,
                                dropdown_values: values,
                              });
                            }}
                            name={schema.name}
                            placeHolder="Enter dropdown values..."
                            disabled={category.locked}
                          />
                          <div className="d-flex gap-4">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`${schema.name}-isMulti`}
                                value={schema.isMulti}
                                defaultChecked={schema.isMulti}
                                onChange={(event) => {
                                  updateSchema(schema.id, {
                                    ...schema,
                                    default_value: null,
                                    isMulti: event.target.checked,
                                  });
                                }}
                              />
                              <label className="form-check-label" htmlFor="category-locked-values">
                                Allow selecting multiple values
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {schema.data_type === 'category' ? (
                        <>
                          <DropdownInput
                            placeholder="Select a category..."
                            value={schema.foreign_category_id}
                            formattedDropdownValues={categories.map((category) => ({
                              value: category.id,
                              label: category.name,
                            }))}
                            onChange={(event) => updateSchema(schema.id, { ...schema, default_value: '', foreign_category_id: event.value })}
                            isClearable
                            disabled={category.locked}
                          />
                          <div className="d-flex gap-4">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`${schema.name}-isMulti`}
                                value={schema.isMulti}
                                defaultChecked={schema.isMulti}
                                onChange={(event) => {
                                  updateSchema(schema.id, {
                                    ...schema,
                                    default_value: null,
                                    isMulti: event.target.checked,
                                  });
                                }}
                              />
                              <label className="form-check-label" htmlFor="category-locked-values">
                                Allow selecting multiple values
                              </label>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  }
                />
              </Card>

              {schema.data_type === 'object' ? (
                <ObjectSchemaInput
                  schema={schema}
                  forms={forms}
                  categories={categories}
                  data_types={data_types}
                  updateSchema={updateSchema}
                  disabled={category.locked}
                />
              ) : null}
            </div>
          </SortableItem>
        ))}
      </SortableContext>
    </DndContext>
  );
}
