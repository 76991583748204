import { useInView } from 'react-intersection-observer';

export default function ImageCard({ image, endpoints, imagePreviewModalId, setImagePreview }) {
  const { name, url, id } = image;
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  const handleImagePreview = () => {
    setImagePreview(url);
  };

  return (
    <div className="image">
      <div ref={ref} className="image-container">
        {url ? (
          inView ? (
            <img src={url} alt={`Image ID: ${id}`} />
          ) : (
            <div className="preview-container">
              <i className="fe fe-file" />
            </div>
          )
        ) : (
          <div className="preview-container">CDN URL is not found.</div>
        )}
      </div>
      <div className="d-flex px-2 pt-3 align-items-center justify-content-between">
        <label className="d-inline-block text-truncate" data-bs-toggle="tooltip" data-bs-title={name} style={{ maxWidth: 150 }}>
          {name}
        </label>
        <div className="d-flex gap-2">
          <button className="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target={`#${imagePreviewModalId}`} onClick={handleImagePreview}>
            <i className="fe fe-external-link" />
          </button>
          <a className="btn btn-primary" href={`${endpoints.newAdminImageUrl}?img_src=${url}&filename=${name}`}>
            Edit
          </a>
        </div>
      </div>
    </div>
  );
}
