import { Tooltip } from 'bootstrap';
import { useState, useEffect } from 'react';

import TextInput from '../../inputs/form-inputs/TextInput';
import ImageCard from './_components/ImageCard';
import { authorisedGetImages } from '../lib';

const ImagePreviewModal = ({ id, imagePreview }) => {
  return (
    <div className="modal fade" id={id} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <p className="modal-title">Previewing Image</p>
              <div className="d-flex gap-2">
                <a className="btn btn-primary" href={imagePreview}>
                  Visit
                </a>
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center justify-content-center w-100" style={{ height: 650 }}>
              <img src={imagePreview} style={{ objectFit: 'contain', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function IndexImages({ endpoints }) {
  const [filteredImages, setFilteredImages] = useState([]);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState('');

  const handleSearch = (e) => {
    if (!e.target.value) {
      setFilteredImages(images);
      return;
    }
    const keyword = e.target.value.toLowerCase();
    const filtered = filteredImages.filter((image) => image.name.toLowerCase().includes(keyword));
    setFilteredImages(filtered);
  };

  useEffect(() => {
    authorisedGetImages()
      .then((res) => {
        const sortedImages = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setImages(sortedImages);
        setFilteredImages(sortedImages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('Error with getting images:', error);
      });
  }, []);

  useEffect(() => {
    // Reassign Tooltip on schema fields every time there's a new one or one is removed
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');

    tooltips.forEach((tooltip) => {
      new Tooltip(tooltip);
    });
  }, [filteredImages]);

  const imagePreviewModalId = 'imagePreviewModal';

  return (
    <div className="image-manager">
      <TextInput placeholder="Search for an image..." onChange={handleSearch} style={{ maxWidth: 500 }} />
      <div className="tiles-of-images">
        {isLoading ? (
          <div className="w-100 d-flex align-items-center justify-content-center p-5">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : filteredImages?.length ? (
          filteredImages.map((image) => <ImageCard key={image.id} {...{ image, endpoints, setImagePreview, imagePreviewModalId }} />)
        ) : (
          <div className="w-100 d-flex align-items-center justify-content-center p-5">No images found.</div>
        )}
      </div>
      <ImagePreviewModal {...{ id: imagePreviewModalId, imagePreview }} />
    </div>
  );
}
