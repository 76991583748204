import { useState } from 'react';

import TextInput from '../../../inputs/form-inputs/TextInput';
import SwitchInput from '../../../inputs/form-inputs/SwitchInput';

function PropSetting({ name, type, value, defaultValue, isRequired, allowMultipleOptions, options, setComponentProps }) {
  if (type === 'string') {
    return (
      <div className="d-flex flex-column gap-2">
        <label className="text-muted">{name}</label>
        {options && options.length ? (
          <select className="form-select" value={value} onChange={(e) => setComponentProps((p) => ({ ...p, [name]: e.target.value }))}>
            <option value="" disabled>
              Select an option
            </option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <TextInput value={value} onChange={(e) => setComponentProps((p) => ({ ...p, [name]: e.target.value }))} />
        )}
      </div>
    );
  }

  if (type === 'boolean') {
    return (
      <div className="d-flex flex-column gap-2">
        <label className="text-muted">{name}</label>
        <SwitchInput checked={value} onChange={(e) => setComponentProps((p) => ({ ...p, [name]: e.target.checked }))} />
      </div>
    );
  }

  if (type === 'array') {
    return (
      <div className="d-flex flex-column gap-2">
        <label className="text-muted">{name}</label>
        <select
          className="form-select"
          onChange={(e) => {
            console.log('Triggered');
            console.log(e.target.value);
            console.log(options.find((o) => o.label === e.target.value).value);
            setComponentProps((p) => ({
              ...p,
              [name]: options.find((o) => o.label === e.target.value).value,
            }));
          }}
        >
          <option value="" disabled>
            Select an option
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.label}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return <div>Not supported data type.</div>;
}

export default function ComponentSettingsModal({ dispatch, component, definition }) {
  const [componentProps, setComponentProps] = useState(component.props);

  const handleSaveComponent = () => {
    dispatch({ type: 'UPDATE_INDIVIDUAL_CONTENT', payload: { ...component, props: componentProps } });
  };

  const handleRemoveComponent = () => {
    dispatch({ type: 'REMOVE_CONTENT', payload: { id: component.id } });
  };

  return (
    <div
      className="modal fade"
      id={`component-${component.id}-settings-modal`}
      tabIndex={-1}
      aria-labelledby={`component-${component.id}-settings-label`}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <p className="modal-title" id={`component-${component.id}-settings-label`}>
                Configure the component
              </p>
              <button type="button" className="btn" data-bs-dismiss="modal">
                <i className="fe fe-x" />
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="d-flex flex-column gap-3">
              {definition?.propSchema?.map((prop, index) => (
                <PropSetting key={index} value={componentProps[prop.name]} setComponentProps={setComponentProps} {...prop} />
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <div className="btn-group">
              <button type="button" className="btn btn-primary" onClick={handleSaveComponent} data-bs-dismiss="modal">
                Save
              </button>
              <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="visually-hidden">Toggle more page options</span>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button type="button" className="dropdown-item" onClick={handleRemoveComponent} data-bs-dismiss="modal">
                    Delete
                  </button>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Duplicate
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
